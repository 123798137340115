import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import LoadingSpinnerLanding from "../LoadingSpinnerLanding";
import {
  CompetitionListForUserAction,
  ShowSliderImageCompetitionAction,
  GetpriceAction,
} from "../../Redux/Actions/AdminAction";
import { Link, useNavigate } from "react-router-dom";
import Slider from "@mui/material/Slider";
import ImageSlider from "react-simple-image-slider";
import "react-image-gallery/styles/css/image-gallery.css";

const Competitions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [competitionList, setCompetitionList] = useState([]);
  const [competitionImage, setCompetitionImage] = useState([]);
  const [valueTotalData, setValueTotalData] = useState([]);

  const { compList, success } = useSelector(
    (state) => state.CompetitionListForUserReducer
  );

  const { pricelist } = useSelector((state) => state.pricelistreducer);

  useEffect(() => {
    if (pricelist && valueTotalData?.length > 0) {
      const matches = [];
      for (const item of pricelist) {
        if (item.number_of_tickets === 100) {
          matches.push(item);
        }
      }

      for (const match of matches) {
        const idToMatch = match.competition;
        const matchingItem = valueTotalData.find(
          (item) => item.id === idToMatch
        );
        if (matchingItem) {
          if (match.disDataShow) {
            matchingItem.total = match.disDataShow;
          } else {
            matchingItem.total = match.total || "0";
          }
        }
      }
      const updatedData = [...valueTotalData]; // Create a copy of valueTotalData

      for (const match of matches) {
        const matchingItems = updatedData.filter(
          (item) => item.id === match.competition
        );

        if (matchingItems.length > 0) {
          for (const matchingItem of matchingItems) {
            matchingItem.total = match.price || "0";
            matchingItem.disDataShow = match.price || "0";
          }
        }
      }
      setValueTotalData(updatedData); // Update the state with the modified data
    } else {
      const newDataArray = compList?.map((compDetail) => ({
        sliderValue: 100,
        total: "0",
        id: compDetail?.id,
        discountprice1: "",
        disDataShow: "",
        message: "",
      }));
      setValueTotalData(newDataArray);
      dispatch(GetpriceAction());
    }
  }, [success]);

  const { SliderShowImagecompetion } = useSelector(
    (state) => state.ShowSliderImageCompetitionReducer
  );

  useEffect(() => {
    dispatch(CompetitionListForUserAction());
    dispatch(ShowSliderImageCompetitionAction());
  }, []);

  useEffect(() => {
    localStorage.setItem("slidervalue", "100");
    if (SliderShowImagecompetion) {
      setCompetitionImage(SliderShowImagecompetion);
    }
  }, [SliderShowImagecompetion]);

  const images = competitionImage?.map((item) => ({
    url: item?.slider_image,
  }));

  useEffect(() => {
    if (compList) {
      setCompetitionList(compList);
    }
  }, [success]);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    indicators: true, // Use "indicators" instead of "dots"
    showNavs: true,
    height: "451px",
  };

  const HandleShowDate = ({ endDate }) => {
    const [timeDiff, setTimeDiff] = useState(
      new Date(endDate).getTime() - new Date().getTime()
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeDiff((prevTimeDiff) => {
          let timeVar = prevTimeDiff;
          const days = Math.floor(timeVar / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeVar % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeVar % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeVar % (1000 * 60)) / 1000);

          if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            dispatch(CompetitionListForUserAction());
          }
          return prevTimeDiff - 1000;
        });
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return (
      <div className="competitonTockent flex">
        <div>
          <h1>{days}</h1>
          <p>Day{days > 1 ? "s" : ""}</p>
        </div>
        <div>
          <h1>{hours}</h1>
          <p>Hr{hours > 1 ? "s" : ""}</p>
        </div>
        <div>
          <h1>{minutes}</h1>
          <p>Min{minutes > 1 ? "s" : ""}</p>
        </div>
        <div>
          <h1>{seconds}</h1>
          <p>Sec{seconds > 1 ? "s" : ""}</p>
        </div>
      </div>
    );
  };

  const DateTime = ({ value }) => {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };
    const formattedDateTime = date.toLocaleString("en-GB", options); // Use "en-GB" for dd/mm/yyyy format
    return (
      <span className="quesAns-Text47 marginTopText33 textcolor55 homeScreenEndsDate">
        Ends: {formattedDateTime}
      </span>
    );
  };

  const handleEnterNow = (e, idValue, index) => {
    e.preventDefault();
    if (valueTotalData?.length > 0) {
      localStorage.setItem(
        "competitondetailid",
        JSON.stringify(valueTotalData[index])
      );
    } else {
      const array = [
        {
          id: idValue,
          value: 100,
        },
      ];

      localStorage.setItem("competitondetailid", JSON.stringify(array));
    }
    navigate(`/competition-deatils/${idValue}`);
  };

  const customStyles = {
    galleryAction: {
      display: "none",
    },
  };
  const handleRedirectFun = (e) => {
    e.preventDefault();
    localStorage.setItem("Redirect_id", "eightPointThree");
    setTimeout(() => {
      navigate("/terms-to-play");
    }, 500);
  };

  const [loading, setLoading] = useState(true); // State to manage loading status

  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    preloadNextImage();
  }, [currentIndex]);

  const preloadNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    if (!loadedImages.includes(nextIndex)) {
      const img = new Image();
      img.src = images[nextIndex];
      img.onload = () => {
        setLoadedImages((prevLoadedImages) => [...prevLoadedImages, nextIndex]);
      };
    }
  };

  const handleSlideChange = (newIndex) => {
    setCurrentIndex(newIndex);
  };
  return (
    <>
      <div className="homeCompetition">
        <div>
          {competitionImage.length > 0 && (
            <div className="compitition_bg">
              <ImageSlider
                images={[images[currentIndex]]}
                width="100%"
                height="100vh"
                showBullets
                slideDuration={3}
                showNavs
                useGPURender
                autoPlay={true}
                onChange={handleSlideChange}
                lazyLoad={true}
              />
            </div>
          )}
        </div>

        <div className="compitition_page_bg md:flex">
          <div className=" videcontentshwoComp container">
            <div className="compitition_future">
              <h2 className="competitonHeading text-3xl text-white font-semibold font-montserrat text-center">
                Competitions
              </h2>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 sm:grid-cols-1 md:gap-6 lg:gap-12 gap-3 m-2 m-lg-6">
              {competitionList?.map((items, index) => {
                const sliderImgs = items?.competition_media?.map(
                  (innerItem) => ({
                    original: innerItem.image,
                    thumbnail: innerItem.image,
                    renderItem: () => (
                      <div style={{ position: "relative" }}>
                        {loading && <LoadingSpinnerLanding />}
                        <img
                          src={innerItem.image}
                          alt=""
                          onLoad={() => setLoading(false)} // Set loading to false when image is loaded
                          style={loading ? { display: "none" } : {}} // Hide image until it's loaded
                        />
                      </div>
                    ),
                  })
                );
                return (
                  <div key={items?.id}>
                    {new Date(items?.end_date).getTime() -
                      new Date().getTime() >
                    0 ? (
                      <div className="ComplImage1 ComplImage1112 compitition_future_card mb-3">
                        <div className="ComplImageChild1">
                          <div className="img-div-4">
                            <div className="compitition_bg_competitons">
                              <div className="headingandsubtitlenewdiv">
                                <div className="headingandsubtitle">
                                  <h1 className="text-3xl text-center font-medium uppercase compitition_titleee comphtag comphtagnewdiv ">
                                    {items?.title
                                      ? items.title.length > 30
                                        ? items.title.slice(0, 20) + "..."
                                        : items.title
                                      : ""}
                                  </h1>
                                  <p className="showSubTitleSection">
                                    {/* {items?.subtitle
                                      ? items?.subtitle
                                          .split(" ")
                                          .slice(0, 5)
                                          .join(" ")
                                      : ""} */}

                                    {items?.subtitle}
                                  </p>
                                  <p className="text-alert-msg">
                                    {items?.cash_alternative
                                      ? items?.cash_alternative
                                          .split(" ")
                                          .slice(0, 10)
                                          .join(" ")
                                      : ""}
                                  </p>
                                </div>
                              </div>
                              <ImageGallery
                                items={sliderImgs}
                                additionalClass="my-gallery"
                                showPlayButton={false}
                                showFullscreenButton={false}
                                styles={customStyles}
                                autoPlay={true}
                                slideInterval={5000}
                                lazyLoad={true} // Adding lazyLoad prop to enable lazy loading
                              />
                            </div>
                          </div>
                          <div className="competitionContent">
                            <div className="compettion-flex32">
                              <HandleShowDate endDate={items?.end_date} />
                            </div>

                            <div className="competitionText text-center">
                              <div className="buttoncompetitionsection flex justify-center mx-2 mt-2 mb-2 mb-md-4">
                                <button
                                  className="competitonsButton"
                                  onClick={(e) =>
                                    handleEnterNow(e, items.id, index)
                                  }
                                >
                                  Buy Now{" "}
                                  <svg
                                    className="h-4 w-4 imgcolorsec img-text-5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <polyline points="11 17 6 12 11 7" />{" "}
                                    <polyline points="18 17 13 12 18 7" />
                                  </svg>
                                </button>
                              </div>
                              <div className="showprogressbar mx-3 mx-md-6 my-2 my-md-3 ">
                                <div className="flex justify-between mb-1">
                                  <span className="text-base font-medium  dark:text-white-- compet-Text333">
                                    {Math.round(items?.tickets_sold_percentage)}
                                    % Sold
                                  </span>
                                </div>
                                <Slider
                                  defaultValue={items?.tickets_sold_quantity}
                                  aria-label="Default"
                                  valueLabelDisplay="off"
                                  step={1}
                                  min={1}
                                  max={items?.max_tickets}
                                  disabled={true}
                                  className="slidercolor77"
                                />
                              </div>
                              <p
                                className="quesAns-Text47 cursor-pointer"
                                onClick={(e) => handleRedirectFun(e)}
                              >
                                For a free postal entry, see <u>here</u>
                              </p>

                              <div></div>
                              <DateTime value={items?.end_date} />
                            </div>
                          </div>
                        </div>
                        {items?.after_before_images.length > 0 ? (
                          <>
                            <div className="ComplImageSecChild">
                              <Slide {...settings}>
                                {items?.after_before_images.map(
                                  (slideImage, index) => (
                                    <>
                                      <div className="each-slide2" key={index}>
                                        <div className="slider-image-div23">
                                          <Link
                                            to={
                                              slideImage?.website_url
                                                ? slideImage?.website_url
                                                : "#"
                                            }
                                          >
                                            <div className="compeslider">
                                              <div className="compeslider-4">
                                                <img
                                                  className="secondImageShowSlider"
                                                  src={slideImage.before_image}
                                                  alt={`Slide ${index}`}
                                                />
                                                <img
                                                  className="firstImageShowSlider"
                                                  src={slideImage.after_image}
                                                  alt={`Slide ${index}`}
                                                />
                                              </div>
                                              <div className="testsec">
                                                <h5>{slideImage.title}</h5>
                                                <p>{slideImage.short_desc}</p>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                              </Slide>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* --------------------------------------------Four Section--------------------------------------    */}
      </div>
      <div className="competion-LogoSection">
        <p className="comp-heading96">Buy quick and easy!</p>
      </div>
    </>
  );
};

export default Competitions;
