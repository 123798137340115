import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  AddSliderImagesAction,
  getEditSliderImagesAction,
  UpdateSliderImagesAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

const SlideComp = () => {
  const { sliderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [slider_image, setSlider_image] = useState(null);
  const [link, setLink] = useState("");
  const [status, setStatus] = useState(true);
  const [type, settype] = useState("");
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [itemErrors, setItemErrors] = useState([
    {
      titleError: null,
      sliderImageError: null,
      SlilinkError: null,
      SliTypeError: null,
    },
  ]);

  const {
    AddSlider,
    error: AddSliderError,
  } = useSelector((state) => state.AddSliderImagesReducer);

  const {
    getEditSlider,
  } = useSelector((state) => state.EditSliderImageReducer);

  const {
    UpdateSlider,
    error: UpdateSliderError,
  } = useSelector((state) => state.UpdateSliderImagesReducer);


  useEffect(() => {
    if (UpdateSlider && render2) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/slider");
      }, 1500);
      setTitle("");
      setSlider_image(null);
      setLink("");
      setStatus(true);
      settype("");
      setShowImage(false);
      setRender2(false);
    }
    if (UpdateSliderError && render2) {
      swal({
        title: "Error",
        text: UpdateSliderError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 2000,
      });
      setRender2(false);
    }
  }, [dispatch, UpdateSlider, UpdateSliderError]);

  useEffect(() => {
    if(sliderId){
      if (getEditSlider) {
        setTitle(getEditSlider?.title);
        setSlider_image(getEditSlider?.slider_image);
        setLink(getEditSlider?.link);
        setStatus(getEditSlider?.status);
        settype(getEditSlider?.type);
        setShowImage(false);
      }
    }else{
      setTitle("");
      setSlider_image(null);
      setLink("");
      setStatus(true);
      settype("");
      setRender(false);
      setRender2(false);
      setShowImage(false);
    }
   
  }, [getEditSlider]);

  useEffect(() => {
    if (sliderId) {
      dispatch(getEditSliderImagesAction(sliderId));
    }
  }, [sliderId]);

  useEffect(() => {
    if (AddSlider && render) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/slider");
      }, 1500);
      setTitle("");
      setSlider_image(null);
      setLink("");
      setStatus(true);
      settype("");
      setShowImage(false);
      setRender(false);
    }
    if (AddSliderError && render) {
      swal({
        title: "Error",
        text: AddSliderError,
        className: "errorAlert-login",
        buttons: false,
        timer: 1500,
      });
      setRender(false);
    }
  }, [dispatch, AddSlider, AddSliderError,navigate]);

  const validateSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      sliderImageError: !slider_image ? "Please Enter After Image" : null,
      SlilinkError: link && !link.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/)
        ? "Please Enter valid WebSite Url"
        : null,
      SliTypeError: !type ? "Please Select Slide Type" : null,
    };

    setItemErrors(tempErrors);

    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    handleAddBeforeAfter(e);
  };

  const handleAddBeforeAfter = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);

    if (showImage) {
      formData.append("slider_image", slider_image);
    }
    formData.append("link", link);
    formData.append("status", status);
    formData.append("type", type);

    if (sliderId) {
      setRender2(true);
      dispatch(UpdateSliderImagesAction(sliderId, formData));
    } else {
      setRender(true);
      dispatch(AddSliderImagesAction(formData));
    }
  };


  return (
    <>
      <div className="main-center">
        <div className="showthecontent">
          <h1 className="componentheading">
            {sliderId ? "Edit" : " Add"} Slide
          </h1>
          <Link to="/slider">
            <button className="AddCompbutton">
              Back
            </button>
          </Link>
        </div>
        <div className="center-section">
          <form onSubmit={validateSubmit}>
            <div className="grid md:grid-cols-2 md:gap-6 m-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="Text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                  placeholder=" "
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Title
                </label>

                
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={link}
                  onChange={(e) => {
                    setLink(e.target.value);
                    setItemErrors({ ...itemErrors, SlilinkError: null });
                  }}
                />
                <label
                  htmlFor="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  link
                </label>
                <span
                  style={{
                    color: "#D14F4F",
                    textAlign: "end",
                    fontSize: "14px",
                    opacity: itemErrors.SlilinkError ? 1 : 0,
                  }}
                >
                  {itemErrors.SlilinkError ?? "valid"}
                </span>
              </div>

              <div className="relative z-0 w-full mb-6 group">
                <div className="flex items-center">
                  <label
                    for="checked-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Status
                  </label>
                  <input
                    checked={status} 
                    onChange={(e) => setStatus(e.target.checked)}
                    id="checked-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-6"
                  />
                </div>
              </div>

              

              <div className="relative z-0 w-full mb-6 group">
                <select
                  name="floating_company"
                  id="floating_company"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent status_type border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  value={type}
                  onChange={(e) => {
                    settype(e.target.value);
                    setItemErrors({ ...itemErrors, SliTypeError: null });
                  }}
                >
                  <option value="" disabled selected hidden>
                    Select Type
                  </option>
                  <option value="home">Home</option>
                  <option value="competition">Competition</option>
                </select>
                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Type
                </label>
                <span
                  style={{
                    color: "#D14F4F",
                    textAlign: "end",
                    fontSize: "14px",
                    opacity: itemErrors.SliTypeError ? 1 : 0,
                  }}
                >
                  {itemErrors.SliTypeError ?? "valid"}
                </span>
              </div>

              

              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="file"
                  name="floating_company"
                  id="floating_company"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                 

                  onChange={(e) => {
                    const selectedFile = e.target.files[0];

                    if (selectedFile) {
                      const allowedTypes = [
                        "image/jpeg",
                        "image/png",
                        "image/gif",
                      ];
                      if (allowedTypes.includes(selectedFile.type)) {
                        setSlider_image(selectedFile);
                        setItemErrors({
                          ...itemErrors,
                          sliderImageError: null,
                        });
                        setShowImage(true);
                      } else {
                        setItemErrors({
                          ...itemErrors,
                          sliderImageError:
                            "Please select a valid image file (jpeg, png, gif).",
                        });
                      }
                    }
                  }}
                  accept="image/jpeg, image/png, image/gif"
                />
                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Slider Image
                </label>

                <span
                  style={{
                    color: "#D14F4F",
                    textAlign: "end",
                    fontSize: "14px",
                    opacity: itemErrors.sliderImageError ? 1 : 0,
                  }}
                >
                  {itemErrors.sliderImageError ?? "valid"}
                </span>

                {slider_image && (
                  <>
                    {showImage ? (
                      <>
                        <img
                          src={URL.createObjectURL(slider_image)}
                          alt="After_Image"
                          style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                          }}
                        />
                      </>
                    ) : (
                      <img
                        src={slider_image}
                        alt="After_Image"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    )}
                  </>
                )}

               
              </div>
            </div>
            <div className="flex justify-end">
              <button type="submit" className="AddCompbutton2">
                {" "}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SlideComp;
