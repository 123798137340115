import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Validations } from "../../utils";
import { BACKEND_API_URL } from "../../Environment";
import axios from "axios";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";

const Registration = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === "/register") {
      import("../../custom-tailwind.css");
    }
  }, [pathname]);


  const ukPostcodeRegex =
  /^(?!.*(ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA|KY\d|MSR|VG|AI|GE\s?CX|GIR\s?0A{2}|SAN\s?TA1))(([A-Za-z]{1,2}[0-9][A-Za-z0-9]?|[A-Za-z]{2}\d|\d[A-Za-z]{2}|[A-Za-z]\d|\d[A-Za-z])\s?\d[A-Za-z]{2})$/i;

  let navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [termsCon, setTermsCon] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [address_line1, setAddress_line1] = useState("");
  const [doorNumber, setDoorNumber] = useState("");


  const [error, setError] = useState({
    userError: null,
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    PasswordError: null,
    cpasswordError: null,
    countryError: null,
    phoneError: null,
    isCheckedError: null,
    postCodeError: null,
    addressError: null,
    termsConError: null,
  });

  const validationSubmit = (e) => {
    e.preventDefault();
    const tempError = {
      firstNameError: Validations.firstName(firstName),
      lastNameError: Validations.lastName(lastName),
      emailError: Validations.email(email),
      PasswordError: Validations.password(Password),
      cpasswordError: Validations.confirmPassword(Password, cpassword),
      phoneError: Validations.phoneNumber(phoneNumber),
      isCheckedError: !isChecked && "This field is required",
      postCodeError: Validations.isValidZipCode(postcode),
      addressError: Validations.AddressRequired(address_line1),
      termsConError: !termsCon && "This field is required",
      doorError: Validations.doorNumberIs(doorNumber),
    };
    setError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    submitHandler(e);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${BACKEND_API_URL}register/`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: Password,
        confirm_password: cpassword,
        phone: phoneNumber,
        postcode: postcode,
        address_line1: address_line1,
        door_no:doorNumber,
      })
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        swal({
          title: "Successfully Complete",
          text: res.data.message,
          buttons: false,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/thank-you");
        }, 2000);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1);

        if (
          err.response.data.message.non_field_errors ===
          "User Name already taken"
        ) {
          setIsLoading(false);
          const tempErrors = {
            username: "Username already taken",
          };
          setError(tempErrors);
          return;
        } else if (
          err.response.data.message.non_field_errors?.[0] ===
          "Email must be unique"
        ) {
          setIsLoading(false);
          const tempErrors = {
            emailError: "Email already taken",
          };
          setError(tempErrors);
          return;
        } else {
          setIsLoading(false);
          const tempErrors = {
            email: "Email already taken",
            username: "Username already taken",
          };
          setError(tempErrors);
          return;
        }
      });
  };


  
  useEffect(() => {

    const handleSubmitPostCode = async () => {
      try {

        if (!postcode.match(ukPostcodeRegex)) {
          // If postcode doesn't match UK postcode regex, do nothing
          return;
        }

        const response = await axios.get(`https://api.postcodes.io/postcodes/${postcode}`);
        const { latitude, longitude } = response.data.result;

        const streetResponse = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
        const street = streetResponse.data.address.road;
        setAddress_line1(street)
        setError({ ...error, addressError: null });
      } catch (error) {
        // console.log("error------------",error)
        setAddress_line1('')
      }
    };


    handleSubmitPostCode();
  }, [postcode]);



  return (
    <>
      <div className="flex h-screen w-full items-center justify-center registration-sec">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="registerdiv create_form bg-white px-16 py-10 shadow-2xl  backdrop-blur-md max-sm:px-8">
            <div className="text-white">
              <div className="mb-8 flex flex-col items-center">
                <Link to="/">
                  <img
                    className="logoim_reg"
                    src="/images/logonew.png"
                    width="150"
                    alt=""
                    srcSet=""
                  />
                </Link>
                <span className="text-black">Create your LUX499 Account</span>
              </div>
              <form onSubmit={validationSubmit}>
                <div className="text-lg text-black  gap-2 register_formmm">
                  <div>
                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="text"
                        name="name"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setError({ ...error, firstNameError: null });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.firstNameError ? 1 : 0,
                      }}
                    >
                      {error.firstNameError ?? "valid"}
                    </span>
                  </div>
                  <div>
                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="text"
                        name="name"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setError({ ...error, lastNameError: null });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.lastNameError ? 1 : 0,
                      }}
                    >
                      {error.lastNameError ?? "valid"}
                    </span>
                  </div>
                </div>

                <div className=" text-lg text-black  gap-2 register_formmm">
                  <div>
                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="text"
                        name="name"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError({ ...error, emailError: null });
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.emailError ? 1 : 0,
                      }}
                    >
                      {error.emailError ?? "valid"}
                    </span>
                  </div>
                  <div>

                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="tel"
                        name="name"
                        placeholder="Enter Phone Number"
                        value={phoneNumber} 
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^\d+]/g,
                            ""
                          ); 
                          setPhoneNumber(numericValue);
                          setError({ ...error, phoneError: null });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.phoneError ? 1 : 0,
                      }}
                    >
                      {error.phoneError ?? "valid"}
                    </span>
                  </div>
                </div>

                <div className=" text-lg text-black  gap-2 register_formmm">
                  <div>
                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="text"
                        name="name"
                        placeholder="Post code"
                        value={postcode}
                        onChange={(e) => {
                          setPostcode(e.target.value);
                          setError({ ...error, postCodeError: null });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.postCodeError ? 1 : 0,
                      }}
                    >
                      {error.postCodeError ?? "valid"}
                    </span>
                  </div>

                  <div>
                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="text"
                        name="name"
                        placeholder="Door Number"
                        value={doorNumber}
                        onChange={(e) => {
                          setDoorNumber(e.target.value);
                          setError({ ...error, doorError: null });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.doorError ? 1 : 0,
                      }}
                    >
                      {error.doorError ?? "valid"}
                    </span>

                       {/* <div className="text-center registar_form select_register_form">
                    <select className='rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md'>
                    <option value="">Select a city</option>
                    {cities.map(city => (
                      <option key={city?.geonameId} value={city?.name}>{city?.name}</option>
                    ))}
                  </select>
                  </div> */}


                  </div>

                </div>



                <div className=" text-lg text-black ">

                <div>
                <div className="text-center registar_form">
                <input
                  className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md addresfield-register-sect"
                  type="text"
                  name="name"
                  placeholder="Address"
                  value={address_line1}
                  onChange={(e) => {
                    setAddress_line1(e.target.value);
                    setError({ ...error, addressError: null });
                  }}
                />
                </div>
                <span
                style={{
                  color: "#D14F4F",
                  display: "flex",
                  justifyContent: "end",
                  fontSize: "14px",
                  marginRight: "27px",
                  opacity: error.addressError ? 1 : 0,
                }}
                >
                {error.addressError ?? "valid"}
                </span>
                </div>

                </div>

                <div className=" text-lg text-black  gap-2 register_formmm">
                  <div>
                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="Password"
                        name="name"
                        placeholder="Password"
                        value={Password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setError({ ...error, PasswordError: null });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.PasswordError ? 1 : 0,
                      }}
                    >
                      {error.PasswordError ?? "valid"}
                    </span>
                  </div>
                  <div>
                    <div className="text-center registar_form">
                      <input
                        className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                        type="Password"
                        name="name"
                        placeholder="Confirm Password"
                        value={cpassword}
                        onChange={(e) => {
                          setCPassword(e.target.value);
                          setError({ ...error, cpasswordError: null });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.cpasswordError ? 1 : 0,
                      }}
                    >
                      {error.cpasswordError ?? "valid"}
                    </span>
                  </div>
                </div>
                
                <div className=" text-lg text-black  gap-[8rem] register_formmm">
                  <div>
                    <div className="text-center registar_form flex">
                      <input
                        checked={isChecked}
                        onChange={(e) => {
                          setIsChecked(e.target.checked);
                          setError({ ...error, isCheckedError: null });
                        }}
                        id="checked-checkbox"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-6 marginTop22"
                      />
                      <label
                        for="checked-checkbox"
                        className="ml-2 text-sm font-medium mb-0 text-black "
                      >
                       I am 18 years +
                      </label>
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        fontSize: "14px",
                        marginLeft: "47px",
                        opacity: error.isCheckedError ? 1 : 0,
                      }}
                    >
                      {error.isCheckedError ?? "valid"}
                    </span>
                  </div>
                  <div>
                    <div className="text-center registar_form flex">
                      <input
                        checked={termsCon}
                        onChange={(e) => {
                          setTermsCon(e.target.checked);
                          setError({ ...error, termsConError: null });
                        }}
                        id="checked-checkbox2"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-6 marginTop22"
                      />
                      <label
                        for="checked-checkbox2"
                        className="ml-2 text-sm font-medium mb-0 text-gray-900 dark:text-gray-300"
                      >
                        <Link className="linkColor2" to="/terms-to-play">
                          Terms and conditions
                        </Link>
                      </label>
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        fontSize: "14px",
                        marginLeft: "47px",
                        opacity: error.termsConError ? 1 : 0,
                      }}
                    >
                      {error.termsConError ?? "valid"}
                    </span>
                  </div>
                </div>

                <div className="mt-4 flex justify-center text-lg text-black">
                  <button
                    type="submit"
                    className="rounded-3xl w-80 bg-yellow-400 bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600"
                  >
                    Create Account
                  </button>
                </div>
                <div className="mt-4 flex justify-center text-lg text-black">
                <Link to="/privacy-policy">
                  <span className="text-sm ml-2 text-black hover:text-blue-500 cursor-pointer">
                    By signing up, you agree to our Privacy Policy
                  </span>
                  </Link>
                </div>

                <div className="mt-4 flex justify-center text-lg text-blue-800">
                  <Link to="/login">
                    <span className="text-lg text-blue ml-2 hover:text-yellow-600 cursor-pointer">
                      Sign In Instead.
                    </span>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Registration;
