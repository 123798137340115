import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetCompetitionCompAction } from "../../Redux/Actions/PublicActions";
import {
  AddToCartAction,
  WithoutUserAddToCartAction,
  GetpriceAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import Slider from "@mui/material/Slider";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { v4 as uuidv4 } from "uuid";


const CompetitionDeatilsPages = () => {
  const { comdetailsid } = useParams();
  const uniqueNumber = uuidv4();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [compDetails, setComDetails] = useState();
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [user_answer, setUser_answer] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [dataError, setDataError] = useState({ user_answerError: null });
  const [deviceidSave, setDeviceIdSave] = useState();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("DeviceIdUser");
    if (data) {
      setDeviceIdSave(data);
    } else {
      localStorage.setItem("DeviceIdUser", uniqueNumber);
    }
  }, [localStorage, uniqueNumber]);

  const {
    userData,
  } = useSelector((state) => state.authReducer);

  const {
    getCompDetails,
    success: getCompDetailsSuccess,
  } = useSelector((state) => state.GetCompetitionCompReducer);

  const { pricelist } = useSelector(
    (state) => state.pricelistreducer
  );

  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (pricelist?.length && userDataToken) {
  //       const freeItems = pricelist
  //         .filter(item => item.price === "0.00")
  //         .map(item => item.id);

  //       console.log(freeItems, "freeItems");

  //       if (freeItems.length > 0) {
  //         try {
  //           const response = await fetch(
  //             `${BACKEND_API_URL}competition/${freeItems.join(',')}`,
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${userDataToken?.token}`,
  //               },
  //             }
  //           );

  //           // Handle the response here
  //           const data = await response.json();
  //           console.log(data);

  //         } catch (error) {
  //           console.error("Error fetching competition data", error);
  //         }
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [pricelist]);



  const {
    AddtocartD,
    error: AddtocartDError,
  } = useSelector((state) => state.AddToCartReducer);

  const {
    WihtoutLoginAddtocartD,
    error: WithoutLoginAddtocartDError,
  } = useSelector((state) => state.WithoutUserAddToCartReducer);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (WihtoutLoginAddtocartD && render2) {
      swal({
        title: "Successfully Complete",
        text: "Add in Cart",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 2000,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      setTimeout(() => {
        navigate("/basket");
      }, 2000);
      setRender2(false);
    }
    if (WithoutLoginAddtocartDError && render2) {
      swal({
        title: "Error",
        text: WithoutLoginAddtocartDError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRender2(false);
      if (WithoutLoginAddtocartDError == "Competition has expired.") {
        setTimeout(() => {
          navigate("/competitons");
        }, 3000);
      }
    }
  }, [WihtoutLoginAddtocartD, WithoutLoginAddtocartDError]);

  useEffect(() => {
    if (AddtocartD && render) {
      swal({
        title: "Successfully Complete",
        text: "Add in Cart",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 2000,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      setTimeout(() => {
        navigate("/basket");
      }, 2000);
      setRender(false);
    }
    if (AddtocartDError && render) {
      swal({
        title: "Error",
        text: AddtocartDError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 7000,
      });
      setIsLoading(false);
      setRender(false);
      if (AddtocartDError == "Competition has expired.") {
        setTimeout(() => {
          navigate("/competitons");
        }, 3000);
      }
    }
  }, [dispatch, AddtocartD, AddtocartDError]);

  useEffect(() => {
    if (getCompDetails) {
      const data = getCompDetails?.[0];
      setComDetails(data);
    }
  }, [getCompDetailsSuccess]);

  useEffect(() => {
    if (comdetailsid) {
      dispatch(GetCompetitionCompAction(comdetailsid));
      dispatch(GetpriceAction(comdetailsid));
    }
  }, [comdetailsid, dispatch]);

  const ValidationSubmit = (item) => {
    const tempErrors = {
      user_answerError: !user_answer && "Please select one of these options",
    };
    setDataError(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleAddToCart(item);
  };

  const handleAddToCart = (item) => {
    const formData = new FormData();
    formData.append("competition_id", comdetailsid);
    formData.append("quantity", 1);
    formData.append("user_answer", user_answer);
    formData.append("package", item.id);
    if (!userData) {
      formData.append("device_id", deviceidSave);
    }
    if (userData) {
      setRender(true);
      setIsLoading(true);
      dispatch(AddToCartAction(formData));
    } else {
      setRender2(true);
      setIsLoading(true);
      dispatch(WithoutUserAddToCartAction(formData));
    }
  };

  const handleRadioClick = (id) => {
    setUser_answer(id);
    setDataError({ ...dataError, user_answerError: null });
  };

  const handleRedirectFun = (e) => {
    e.preventDefault();
    localStorage.setItem("Redirect_id", "eightPointThree");
    setTimeout(() => {
      navigate("/terms-to-play");
    }, 500);
  };

  const images = compDetails?.competition_media?.map((item) => ({
    original: item?.image,
    thumbnail: item?.image,
  }));



  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="cartpage add-height-in-competitions">
          {getCompDetails?.length > 0 ? (
            <>
              <div className="container">
                <h1 className="cart_titleee mb-4">{compDetails?.title}</h1>
                <p className="text-center mb-4">{compDetails?.subtitle}</p>
                <div className="shopping-cart">
                  <div className="shopping-cart-img">
                    <div>
                      <div className="compitition_bg_competitons">
                        <div>
                          {images?.length > 0 && (
                            <ImageGallery
                              items={images}
                              additionalclassName="my-gallery"
                              showPlayButton={false}
                              showFullscreenButton={false}
                              autoPlay={true}
                              slideInterval={5000}
                              renderItem={(item) => {
                                return (
                                  <div className="image-gallery-image">
                                    <img
                                      src={item.original}
                                      alt={item.originalAlt}
                                      srcSet={item.srcSet}
                                      sizes={item.sizes}
                                    />
                                  </div>

                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="shopping-cart-text">
                    <div className="pro-text">
                      <div className="mb-3 current_bodyy">
                        <h4>{compDetails?.question}</h4>
                      </div>

                      {compDetails?.competition_answer?.map((itemData, index) => (
                        <div
                          className={`flex items-center pl-3 ${itemData.id == user_answer
                            ? "checkb-border5"
                            : "checkb-border44"
                            }`}
                        >
                          <input
                            id={`list-radio-license ${index}`}
                            type="radio"
                            value=""
                            name="list-radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 check_box_shaddow_bg"
                            onClick={() => handleRadioClick(itemData.id)}
                          />
                          <label
                            htmlFor={`list-radio-license ${index}`}
                            className="w-full mb-0 py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {itemData?.answer}
                          </label>
                        </div>
                      ))}
                      <div className="messageforans">
                        <span
                          style={{
                            color: "#D14F4F",
                            textAlign: "end",
                            fontSize: "14px",
                            opacity: dataError.user_answerError ? 1 : 0,
                          }}
                        >
                          {dataError.user_answerError ?? "valid"}
                        </span>
                      </div>
                      <div>
                        <p className="quesAns-Text47">
                          This question must be answered correctly to be entered.
                        </p>
                        <p
                          className="quesAns-Text47 cursor-pointer"
                          onClick={(e) => handleRedirectFun(e)}
                        >
                          For a free postal entry, see <u>here</u>
                        </p>
                      </div>
                      <div className="grid grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-8 competitons_details">
                        {pricelist?.map((item, index) => {
                          return (
                            <div
                              className={`card-pricediv`}
                              key={index}
                              onClick={() => ValidationSubmit(item)}
                            >
                              <div className="card-show">
                                <div className="card-bodymain">
                                  {item?.number_of_tickets == 100 ? <div className="bainerTag">No Brainer</div> : item?.number_of_tickets == 200 ? <div className="bainerTag"> People's Choice</div> : item?.number_of_tickets == 400 ? <div className="bainerTag"> In 2 win</div> : null}
                                  <div className="packageprice">
                                    <h1 className="pricetag">
                                      {parseFloat(item.price) === 0
                                        ? "Free"
                                        : parseFloat(item.price) > 1.0
                                          ? `£${parseFloat(item.price).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}`
                                          : `${parseFloat(item.price)
                                            .toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                            })
                                            .replace(/^0?\./, "")}p`}
                                    </h1>


                                  </div>

                                  <div className="py-1">
                                    <p className="card-textpack card-titlepack">
                                      {Number(item.number_of_tickets).toFixed(0)}{" "}
                                      {Number(item.number_of_tickets).toFixed(0) <=
                                        1
                                        ? "Entry"
                                        : "Entries"}
                                    </p>
                                  </div>
                                  <div>
                                    <button
                                      className="bynowbtn"
                                    >
                                      Buy Now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-6 px-2 showsolderTickets">
                  <div className="showtheslideroftickets">
                    <div className="showthesolidtickets">
                      <p>
                        {Math.round(compDetails?.tickets_sold_percentage)}% of
                        tickets sold
                      </p>
                    </div>
                    <Slider
                      defaultValue={compDetails?.tickets_sold_quantity !== 0 ? compDetails?.tickets_sold_quantity : 0}
                      aria-label="Default"
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={compDetails?.max_tickets}
                      disabled={true}
                      className="slidercolor77"
                    />

                  </div>
                </div>
                <div className="text-center my-4">
                  <h4 className="cart_titleee">Prize Description</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: compDetails?.prize_description,
                    }}
                  ></p>
                </div>
              </div>

            </>
          ) : (
            <>
              <div className="no-data-found-competition">
                <p>No Data Found</p>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CompetitionDeatilsPages;
