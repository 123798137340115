import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAction } from "../../Redux/Actions/AuthAction";
import axios from "axios";
import swal from "sweetalert";
import { GetProcessToPayAction } from "../../Redux/Actions/AdminAction";
import { UpdateProfileAction } from "../../Redux/Actions/PublicActions";
import moment from "moment";
import { BACKEND_API_URL } from "../../Environment";
import { saveAs } from "file-saver";
import { MDBDataTable } from "mdbreact";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Validations } from "../../utils";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [div1, setdiv1] = useState(true);
  const [div2, setdiv2] = useState(false);
  const [dataProcess, setDataProcess] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [ren, setRen] = useState(false);
  const [userLocal, setUserLocal] = useState(null);
  const [dataItems, setDataItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
  const ukPostcodeRegex =
    /^(?!.*(ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA|KY\d|MSR|VG|AI|GE\s?CX|GIR\s?0A{2}|SAN\s?TA1))(([A-Za-z]{1,2}[0-9][A-Za-z0-9]?|[A-Za-z]{2}\d|\d[A-Za-z]{2}|[A-Za-z]\d|\d[A-Za-z])\s?\d[A-Za-z]{2})$/i;
  const [render2, setRender2] = useState(false);

  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    postcode: "",
    door_no: "",
    address_line1: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    postcode: "",
    door_no: "",
    address_line1: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "postcode") {
      handleSubmitPostCode();
    }
    setErrors({ ...errors, [name]: "" });
    setProfileData({ ...profileData, [name]: value });
  };

  const { userData: userDataToken } = useSelector((state) => state.authReducer);

  const {
    updateProfileData,
    success: editSuccess,
    error: editError,
  } = useSelector((state) => state.UpdateProfileReducer);

  const { GetProcessTo } = useSelector((state) => state.GetProcessToPayReducer);

  useEffect(() => {
    if (GetProcessTo) {
      setDataProcess(GetProcessTo);
    }
  }, [GetProcessTo]);

  useEffect(() => {
    dispatch(GetProcessToPayAction());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logoutAction());
    swal({
      title: "Success!",
      text: "Logout successfully!",
      className: "successAlert",
      buttons: false,
      timer: 3000,
      open: true,
    }).then(() => {
      navigate("/");
    });
  };

  const handleClickView = (selectedId) => {
    setOpen(true);
    const filteredData = dataProcess.filter((item1) => item1.id == selectedId);
    setDataItems(filteredData);
  };

  const handleClickedit = (selectedId) => {
    setOpen1(true);
    setRen(false);
    const filteredData = dataProcess.filter((item) => item.id == selectedId);
    setDataItems(filteredData);
  };

  const handleClickDownload = async (CId) => {
    try {
      const response = await fetch(`${BACKEND_API_URL}download-order/${CId}/`, {
        headers: {
          Authorization: `Bearer ${userDataToken?.token}`,
        },
      });

      if (response.ok) {
        const filenameHeader = response.headers.get("Content-Disposition");
        const matches = /filename="([^"]+)"/.exec(filenameHeader);
        const filename = matches ? matches[1] : "competition.csv";

        const blob = await response.blob();
        saveAs(blob, filename);
      } else {
        console.error("Error downloading CSV file:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading CSV file:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDataItems([]);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setDataItems([]);
    setRen(true);
  };

  useEffect(() => {
    let userData = [];

    if (dataProcess) {
      dataProcess?.map((item, index) => {
        item.title = item.id;
        item.start_date = "£" + item.payment_amount;

        item.is_active = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              {item.is_paid ? "Paid" : "Failed"}
            </div>
          </div>
        );
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              {moment(item.updated_at).format("MM-DD-yyyy hh:mm a")}
            </div>
          </div>
        );
        item.afterbefore = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <button
                className="eyeicon"
                onClick={() => handleClickView(item.id)}
              >
                <i class="fa fa-eye"></i>
              </button>
            </div>
          </div>
        );
        item.dwonload = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <button
                className="eyeicon"
                onClick={() => handleClickDownload(item.id)}
              >
                <i class="fas fa-file-export"></i>
              </button>
            </div>
          </div>
        );
        userData.push(item);
      });
    }

    setUsersForRender(userData);
  }, [dataProcess]);

  const data1 = {
    columns: [
      {
        label: "Order Id",
        field: "title",
        sort: "asc",
        width: 500,
      },
      {
        label: "Amount",
        field: "start_date",
        sort: "asc",
        width: 500,
      },
      {
        label: "Payment Status",
        field: "is_active",
        sort: "asc",
        width: 500,
      },

      {
        label: "Order Date",
        field: "action",
        sort: "asc",
        width: 100,
      },
      {
        label: "View Items",
        field: "afterbefore",
        sort: "asc",
        width: 100,
      },
      {
        label: "Export",
        field: "dwonload",
        sort: "asc",
        width: 500,
      },
    ],
    rows: usersForRender,
  };
  useEffect(() => {
    if (userLocal?.user) {
      setProfileData({
        first_name: userLocal.user.first_name || "",
        last_name: userLocal.user.last_name || "",
        email: userLocal.user.email || "",
        phone: userLocal.user.phone || "",
        postcode: userLocal.user.postcode || "",
        door_no: userLocal.user.door_no || "",
        address_line1: userLocal.user.address_line1 || "",
        // time: moment(userLocal.user.date_joined).format("HH:mm") || "",
        // date: moment(userLocal.user.date_joined).format("YYYY-MM-DD") || "",
      });
    }
  }, [userLocal, ren]);
  const validationSubmit = (e) => {
    e.preventDefault();
    let tempErrors = {
      first_name: Validations.firstName(profileData?.first_name),
      last_name: Validations.lastName(profileData?.last_name),
      phone: Validations.phoneNumber(profileData?.phone),
      postcode: Validations.isValidZipCode(profileData?.postcode),
      door_no: Validations.doorNumberIs(profileData?.door_no),
      address_line1: Validations.AddressRequired(profileData?.address_line1),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleSubmit(e);
  };
  const handleSubmit = () => {
    if (profileData) {
      const formd = new FormData();
      Object.keys(profileData).forEach((key) => {
        formd.append(key, profileData[key]);
      });
      formd.append("role", userDataToken?.user?.role);
      dispatch(UpdateProfileAction(formd));
    }
    setRender2(true);
    setUserLocal(userDataToken);
  };

  const handleSubmitPostCode = async () => {
    try {
      if (!profileData?.postcode.match(ukPostcodeRegex)) {
        // If postcode doesn't match UK postcode regex, do nothing
        return;
      }

      const response = await axios.get(
        `https://api.postcodes.io/postcodes/${profileData?.postcode}`
      );
      const { latitude, longitude } = response.data.result;

      const streetResponse = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const street = streetResponse.data.address.road;
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        address_line1: street,
      }));
    } catch (error) {
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
      }));
    }
  };

  const updateUserInLocalStorage = (updatedUser) => {
    localStorage.setItem("userData", JSON.stringify(updatedUser));
  };
  useEffect(() => {
    if (userDataToken) {
      setUserLocal(userDataToken);
    }
  }, [localStorage]);

  useEffect(() => {
    if (editSuccess && render2) {
      swal({
        title: "Profile Updated Successfully",
        // text: "Edit Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });

      const updatedUser = {
        ...userLocal,
        user: { ...userLocal.user, ...updateProfileData },
      };
      // Update user object in local storage
      updateUserInLocalStorage(updatedUser);
      // Update state to reflect changes
      setUserLocal(updatedUser);
      setRender2(false);
      setOpen1(false);
    }
    if (editError && render2) {
      swal({
        title: "Error",
        text: "Something went wrong",
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setRender2(false);
    }
  }, [dispatch, editSuccess, editError]);

  return (
    <>
      <div className="user_profile">
        <div className="compitition_future text-center winner_title">
          <h2>My Profile</h2>
        </div>
        <div className="container">
          <div className="flex ComplImage1 user_profile_new">
            <div className="rounded-md border7676 tabs_menu">
              <div
                className={`text-gray-600  cursor-pointer profile-hover ${
                  div1 ? "active" : ""
                }`}
                onClick={() => {
                  setdiv2(false);
                  setdiv1(true);
                }}
              >
                <i className="fa fa-user" aria-hidden="true"></i>
                <span> Profile</span>
              </div>
              <div
                className={`text-gray-600 cursor-pointer profile-hover ${
                  div2 ? "active" : ""
                }`}
                onClick={() => {
                  setdiv2(true);
                  setdiv1(false);
                }}
              >
                <i className="fa fa-film" aria-hidden="true"></i>
                <span> My Orders</span>
              </div>
              <div className=" profile-hover">
                <button
                  onClick={handleLogout}
                  className=" items-center text-gray-600 profile-hover1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 logouticon"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      strokeWidth="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  <span className="group-hover:text-gray-700">Logout</span>
                </button>
              </div>
            </div>
            <div className="w-[100%]">
              <div className="editsec">
                <p>
                  <button className="eyeicon" onClick={() => handleClickedit()}>
                    <i class="fa fa-edit"></i>
                  </button>
                </p>
              </div>
              {div1 && (
                <div className="p-4">
                  <div className="flex">
                    <div className="flex items-baseline user_name">
                      <i className="fa fa-user me-1" aria-hidden="true"></i>
                      <p className="">
                        {userLocal?.user?.first_name}{" "}
                        {userLocal?.user?.last_name}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex items-baseline user_name">
                      <i className="fa fa-envelope me-1" aria-hidden="true"></i>
                      <p>{userLocal?.user?.email}</p>
                    </div>
                  </div>
                  {userLocal?.user?.phone && (
                    <div className="flex">
                      <div className="flex items-baseline user_name">
                        <i className="fa fa-phone me-1" aria-hidden="true"></i>
                        <p>{userLocal?.user?.phone}</p>
                      </div>
                    </div>
                  )}
                  {userLocal?.user?.postcode && (
                    <div className="flex">
                      <div className="flex items-baseline user_name">
                        <i className="fa-solid fa-lock" aria-hidden="true"></i>
                        <p>{userLocal?.user?.postcode}</p>
                      </div>
                    </div>
                  )}
                  {userLocal?.user?.door_no && (
                    <div className="flex">
                      <div className="flex items-baseline user_name">
                        <i
                          className="fa-solid fa-address-book"
                          aria-hidden="true"
                        ></i>
                        <p>{userLocal?.user?.door_no}</p>
                      </div>
                    </div>
                  )}
                  {userLocal?.user?.address_line1 && (
                    <div className="flex">
                      <div className="flex items-baseline user_name">
                        <i
                          className="fa-solid fa-address-book"
                          aria-hidden="true"
                        ></i>
                        <p>{userLocal?.user?.address_line1}</p>
                      </div>
                    </div>
                  )}

                  <div className="flex">
                    <div className="flex items-baseline user_name">
                      <i
                        className="fa fa-calendar-o me-1"
                        aria-hidden="true"
                      ></i>
                      <p>
                        {moment(userLocal?.user?.date_joined).format(
                          "MM-DD-yyyy hh:mm a"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {div2 && (
                <>
                  {dataProcess.length == 0 ? (
                    <>
                      <div className="notanyorder">
                        <p className="notanyordercontent">You have no orders</p>
                      </div>
                    </>
                  ) : (
                    <div className=" ">
                      <div className="col-md-12 py-2">
                        <div className="card_profile userprofilesec">
                          <div className="card-body p-0 ">
                            <MDBDataTable
                              style={{}}
                              responsive
                              striped
                              bordered
                              small
                              data={data1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <Dialog
                    className="dialogsctionmain"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <h4 className="flex justify-center title-top">
                        Order Items
                      </h4>
                      <div className="userprofilepage">
                        <table>
                          {dataItems?.[0]?.order_items?.map((items_, index) => (
                            <>
                              <tbody>
                                <tr>
                                  <th>
                                    <p className="userdiv1">Order Item ID</p>
                                  </th>
                                  <td>
                                    <p className="itemdiv2">#{items_.id}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="userdiv1">Competition</p>
                                  </th>
                                  <td>
                                    <p className="itemdiv2">
                                      {items_.competition_title}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="userdiv1">Order Status </p>
                                  </th>
                                  <td>
                                    <p className="itemdiv2">
                                      {items_.is_paid ? "Paid" : "Failed"}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="userdiv1">Order Item Price</p>{" "}
                                  </th>
                                  <td>
                                    <p className="itemdiv2">
                                      £{items_.paid_price}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="userdiv1">Answer</p>
                                  </th>
                                  <td>
                                    <p className="itemdiv2">
                                      {items_.user_answer}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    {" "}
                                    <p className="userdiv1">
                                      Purchased Tickets
                                    </p>
                                  </th>
                                  <td>
                                    <p className="itemdiv2">
                                      {items_.number_of_tickets}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    {" "}
                                    <p className="userdiv1">
                                      Tickets Number
                                    </p>{" "}
                                  </th>
                                  <td>
                                    <p className="itemdiv2">
                                      {items_.ticket_numbers}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                              <hr />
                            </>
                          ))}
                        </table>
                      </div>
                      <div className="userdivbtndiv">
                        {" "}
                        <button className="AddCompbutton" onClick={handleClose}>
                          Close
                        </button>
                      </div>
                      <div></div>
                    </DialogTitle>
                  </Dialog>
                </>
              )}
            </div>

            <Dialog
              className="dialogsctionmainuser"
              open={open1}
              onClose={handleClose1}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <h2 className="font-semibold text-center">Edit Profile</h2>
                <div className="userdivbtndiv">
                  <button className="AddCompbuttonuser" onClick={handleClose1}>
                    X
                  </button>
                </div>
                <div className="userform">
                  <div className="input_secuser">
                    <div className="input_secuser_left">
                      <input
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        value={profileData.first_name}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          textAlign: "end",
                          fontSize: "14px",
                          opacity: errors.first_name ? 1 : 0,
                        }}
                      >
                        {errors.first_name ?? "valid"}
                      </span>
                    </div>
                    <div className="input_secuser_right">
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        value={profileData.last_name}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          textAlign: "end",
                          fontSize: "14px",
                          opacity: errors.last_name ? 1 : 0,
                        }}
                      >
                        {errors.last_name ?? "valid"}
                      </span>
                    </div>
                  </div>
                  <div className="input_secuser">
                    <div className="input_secuser_left">
                      <input
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        disabled
                        value={profileData.email}
                      />
                    </div>
                    <div className="input_secuser_right">
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Enter Phone Number"
                        value={profileData.phone}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          textAlign: "end",
                          fontSize: "14px",
                          opacity: errors.phone ? 1 : 0,
                        }}
                      >
                        {errors.phone ?? "valid"}
                      </span>
                    </div>
                  </div>
                  <div className="input_secuser">
                    <div className="input_secuser_left">
                      <input
                        type="text"
                        name="postcode"
                        placeholder="Post code"
                        value={profileData.postcode}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          textAlign: "end",
                          fontSize: "14px",
                          opacity: errors.postcode ? 1 : 0,
                        }}
                      >
                        {errors.postcode ?? "valid"}
                      </span>
                    </div>
                    <div className="input_secuser_right">
                      <input
                        type="text"
                        name="door_no"
                        placeholder="Door Number"
                        value={profileData.door_no}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          textAlign: "end",
                          fontSize: "14px",
                          opacity: errors.door_no ? 1 : 0,
                        }}
                      >
                        {errors.door_no ?? "valid"}
                      </span>
                    </div>
                  </div>
                  <div className="Address">
                    <input
                      type="text"
                      name="address_line1"
                      placeholder="Address"
                      value={profileData.address_line1}
                      onChange={handleInputChange}
                    />
                    <span
                      style={{
                        color: "#D14F4F",
                        textAlign: "end",
                        fontSize: "14px",
                        opacity: errors.address_line1 ? 1 : 0,
                      }}
                    >
                      {errors.address_line1 ?? "valid"}
                    </span>
                  </div>

                  <div className="userProfile">
                    <button
                      type="button"
                      onClick={validationSubmit}
                      className="savebtndiv"
                    >
                      save
                    </button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
