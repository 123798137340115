import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userRegisterReducer,
} from "./Redux/Reducers/AuthReducer";

import {
  CreateCompetitionReducer,
  CompetitionListReducer,
  CompetitionListForUserReducer,
  CompetitionDeleteReducer,
  EditCompetitionReducer,
  GetCompetitionByIdReducer,
  AddBeforeAfterImagesReducer,
  EditBeforeAfterImagesReducer,
  GetEditBeforeAfterImagesReducer,
  UpdateBeforeAfterImagesReducer,
  DeleteBeforeAfterImagesReducer,
  AddSliderImagesReducer,
  GetSliderImageListReducer,
  DeleteSliderReducer,
  EditSliderImageReducer,
  UpdateSliderImagesReducer,
  ShowSliderImageHomeReducer,
  ShowSliderImageCompetitionReducer,
  HomePageContentSendReducer,
  GetHomePageContentReducer,
  UpdateHomePageContentReducer,
  AddToCartReducer,
  WithoutUserAddToCartReducer,
  AddWinnersReducer,
  GetWinnersReducer,
  DeleteWinnersActionReducer,
  EditWinnersReducer,
  GetByIdWinnersReducer,
  pricelistreducer,
  userlistreducer,
  GetCartListReducer,
  WithoutLoginGetCartListReducer,
  UpdateCartListReducer,
  WLoginUpdateCartListReducer,
  DeleteCartItemReducer,
  WLoginDeleteCartItemReducer,
  GetPastCompetitionReducer,
  ProcessToPayReducer,
  GetProcessToPayReducer,
  CompetitionAnsDeleteReducer,
} from "./Redux/Reducers/AdminReducers";

import {
  SupportSendReducer,
  GetcHomeIsFeaturedReducer,
  GetCompetitionCompReducer,
  UpdateProfileReducer,
} from "./Redux/Reducers/PublicReducer";

const reducer = combineReducers({
  authReducer: userLoginReducer,
  userRegisterReducer,
  CreateCompetitionReducer,
  CompetitionListReducer,
  CompetitionListForUserReducer,
  CompetitionDeleteReducer,
  EditCompetitionReducer,
  GetCompetitionByIdReducer,
  AddBeforeAfterImagesReducer,
  EditBeforeAfterImagesReducer,
  GetEditBeforeAfterImagesReducer,
  UpdateBeforeAfterImagesReducer,
  DeleteBeforeAfterImagesReducer,
  AddSliderImagesReducer,
  GetSliderImageListReducer,
  DeleteSliderReducer,
  EditSliderImageReducer,
  UpdateSliderImagesReducer,
  ShowSliderImageHomeReducer,
  ShowSliderImageCompetitionReducer,
  HomePageContentSendReducer,
  GetHomePageContentReducer,
  UpdateHomePageContentReducer,
  SupportSendReducer,
  GetcHomeIsFeaturedReducer,
  GetCompetitionCompReducer,
  AddToCartReducer,
  WithoutUserAddToCartReducer,
  AddWinnersReducer,
  GetWinnersReducer,
  DeleteWinnersActionReducer,
  EditWinnersReducer,
  GetByIdWinnersReducer,
  pricelistreducer,
  userlistreducer,
  GetCartListReducer,
  WithoutLoginGetCartListReducer,
  UpdateCartListReducer,
  WLoginUpdateCartListReducer,
  DeleteCartItemReducer,
  WLoginDeleteCartItemReducer,
  GetPastCompetitionReducer,
  ProcessToPayReducer,
  GetProcessToPayReducer,
  CompetitionAnsDeleteReducer,
  UpdateProfileReducer,
});
const userDataFromStorage = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;

  const initialState = {
    authReducer: { userData: userDataFromStorage },
  };

  const middleware = [thunk];

  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  
  export default store;
