import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  EditBeforeAfterImagesAction,
  GetEditBeforeAfterImagesAction,
  UpdateBeforeAfterImagesAction,
  DeleteBeforeAfterImagesAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const EditBeforeAfterImages = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [usersForRender, setUsersForRender] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [website_url, setWebsite_url] = useState("");
  const [after_image, setAfter_image] = useState(null);
  const [before_image, setBefore_image] = useState(null);
  const [showImages, setShowimages] = useState(false);
  const [showImages2, setShowimages2] = useState(false);
  const [imagesId, setImagesId] = useState()
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [competitionTitle, setCompetitiomTitle] = useState("")
  const [isChecked, setIsChecked] = useState(true);
  const [description, setDescription] = useState("");


  const [itemErrors, setItemErrors] = useState([
    {
      afterImageError: null,
      beforeImageError: null,
      titleError: null,
      website_urlError: null,
    },
  ]);

  const {
    EditBFImages,
    success: EditBFImagesSuccess,
  } = useSelector((state) => state.EditBeforeAfterImagesReducer);

  const {
    getEditBFImages,
  } = useSelector((state) => state.GetEditBeforeAfterImagesReducer);


  const {
    UpdateBFImages,
    error: UpdateBFImagesError,
    success: UpdateBFImagesSuccess,
  } = useSelector((state) => state.UpdateBeforeAfterImagesReducer);

  const {
    delBFImages,
    error: delBFImagesError,
    success: delBFImagesSuccess,
  } = useSelector((state) => state.DeleteBeforeAfterImagesReducer);


  useEffect(() => {
    if (id) {
      dispatch(EditBeforeAfterImagesAction(id));
    }
  }, [id, UpdateBFImagesSuccess, delBFImagesSuccess]);

  useEffect(() => {
    if (getEditBFImages) {
      setTitle(getEditBFImages?.title);
      setWebsite_url(getEditBFImages?.website_url);
      setAfter_image(getEditBFImages?.after_image);
      setBefore_image(getEditBFImages?.before_image);
      setShowimages(false)
      setShowimages(false)
      setCompetitiomTitle(getEditBFImages?.competition_title)
      setIsChecked(getEditBFImages?.is_active)
      setDescription(getEditBFImages?.short_desc)

    }
  }, [getEditBFImages]);


  useEffect(() => {
    if (delBFImages && render2) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
      }, 1000);
      setRender2(false);
    }
    if (delBFImagesError && render2) {
      swal({
        title: "Error",
        text: delBFImagesError,
        className: "errorAlert-login",
        buttons: false,
        timer: 1500,
      });
      setRender2(false);
    }
  }, [dispatch, delBFImages, delBFImagesError]);


  useEffect(() => {
    if (UpdateBFImages && render) {
      swal({
        title: "Successfully Complete",
        text: "Updated Success",
        className: "successAlert-login",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
      }, 1000);
      setRender(false);
      setOpen(false);
      setTitle("");
      setWebsite_url("");
      setAfter_image(null);
      setBefore_image(null);
      setShowimages(false);
      setShowimages2(false);
      setImagesId()

    }
    if (UpdateBFImagesError && render) {
      swal({
        title: "Error",
        text: UpdateBFImagesError,
        className: "errorAlert-login",
        buttons: false,
        timer: 2000,
      });
      setRender(false);
    }
  }, [dispatch, UpdateBFImages, UpdateBFImagesError]);

  useEffect(() => {
    let userData = [];

    if (EditBFImages) {
      EditBFImages?.map((item, index) => {
        item.title = item.title;
        item.website_url = item.website_url;

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <button
                title="edit"
                className="iconbtn newbtn44"
                onClick={() => handleClickEdit(item.id)}
              >
                <p className="editiconDelete1 m-2">
                  {" "}
                  <i className="fas fa-pencil-alt"></i>
                </p>
              </button>
              <button title="delete" className=" newbtn44 iconbtn">
                <p
                  className="editiconDelete1 m-2"
                  onClick={() => deleteHandler(item.id)}
                >
                  <i className="fa fa-trash"></i>
                </p>
              </button>
            </div>
          </div>
        );
        userData.push(item);
      });
    }

    setUsersForRender(userData);
  }, [EditBFImagesSuccess]);

  const data1 = {
    columns: [
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 500,
      },
      {
        label: "Web site url",
        field: "website_url",
        sort: "asc",
        width: 500,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: usersForRender,
  };

  const handleClickEdit = (id) => {
    setImagesId(id)
    dispatch(GetEditBeforeAfterImagesAction(id));
    setOpen(true);
  };

  const deleteHandler = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setRender2(true)
        dispatch(DeleteBeforeAfterImagesAction(id))
      }
    });

  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      titleError: !title && "Please Enter Title",
      website_urlError: website_url && !website_url.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/)
        ? "Please Enter valid WebSite Url"
        : null,
      afterImageError: !after_image ? "Please Enter After Image" : null,
      beforeImageError: !before_image ? "Please Enter Before Image" : null,
    };

    setItemErrors(tempErrors);

    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    handleAddBeforeAfter(e);
  };

  const handleAddBeforeAfter = (e) => {
    setRender(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("website_url", website_url);
    formData.append("is_active", isChecked);
    if (showImages) {
      formData.append("after_image", after_image);
    }
    if (showImages2) {
      formData.append("before_image", before_image);
    }
    formData.append("competition", id);
    formData.append("short_desc", description)

    dispatch(UpdateBeforeAfterImagesAction(imagesId, formData));
  };

  return (
    <>
      <div className="main-center">
        <div className="showthecontent">
          <h1 className="componentheading">{competitionTitle} AFTER BEFORE IMAGES</h1>
          <Link to="/competition-list">
            <button className="AddCompbutton">Back</button>
          </Link>
        </div>
        <div className="center-section">
          <div>
            <MDBDataTable
              style={{}}
              responsive
              striped
              bordered
              small
              data={data1}
            />
          </div>

          <Dialog
            className="dialogsctionmain"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h4 className="flex justify-center title-top">
                Edit After Before Images
              </h4>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">

                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-6 m-6">
                  <>

                    <div>
                      <label
                        for="first_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Enter Title"
                        required

                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setItemErrors({ ...itemErrors, titleError: null });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          opacity: itemErrors.titleError ? 1 : 0,
                        }}
                      >
                        {itemErrors?.titleError ?? "valid"}
                      </span>
                    </div>
                    <div>
                      <label
                        for="website"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Web Site Url
                      </label>
                      <input
                        type="text"
                        id="website"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Enter Website Url"
                        required

                        value={website_url}
                        onChange={(e) => {
                          setWebsite_url(e.target.value);
                          setItemErrors({
                            ...itemErrors,
                            website_urlError: null,
                          });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          opacity: itemErrors?.website_urlError ? 1 : 0,
                        }}
                      >
                        {itemErrors.website_urlError ?? "valid"}
                      </span>
                    </div>

                    <div>
                      <div>
                        <label>Before Image</label>
                        <input
                          onChange={(e) => {
                            const selectedFile = e.target.files[0];

                            if (selectedFile) {
                              const allowedTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/gif",
                              ];
                              if (allowedTypes.includes(selectedFile.type)) {
                                setBefore_image(selectedFile);
                                setItemErrors({
                                  ...itemErrors,
                                  beforeImageError: null,
                                });
                                setShowimages2(true);
                              } else {
                                setItemErrors({
                                  ...itemErrors,
                                  beforeImageError:
                                    "Please select a valid image file (jpeg, png, gif).",
                                });
                              }
                            }
                          }}
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                        />
                      </div>
                      <span
                        style={{
                          color: "#D14F4F",
                          opacity: itemErrors?.beforeImageError ? 1 : 0,
                        }}
                      >
                        {itemErrors?.beforeImageError ?? "valid"}
                      </span>
                      {before_image && (
                        <>
                          {showImages2 ? (
                            <>
                              <img
                                src={URL.createObjectURL(before_image)}
                                alt="After_Image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            </>
                          ) : (
                            <img
                              src={before_image}
                              alt="After_Image"
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <div>
                        <label>After Image</label>
                        <input
                          onChange={(e) => {
                            const selectedFile = e.target.files[0];

                            if (selectedFile) {
                              const allowedTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/gif",
                              ];
                              if (allowedTypes.includes(selectedFile.type)) {
                                setAfter_image(selectedFile);
                                setItemErrors({
                                  ...itemErrors,
                                  afterImageError: null,
                                });
                                setShowimages(true);
                              } else {
                                setItemErrors({
                                  ...itemErrors,
                                  afterImageError:
                                    "Please select a valid image file (jpeg, png, gif).",
                                });
                              }
                            }
                          }}
                          type="file"
                          accept="image/jpeg, image/png, image/gif"

                        />
                      </div>
                      <span
                        style={{
                          color: "#D14F4F",
                          opacity: itemErrors?.afterImageError ? 1 : 0,
                        }}
                      >
                        {itemErrors?.afterImageError ?? "valid"}
                      </span>
                      {after_image && (
                        <>
                          {showImages ? (
                            <>
                              <img
                                src={URL.createObjectURL(after_image)}
                                alt="After_Image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={after_image}
                                alt="After_Image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <div className="flex items-center">
                        <label
                          for="checked-checkbox"
                          className="ml-2 text-sm font-medium mb-0 text-gray-900 dark:text-gray-300"
                        >
                          Status
                        </label>
                        <input
                          checked={isChecked} 
                          onChange={(e) => setIsChecked(e.target.checked)}
                          id="checked-checkbox"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-6"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="comment"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Description
                      </label>
                      <div className="w-full border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                        <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                          <textarea
                            id="comment"
                            rows="4"
                            className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                            placeholder="Write a description..."
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value)
                              setItemErrors({
                                ...itemErrors,
                                description: null,
                              });
                            }}

                          />

                        </div>

                      </div>

                    </div>
                  </>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="AddCompbutton" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="AddCompbutton"
                onClick={(e) => validateSubmit(e)}
                autoFocus
              >
                Submit
              </button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default EditBeforeAfterImages;
