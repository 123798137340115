import React, { useState, useEffect } from "react";
import {
  GetCartListAction,
  WithoutLoginGetCartListAction,
  UpdateCartListAction,
  WLoginUpdateCartListAction,
  DeleteCartItemAction,
  WLoginDeleteCartItemAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    userData,
  } = useSelector((state) => state.authReducer);
  const [products, setProducts] = useState();
  const [products2, setProducts2] = useState();
  const [totalItems, setTotalItems] = useState();
  const [updateProducts, setUpdateProducts] = useState([]);
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [render3, setRender3] = useState(false);
  const [render4, setRender4] = useState(false);
  const [navigatestate, setnavigatestate] = useState(false);
  const [deviceIdData, setDeviceIdData] = useState();
  const {
    UpdateCart,
    error: UpdateCartError,
    success: UpdateCartSuccess,
  } = useSelector((state) => state.UpdateCartListReducer);

  const {
    WLoignUpdateCart,
    error: WLoignUpdateCartError,
    success: WLoignUpdateCartSuccess,
  } = useSelector((state) => state.WLoginUpdateCartListReducer);

  const {
    DelCartItem,
    error: DelCartItemError,
    success: DelCartItemSuccess,
  } = useSelector((state) => state.DeleteCartItemReducer);

  const {
    WLoginDelCartItem,
    error: WLoginDelCartItemError,
  } = useSelector((state) => state.WLoginDeleteCartItemReducer);

  const {
    GetCartList,
  } = useSelector((state) => state.GetCartListReducer);

  const {
    WithoutLoginGetCartList,
  } = useSelector((state) => state.WithoutLoginGetCartListReducer);

  useEffect(() => {
    const data = localStorage.getItem("DeviceIdUser");
    if (data) {
      setDeviceIdData(data);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      if (GetCartList) {
        setProducts(GetCartList?.cart_items);
        setProducts2(GetCartList?.cart_items);
        setTotalItems(GetCartList?.totals);
      }
    } else {
      if (WithoutLoginGetCartList) {
        setProducts(WithoutLoginGetCartList?.cart_items);
        setProducts2(WithoutLoginGetCartList?.cart_items);
        setTotalItems(WithoutLoginGetCartList?.totals);
      }
    }
  }, [GetCartList, WithoutLoginGetCartList]);

  useEffect(() => {
    if (userData) {
      dispatch(GetCartListAction());
    } else {
      if (deviceIdData) {
        dispatch(WithoutLoginGetCartListAction(deviceIdData));
      }
    }
  }, [
    userData,
    UpdateCartSuccess,
    WLoignUpdateCartSuccess,
    DelCartItemSuccess,
    DelCartItemError,
    deviceIdData,
  ]);

  const increaseQuantity = (i) => {
    setStateFor(true);
    const updatedProducts = products2.map((data, o) => {
      if (i == o) {
        return {
          ...data,
          quantity: data.quantity + 1,
        };
      }
      return data;
    });

    const updatedProductIndex = updateProducts.findIndex(
      (item) => item.id == products[i].id
    );

    if (updatedProductIndex !== -1) {
      const updatedUpdateProducts = [...updateProducts];
      updatedUpdateProducts[updatedProductIndex].quantity += 1;
      setUpdateProducts(updatedUpdateProducts);
    } else {
      const updatedProduct = {
        id: products[i].id,
        quantity: products[i].quantity + 1,
        competion_id: products[i].competition_id,
      };
      setUpdateProducts([...updateProducts, updatedProduct]);
    }

    setProducts2(updatedProducts);
  };

  const decreaseQuantity = (i) => {
    setStateFor(true);
    const updatedProducts = products2.map((data, o) => {
      if (i == o) {
        if (data.quantity > 1) {
          return { ...data, quantity: data.quantity - 1 };
        }
      }
      return data;
    });

    const updatedProductIndex = updateProducts.findIndex(
      (item) => item.id == products[i].id
    );

    if (updatedProductIndex !== -1) {
      const updatedUpdateProducts = [...updateProducts];
      if (updatedUpdateProducts[updatedProductIndex].quantity > 1) {
        updatedUpdateProducts[updatedProductIndex].quantity -= 1;
      }
      setUpdateProducts(updatedUpdateProducts);
    } else {
      const updatedProduct = {
        id: products[i].id,
        quantity: products[i].quantity > 1 ? products[i].quantity - 1 : 1, // Ensure quantity is at least 1
        competion_id: products[i].competition_id,
      };
      setUpdateProducts([...updateProducts, updatedProduct]);
    }

    setProducts2(updatedProducts);
  };

  useEffect(() => {
    if (WLoignUpdateCart && render4 && !navigatestate) {
      swal({
        title: "Successfully Complete",
        text: "Update Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setRender4(false);
    }
    if (WLoignUpdateCartError && render4) {
      swal({
        title: "Error",
        text: WLoignUpdateCartError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setRender4(false);
      if (
        UpdateCartError ==
        "Cart item not found because some Competition has expired, please order again"
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
    if (WLoignUpdateCart && render4 && navigatestate) {
      navigate("/checkout"); // Navigate to the checkout page
    }
  }, [dispatch,WLoignUpdateCart, WLoignUpdateCartError,render4]);

  useEffect(() => {
    if (UpdateCart && render2 && !navigatestate) {
      swal({
        title: "We Have Updeted Cart",
        text: "Successfully",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setRender2(false);
    }
    if (UpdateCartError && render2) {
      setUpdateProducts([]);
      swal({
        title: "Error",
        text: UpdateCartError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setRender2(false);

      if (
        UpdateCartError ==
        "Cart item not found because some Competition has expired, please order again"
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
    if (UpdateCart && render2 && navigatestate) {
      navigate("/checkout");
    }
  }, [dispatch,UpdateCart, UpdateCartError]);

  useEffect(() => {
    if (WLoginDelCartItem && render3 ) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setRender3(false);
    }
    if (WLoginDelCartItemError && render3 ) {
      swal({
        title: "Error",
        text: WLoginDelCartItemError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setRender3(false);
      if (
        UpdateCartError ==
        "Cart item not found because some Competition has expired, please order again"
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  }, [dispatch, WLoginDelCartItem, WLoginDelCartItemError,navigatestate]);

  useEffect(() => {
    if (DelCartItem && render) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setRender(false);
     
    }
    if (DelCartItemError && render) {
      swal({
        title: "Error",
        text: "Cart item not found because competition has expired.",
        className: "errorAlert-login",
        buttons: false,
        timer: 5000,
      });
      setRender(false);
    }
  
  }, [dispatch, DelCartItem, DelCartItemError,navigatestate]);

  const removeFromCart = (i, id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) { 
        if (userData) {
          setRender(true);
          dispatch(DeleteCartItemAction(id));
          setProducts((prevCart) =>
          prevCart.filter((item, o) => {
            return i !== o;
          })
        );
        } else {
          setRender3(true);
          dispatch(WLoginDeleteCartItemAction(id, deviceIdData));
          setProducts((prevCart) =>
          prevCart.filter((item, o) => {
            return i !== o;
          })
        );
        }
      }
    });
  };


  const [stateFor, setStateFor] = useState(false);

  const HandleUpdateCart = (e) => {
    e.preventDefault();
    const updatedProductsdata = products2.map((product) => ({
      competition_id: product.competition_id,
      id: product.id,
      quantity: product.quantity,
    }));
    const updateProductsArray = Array.isArray(updatedProductsdata)
      ? updatedProductsdata
      : JSON.parse(updatedProductsdata);
     if (updateProductsArray && updateProductsArray.length > 0) {
      const competitionQuantities = {};
      updateProductsArray.forEach((product) => {
        const competitionId = product.competion_id; 
        if (competitionQuantities.hasOwnProperty(competitionId)) {
          competitionQuantities[competitionId] += product.quantity;
        } else {
          competitionQuantities[competitionId] = product.quantity;
        }
      });
      const updatedProducts = updateProductsArray.map((product) => ({
        ...product,
        total: competitionQuantities[product.competion_id], 
      }));

      const final_data = {
        cart_items: JSON.stringify(updatedProducts),
      };
      setStateFor(false);
      if (userData) {
        setRender2(true);
        setnavigatestate(false)
        dispatch(UpdateCartListAction(final_data));
      } else {
        setRender4(true);
        setnavigatestate(false)
        dispatch(WLoginUpdateCartListAction(final_data, deviceIdData));
      }
    }
  };

  const handleClickData = (competition_id, quantity) => {
    localStorage.setItem("slidervalue", quantity);
    const array = [
      {
        id: competition_id,
        value: quantity,
      },
    ];
    localStorage.setItem("competitondetailid", JSON.stringify(array));
  };

  const checkoutclick = () => {
    const updatedProductsdata = products2.map((product) => ({
      competition_id: product.competition_id,
      id: product.id,
      quantity: product.quantity,
      max_tickets: product.max_tickets,
      ticketquantity: product.package_ticket_quantity,
      remaining_tickets: product.remaining_tickets,
    }));

    const productsByCompetition = updatedProductsdata.reduce((acc, product) => {
      if (!acc[product.competition_id]) {
        acc[product.competition_id] = [];
      }
      acc[product.competition_id].push(product);
      return acc;
    }, {});

    Object.values(productsByCompetition).forEach((products) => {
      const totalTickets = products.reduce((total, product) => {
        return total + product.quantity * product.ticketquantity;
      }, 0);

      const remainingTickets = Math.min(
        ...products.map((product) => product.remaining_tickets)
      );

      if (totalTickets > remainingTickets) {
        swal({
          title: "Error",
          text: `${remainingTickets} tickets are remaining`,
          className: "errorAlert-login",
          icon: "error",
          buttons: false,
          timer: 5000,
        });
      } else {
          const updatedProductsdata = products2.map((product) => ({
          competition_id: product.competition_id,
          id: product.id,
          quantity: product.quantity,
        }));
    
        const updateProductsArray = Array.isArray(updatedProductsdata)
          ? updatedProductsdata
          : JSON.parse(updatedProductsdata);
         if (updateProductsArray && updateProductsArray.length > 0) {
          const competitionQuantities = {};
          updateProductsArray.forEach((product) => {
            const competitionId = product.competion_id; 
            if (competitionQuantities.hasOwnProperty(competitionId)) {
              competitionQuantities[competitionId] += product.quantity;
            } else {
              competitionQuantities[competitionId] = product.quantity;
            }
          });

          const updatedProducts = updateProductsArray.map((product) => ({
            ...product,
            total: competitionQuantities[product.competion_id], 
          }));
    
          const final_data = {
            cart_items: JSON.stringify(updatedProducts),
          };
          setStateFor(false);
          if (userData) {
            setRender2(true);
            setnavigatestate(true)
            dispatch(UpdateCartListAction(final_data));
          } else {
            setRender4(true);
            setnavigatestate(true)
            dispatch(WLoginUpdateCartListAction(final_data, deviceIdData));
          }
        }
      }
    });
  };

  return (
    <div className="container main-cart-section-div">
      <div className="compitition_future text-center winner_title bucket">
        <h2 className="mb-0">Basket</h2>
      </div>
      <div className="row justify-content-center m-0">
        <div className="col-md-12 mt-2 mb-5">
          <div className="card secondCard newdiv41">
            <div className="card-body p-0">
              {products && products.length == 0 ? (
                <div className="table-responsive_new">
                  <table className="table cart-table mb-0">
                    <tbody>
                      <tr>
                        <td colSpan="6">
                          <div className="cart-empty">
                            <i className="fa fa-shopping-cart"></i>
                            <p>Your Cart Is empty</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="table-responsive mt-0">
                  <table className="table cart-table mb-0">
                    <thead>
                      <tr>
                        <th className="text-white">Action</th>
                        <th className="text-white">Product</th>
                        <th className="text-white">Price</th>
                        <th className="text-white">Qty</th>
                        <th className="text-right text-white">
                          <span id="amount" className="amount">
                            Total Amount
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {products &&
                        products.map((data, index) => {
                          const {
                            id,
                            competition_image,
                            competition_name,
                            quantity,
                            competition_id,
                            user_answer,
                            package_price,
                            package_ticket_quantity,
                          } = data;
                          return (
                            <tr key={index}>
                              <td>
                                <button
                                  className="prdct-delete"
                                  onClick={() => removeFromCart(index, id)}
                                >
                                  <i className="fa-solid fa-xmark"></i>
                                </button>
                              </td>
                              <td>
                                <div className="product_img_text">
                                  <div className="product-img">
                                    <img
                                      src={competition_image}
                                      alt="Image"
                                    />
                                  </div>
                                  <div className="product_text">
                                    <div
                                      className="product-name"
                                      onClick={() =>
                                        handleClickData(
                                          competition_id,
                                          quantity
                                        )
                                      }
                                    >
                                      <Link
                                        className="producttitle"
                                        to={
                                          "/competition-deatils/" +
                                          competition_id
                                        }
                                      >
                                        {competition_name}
                                      </Link>
                                      <h6 className="answersec">
                                        <strong>Answer: </strong>
                                        <p className="subaru mb-0">
                                          {" "}
                                          {user_answer}
                                        </p>
                                      </h6>

                                      <div className="flex gap-4">
                                        <h6 className="answersec">
                                          <strong>Entries: </strong>
                                          <p className="subaru mb-0">
                                            {" "}
                                            {Number(
                                              package_ticket_quantity
                                            ).toFixed(0)}{" "}
                                            {Number(
                                              package_ticket_quantity
                                            ).toFixed(0) <= 1
                                              ? "Entry"
                                              : "Entries"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                {" "}
                                {package_price > 1.0
                                  ? `£${package_price.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`
                                  : `${package_price
                                      ?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })
                                      .replace(/^0?\./, "")}p`}
                              </td>
                              <td>
                                <div className="prdct-qty-container">
                                {parseFloat(package_price) !== 0 && <>
                                  <button
                                    className="prdct-qty-btn"
                                    type="button"
                                    onClick={() => decreaseQuantity(index)}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                  <input
                                    type="text"
                                    name="qty"
                                    className="qty-input-box"
                                    value={products2?.[index]?.quantity}
                                    disabled
                                  />
                                  <button
                                    className="prdct-qty-btn"
                                    type="button"
                                    onClick={() => increaseQuantity(index)}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                  </> }
                                </div>
                              </td>
                              <td className="text-right">
                                {" "}
                                {(quantity * package_price).toFixed(2) > 1.0
                                  ? `£${(
                                      quantity * package_price
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}`
                                  : `${(quantity * package_price)
                                      .toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })
                                      .replace(/^0?\./, "")}p`}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}

              {products && products.length > 0 && (
                <div className="showtheDataTotal text-end">
                  <button
                    className="AddCompbutton2"
                    onClick={(e) => (stateFor ? HandleUpdateCart(e) : null)}
                  >
                    UPDATE CART
                  </button>
                </div>
              )}
              {products && products.length > 0 && (
                <>
                  <hr />
                  <div className="row m-3">
                    <div className="col-lg-8 col-md-6"></div>
                    <div className="col-lg-4 col-md-6 pe-0">
                      <div className="checkoutData">
                        <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-3">
                          <div className="ticket_main ">
                            <div className="first_ticket">
                              <p>Packages:</p>
                              <p className="text-end">
                                {totalItems?.total_tickets_in_cart}
                              </p>
                            </div>
                            <div className="seconf_ticket">
                              <p>Total Spend:</p>
                              <p className="text-end">
                                {totalItems?.total_package_amount_in_cart > 1.0
                                  ? `£${totalItems?.total_package_amount_in_cart.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`
                                  : `${totalItems?.total_package_amount_in_cart
                                      .toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })
                                      .replace(/^0?\./, "")}p`}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="my-3">
                          
                          <button
                            onClick={checkoutclick}
                            className="AddCompbutton2 btn-block"
                          >
                            Proceed to checkout
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
