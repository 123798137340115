import React, { useState, useEffect, useCallback, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDropzone } from "react-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  CreateCompetitionAction,
  GetCompetitionByIdAction,
  EditCompetitionAction,
  CompetitionAnsDeleteAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import LoadingSpinner from "../LoadingSpinner";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const AddCompetitions = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [compName, setCompName] = useState("");
  const [title, setTitle] = useState("");
  const [maxTicket, setMaxTiclet] = useState(1000);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [editRender, setEditRender] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isfeatured, setIsfeatured] = useState(true);
  const [prizeDescription, setPrizeDescription] = useState("");
  const [cashAlternative, setCashAlternative] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [addPackages, setAddPackages] = useState([
    {
      number_of_tickets: "",
      price: "",
      discounted_price: "",
    },
  ]);
  const [itemdataImages, setItemdataImages] = useState([]);

  const [question, setQuestion] = useState();
  const [addAnswer, setAddAnswer] = useState([
    {
      answer: "",
      status: "",
    },
  ]);

  const {
    delcompAns,
    error: delcompAnsError,
    success: delcompAnsSuccess,
  } = useSelector((state) => state.CompetitionAnsDeleteReducer);

  const {
    createComp,
    error: createCompError,
  } = useSelector((state) => state.CreateCompetitionReducer);

  const {
    getcomp,
    success: getcompSuccess,
  } = useSelector((state) => state.GetCompetitionByIdReducer);

  const {
    EditComp,
    error: EditCompError,
  } = useSelector((state) => state.EditCompetitionReducer);


  const [errors, setErrors] = useState({
    compNameError: null,
    titleError: null,
    startDateError: null,
    endDateError: null,
    maxticketError: null,
    priceError: null,
    prizeDescError: null,
    cashAltError: null,
    packagesError: null,
    fileError: null,
    questionError: null,
  });

  const [itemErrors, setItemErrors] = useState([
    {
      answerError: null,
      statusError: null,
    },
  ]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(GetCompetitionByIdAction(id));
    }
  }, [delcompAnsSuccess, id, dispatch]);

  useEffect(() => {
    if (delcompAns && render2) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      setRender2(false);
    }
    if (delcompAnsError && render2) {
      swal({
        title: "Error",
        text: delcompAnsError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setIsLoading(false);
      setRender2(false);
    }
  }, [dispatch, delcompAns, delcompAnsError]);

  useEffect(() => {
    if (id) {
      if (getcomp) {
        const inputDateStr = getcomp?.start_date;

        const inputDate = new Date(inputDateStr);

        if (!isNaN(inputDate)) {
          setStartDate(inputDate);
        }
        const inputDateStr2 = getcomp?.end_date;
        const inputDate2 = new Date(inputDateStr2);

        if (!isNaN(inputDate2)) {
          setEndDate(inputDate2);
        }

        setCompName(getcomp?.title);
        setTitle(getcomp?.subtitle);
        setMaxTiclet(getcomp?.max_tickets);
        setPrizeDescription(getcomp?.prize_description);
        setCashAlternative(getcomp?.cash_alternative);
        setIsChecked(getcomp?.is_active);
        setIsfeatured(getcomp?.is_featured);
        setAddPackages(getcomp?.competition_package);
        setAddAnswer(getcomp?.competition_answer);
        setQuestion(getcomp?.question);
        setFiles2(() => [
          ...getcomp?.competition_media.map((item) => {
            const file = new File([], item.title, { type: "image/png" }); // Assuming image type is PNG
            return Object.assign(file, {
              preview: item.image,
              title: item.id.toString(),
            });
          }),
        ]);
      }
    }
  }, [getcomp, id, getcompSuccess, delcompAnsSuccess]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#222a425e",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {};

  const thumbInner = {
    minWidth: 0,
    overflow: "hidden",
    Position: "relative",
  };


  const {
    getRootProps: getRootfileProps,
    getInputProps: getInputfileProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: useCallback(
      (acceptedFiles) => {
        if (files.length + acceptedFiles.length > 5) {
          setErrors({
            ...errors,
            fileError: "You can only upload up to 5 images.",
          });
          return;
        }
        setFiles([
          ...files,
          ...acceptedFiles?.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              title: file.name,
            })
          ),
        ]);
        setErrors({ ...errors, fileError: null });
      },
      [files]
    ),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, baseStyle, focusedStyle]
  );
  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const removeFile2 = (file, id) => () => {
    const newFiles = [...files2];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles2(newFiles);

    setItemdataImages((prevState) => [...prevState, id]);
  };

  const thumbs = files?.map((file) => {
    return (
      <div className="show_img_section" style={thumb} key={file.name}>
        <div style={thumbInner}>
          <div className="remveImageDivShow">
            <div className="edit_compitition_imggg">
              <img src={file?.preview} alt="image" />
            </div>
            <div onClick={removeFile(file)}>
              <span className="crossicon12">
                <i className="fa-solid fa-trash"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const thumbs2 = files2?.map((file) => {
    return (
      <div className="show_img_section" style={thumb} key={file.name}>
        <div style={thumbInner}>
          <div className="remveImageDivShow">
            <div className="edit_compitition_imggg">
              <img src={file?.preview} alt="image" />
            </div>
            <div onClick={removeFile2(file, file.title)}>
              <span className="crossicon12">
                <i className="fa-solid fa-trash"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (EditComp && editRender) {
      swal({
        title: "Successfully Complete",
        text: "Edited Competition",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        setIsLoading(true);
        navigate("/competition-list");
      }, 2000);
      setEditRender(false);
    }
    if (EditCompError && editRender) {
      swal({
        title: "Error",
        text: EditCompError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 1500,
      });
      setIsLoading(false);
      setEditRender(false);
    }
  }, [dispatch, EditComp, EditCompError, navigate]);

  useEffect(() => {
    if (createComp && render) {
      swal({
        title: "Successfully Complete",
        text: "Created Competition",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 3000,
      });
      setTimeout(() => {
        setIsLoading(true);
        navigate("/competition-list");
      }, 1500);
      setRender(false);
    }
    if (createCompError && render) {
      swal({
        title: "Error",
        text: createCompError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRender(false);
    }
  }, [dispatch, createComp, createCompError, navigate]);

  const validationSubmit = (e) => {
    e.preventDefault();
    const isDefaultDate = startDate.getTime() < endDate.getTime();
    const tempErrors = {
      compNameError: !compName && "Please enter Competiton name",
      endDateError:
        !isDefaultDate && "End date cannot be before the start date.",
      fileError: id ? false : files.length === 0 && "Please enter image",
      questionError: !question && "Please enter Question",
      prizeDescError: !prizeDescription && "Please enter prize description",
      cashAltError: !cashAlternative && "Please enter cash alternative",
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    Answervalidation(e);
  };

  const Answervalidation = (e) => {
    e.preventDefault();
    const tempErrors = [];

    for (let index = 0; index < addAnswer.length; index++) {
      tempErrors.push({
        answerError: !addAnswer[index].answer
          ? "This field is mandatory"
          : null,
        statusError:
          addAnswer[index]?.status !== false &&
            addAnswer[index]?.status !== true
            ? "This field is mandatory"
            : null,
      });
    }
    setItemErrors(tempErrors);

    for (let index = 0; index < tempErrors.length; index++) {
      let noError = Object.values(tempErrors[index]).every(
        (x) => x === null || x === ""
      );
      if (!noError) {
        return;
      }
    }
    submitHandler(e);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);


    const localDate = new Date(startDate);
    const utcStartDate = localDate.toISOString();
    const localDateEnd = new Date(endDate);

    // Get the UTC date and time in ISO format
    const utcEndDate = localDateEnd.toISOString();

    const formData = new FormData();
    formData.append("title", compName);
    formData.append("subtitle", title);
    formData.append("start_date", utcStartDate);
    formData.append("end_date", utcEndDate);
    formData.append("max_tickets", maxTicket);
    formData.append("prize_description", prizeDescription);
    formData.append("cash_alternative", cashAlternative);
    formData.append("is_active", isChecked);
    formData.append("is_featured", isfeatured);
    formData.append("competition_package", JSON.stringify(addPackages));
    formData.append("question", question);
    formData.append("competition_answer", JSON.stringify(addAnswer));
    if (id) {
      formData.append("delete_image", itemdataImages);
    }
    if (files.length > 0) {
      for (const key of Object.keys(files)) {
        formData.append("competition_media", files[key]);
      }
    }

    if (id) {
      dispatch(EditCompetitionAction(id, formData));
      setEditRender(true);
    } else {
      dispatch(CreateCompetitionAction(formData));
      setRender(true);
    }
  };

  const HandleTicketsAdd = () => {
    setAddPackages([
      ...addPackages,
      {
        number_of_tickets: "",
        price: "",
        discounted_price: "",
      },
    ]);
  };

  const HandleAnswerAdd = () => {
    setAddAnswer([
      ...addAnswer,
      {
        answer: "",
        status: "",
      },
    ]);
    setItemErrors([
      ...itemErrors,
      {
        answerError: null,
        statusError: null,
      },
    ]);
  };

  const handleRemove = (index) => {
    const updatedPackages = [...addPackages];
    updatedPackages.splice(index, 1);
    setAddPackages(updatedPackages);
  };

  const handleRemoveAns = (index, id) => {
    if (id) {
      setRender2(true);
      setIsLoading(true);
      dispatch(CompetitionAnsDeleteAction(id));
    } else {
      const updatedPackages = [...addAnswer];
      let errorList = [...itemErrors];

      if (index !== 0) {
        updatedPackages.splice(index, 1);
        errorList.slice(index, 1);
      }
      if (!updatedPackages.some((item) => item.status)) {
        updatedPackages[0].status = true;
      }
      setAddAnswer(updatedPackages);
      setItemErrors(errorList);
    }
  };

  const handleInputChange = (e, index, fieldName) => {
    const value = parseFloat(e.target.value); // Parse value as a number
    const updatedPackages = [...addPackages];
    updatedPackages[index][fieldName] = value;
    setAddPackages(updatedPackages);
  };

  const handleInputChangeAnswer = (e, index, fieldName, field_Error) => {
    const value = e.target.value;
    const updatedPackages = [...addAnswer];
    updatedPackages[index][fieldName] = value;
    setAddAnswer(updatedPackages);
    let errorList = itemErrors.map((item, i) => ({
      ...item,
      [field_Error]: i === index ? null : item[field_Error],
    }));
    setItemErrors(errorList);
  };

  const handleInputChangeAnswerSelect = (e, index, fieldName, field_Error) => {
    const value = e.target.value === "true"; // Convert string to boolean

    let updatedPackages = addAnswer.map((item, i) => ({
      ...item,
      [fieldName]: i === index ? value : false, // Set selected value to true, others to false
    }));

    const numberOfCorrect = updatedPackages.filter(
      (item) => item[fieldName]
    ).length;

    // If the selected value is true (Correct), set all others to false (Wrong)
    if (value) {
      updatedPackages = updatedPackages.map((item, i) => ({
        ...item,
        [fieldName]: i === index ? value : false,
      }));
    } else {
      // If all options are "Wrong", set the first option to true (Correct)
      if (numberOfCorrect === 0) {
        updatedPackages[0][fieldName] = true;
      }
    }
    setAddAnswer(updatedPackages);

    let errorList = itemErrors.map((item, i) => ({
      ...item,
      [field_Error]: i === index ? null : item[field_Error], // Clear error for selected item
    }));
    setItemErrors(errorList);
  };

  return (
    <>
      {isLoading ? <LoadingSpinner />
        :

        <div className="main-center">
          <div className="showthecontent">
            <h1 className="componentheading mb-0">
              {" "}
              {id ? "Edit" : "Add"} Competition
            </h1>
            <Link to="/competition-list">
              <button className="AddCompbutton">Back</button>
            </Link>
          </div>
          <div className="center-section">
            <form onSubmit={validationSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6 m-0 m-md-6 selected_field_form">
                <div className="relative compitition_name z-0 w-full mb-3 group">
                  <input
                    type="Text"
                    name="floating_email"
                    id="floating_email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                    placeholder=" "
                    value={compName}
                    onChange={(e) => {
                      setCompName(e.target.value);
                      setErrors({ ...errors, compNameError: null });
                    }}
                  />
                  <label
                    htmlFor="floating_email"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Competition Name
                  </label>

                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: errors.compNameError ? 1 : 0,
                    }}
                  >
                    {errors.compNameError ?? "valid"}
                  </span>
                </div>
                <div className="relative z-0 w-full mb-3 group sub_title1">
                  <input
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <label
                    htmlFor="floating_first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Sub Title
                  </label>
                </div>

                <div className="relative z-0 w-full mb-3 group max_ticket_price">
                  <input
                    type="number"
                    name="floating_company"
                    id="floating_company"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={maxTicket}
                    onChange={(e) => {
                      setMaxTiclet(parseInt(e.target.value, 10));
                      setErrors({ ...errors, maxticketError: null });
                    }}
                  />
                  <label
                    htmlFor="floating_company"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Max Tickets
                  </label>
                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: errors.maxticketError ? 1 : 0,
                    }}
                  >
                    {errors.maxticketError ?? "valid"}
                  </span>
                </div>
                <div className=" w-full main_image mb-3 group">
                  <label>Main Image</label>
                  <div className="container-upload">
                    <div
                      className="borderOfUploadFilenew"
                      {...getRootfileProps({ style })}
                    >
                      <input
                        {...getInputfileProps()}
                        imgExtension={[".jpg", ".gif", ".png", ".gif", ".mp4"]}
                      />
                      <span className="uploadFilenewArea">
                        <img
                          className="upiconimg"
                          src="/img/uploadimg.png"
                          alt=""
                        />
                        Image
                      </span>
                    </div>
                    <div className="showimageWihtDeletIcons">
                      <aside style={thumbsContainer}>{thumbs}</aside>
                      <aside style={thumbsContainer}>{thumbs2}</aside>
                    </div>
                  </div>
                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: errors.fileError ? 1 : 0,
                    }}
                  >
                    {errors.fileError ?? "valid"}
                  </span>
                </div>

                {/* -----------------------------------------mui date picker------------------------------------------------    */}

                <div className="relative z-0 w-full mb-3 group start_date1">
                  <div>
                    <label className="peer-focus:font-medium text-m text-gray-500 dark:text-gray-400 duration-300 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      Start Date
                    </label>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker
                          formatDate={(date) =>
                            date.format("yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'")
                          }
                          minDate={new Date()}
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e);
                          }}
                          ampm={false}
                          disablePast
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>

                <div className="relative z-0 w-full mb-3 group start_date1">
                  <div>
                    <label className="peer-focus:font-medium text-m text-gray-500 dark:text-gray-400 duration-300 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      End Date
                    </label>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker
                          formatDate={(date) =>
                            moment(new Date()).format("DD-MM-YYYY")
                          }
                          minDate={new Date()}
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e);
                            setErrors({ ...errors, endDateError: null });
                          }}
                          ampm={false}
                          disablePast
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: errors.endDateError ? 1 : 0,
                    }}
                  >
                    {errors.endDateError ?? "valid"}
                  </span>
                </div>

                <div className="relative z-0 w-full mb-3 group flex">
                  <div className="flex items-center status_section_comp">
                    <label
                      htmlFor="checked-checkbox"
                      className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300 status_section_comp_Label"
                    >
                      Status
                    </label>
                    <input
                      checked={isChecked} // Use the state to determine the checkbox status
                      onChange={(e) => setIsChecked(e.target.checked)}
                      id="checked-checkbox"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-6 check_box_shaddow_bg"
                    />
                  </div>
                  <div className="flex items-center isfeatured_section_comp">
                    <label
                      htmlFor="checked-checkbox_featured"
                      className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300 status_section_comp_Label"
                    >
                      Is Featured
                    </label>
                    <input
                      checked={isfeatured} // Use the state to determine the checkbox status
                      onChange={(e) => setIsfeatured(e.target.checked)}
                      id="checked-checkbox_featured"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-6 check_box_shaddow_bg"
                    />
                  </div>
                </div>

                <div className="relative z-0 w-full mb-3 group per_price_entry">
                  {addPackages?.map((items, index) => (
                    <>
                      <div className=" ticket_price_main">
                        <div className="relative ticket_price z-0 w-full mb-3 group">
                          <div className="showaddmoresection">
                            <div>
                              <p>{index + 1}</p>
                            </div>
                            <div>
                              <input
                                type="number"
                                name="floating_discounted_price"
                                id={`discounted_price_${index}`}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer addPackagediv"
                                placeholder=" "
                                autoComplete="off"
                                value={items?.discounted_price}
                                onChange={(e) =>
                                  handleInputChange(e, index, "discounted_price")
                                }
                              />
                              <label
                                htmlFor={`discounted_price_${index}`}
                                className="peer-focus:font-medium absolute set_ticket_priceee text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                              >
                                Set Tickets
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="relative ticket_price z-0 w-full mb-3 group">
                          <div className="showaddmoresection">
                            <div>
                              <input
                                type="number"
                                name="floating_company"
                                id={`number_of_tickets_${index}`}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer addPackagediv"
                                placeholder=" "
                                autoComplete="off"
                                value={items?.number_of_tickets}
                                onChange={(e) =>
                                  handleInputChange(e, index, "number_of_tickets")
                                }
                              />
                              <label
                                htmlFor={`number_of_tickets_${index}`}
                                className="peer-focus:font-medium set_ticket_priceee absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                              >
                                Ticket Offering
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="relative z-0 w-full mb-3 group">
                          <div className="showaddmoresection">
                            <div>
                              <input
                                type="number"
                                name="floating_company"
                                id={`price_${index}`}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer addPackagediv"
                                placeholder=" "
                                autoComplete="off"
                                value={items?.price}
                                onChange={(e) =>
                                  handleInputChange(e, index, "price")
                                }
                              />
                              <label
                                htmlFor={`price_${index}`}
                                className="peer-focus:font-medium set_ticket_priceee absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                              >
                                Tickets Price
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="relative z-0 w-full mb-3 group">
                          <div className="showaddmoresection">
                            <div className="removiconsAddCompetitions">
                              {index !== 0 && (
                                <i
                                  className="fa fa-close"
                                  onClick={(e) => handleRemove(index)}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="addAnswerSection">
                    <button
                      type="button"
                      className="addmorePricesbutton"
                      onClick={() => HandleTicketsAdd()}
                    >
                      Add Package
                    </button>
                  </div>
                </div>

                <div className="relative z-0 w-full mb-3 group">
                  <input
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={question}
                    onChange={(e) => {
                      setQuestion(e.target.value);
                      setErrors({ ...errors, questionError: null });
                    }}
                  />
                  <label
                    htmlFor="floating_first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Add Question
                  </label>
                  <div className="buttonandspantag">
                    <span
                      style={{
                        color: "#D14F4F",
                        textAlign: "end",
                        fontSize: "14px",
                        opacity: errors.questionError ? 1 : 0,
                      }}
                    >
                      {errors.questionError ?? "valid"}
                    </span>
                  </div>
                  {addAnswer?.map((items, index) => (
                    <>
                      <div className=" ticket_price_main">
                        <div className="relative ticket_price z-0 w-full mb-3 group">
                          <div className="showaddmoresection showaddmoresection2">
                            <div>
                              <p>{index + 1}</p>
                            </div>
                            <div>
                              <input
                                type="text"
                                name="floating_company"
                                id={`answer_${index}`}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer addAnswerdiv"
                                placeholder=" "
                                autoComplete="off"
                                value={items?.answer}
                                onChange={(e) =>
                                  handleInputChangeAnswer(
                                    e,
                                    index,
                                    "answer",
                                    "answerError"
                                  )
                                }
                              />
                              <label
                                htmlFor={`answer_${index}`}
                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                              >
                                Answer
                              </label>
                              <div className="errordiv">
                                <span
                                  style={{
                                    color: "#D14F4F",
                                    textAlign: "end",
                                    fontSize: "11px",
                                    opacity: itemErrors[index]?.answerError
                                      ? 1
                                      : 0,
                                  }}
                                >
                                  {itemErrors[index]?.answerError ?? "valid"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="relative z-0 w-full mb-3 group">
                          <div className="showaddmoresection showaddmoresection2">
                            <div>
                              <select
                                name="floating_company"
                                id={`status_${index}`}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer addAnswerdiv"
                                value={items.status}
                                onChange={(e) =>
                                  handleInputChangeAnswerSelect(
                                    e,
                                    index,
                                    "status",
                                    "statusError"
                                  )
                                }
                              >
                                <option value="" disabled selected hidden>
                                  select status
                                </option>
                                <option value="true">Correct</option>
                                <option value="false">Wrong</option>
                              </select>
                              <label
                                htmlFor={`status_${index}`}
                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                              >
                                status
                              </label>
                              <span
                                style={{
                                  color: "#D14F4F",
                                  textAlign: "end",
                                  fontSize: "11px",
                                  opacity: itemErrors[index]?.statusError ? 1 : 0,
                                }}
                              >
                                {itemErrors[index]?.statusError ?? "valid"}
                              </span>
                            </div>
                            {index !== 0 && (
                              <div className="removiconsAddCompetitions">
                                <i
                                  className="fa fa-close"
                                  onClick={(e) =>
                                    handleRemoveAns(index, items?.id)
                                  }
                                ></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="addAnswerSection">
                    <button
                      type="button"
                      className="addmorePricesbutton"
                      onClick={() => HandleAnswerAdd()}
                    >
                      Add More Ans
                    </button>
                  </div>
                </div>

                <div className="relative z-0 w-full mb-3 group">
                  <div>
                    <input
                      type="text"
                      name="cash_Alternative"
                      id={`cash_Alternative`}
                      className="mb-3 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer addPackagediv"
                      placeholder=" "
                      autoComplete="off"
                      value={cashAlternative}
                      onChange={(e) => {
                        setCashAlternative(e.target.value);
                        setErrors({ ...errors, cashAltError: null });
                      }}
                    />
                    <label
                      htmlFor="cash_Alternative"
                      className="peer-focus:font-medium set_ticket_priceee absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Cash Alternative
                    </label>
                    <div className="buttonandspantag">
                      <span
                        style={{
                          color: "#D14F4F",
                          textAlign: "end",
                          fontSize: "14px",
                          opacity: errors.cashAltError ? 1 : 0,
                        }}
                      >
                        {errors.cashAltError ?? "valid"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {" "}
                <label className="text-gray-500 dark:text-gray-400">
                  Prize Description
                </label>
                <CKEditor
                  config={{
                    toolbar: {
                      items: [
                        "undo",
                        "redo",
                        "|",
                        "heading",
                        "|",
                        "fontfamily",
                        "fontsize",
                        "fontColor",
                        "fontBackgroundColor",
                        "|",
                        "bold",
                        "italic",
                        "strikethrough",
                        "subscript",
                        "superscript",
                        "code",
                        "|",
                        "link",
                        "blockQuote",
                        "codeBlock",
                        "|",
                        "alignment",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "todoList",
                        "outdent",
                        "indent",
                      ],
                      shouldNotGroupWhenFull: true,
                    },
                  }}
                  data={prizeDescription ?? ""}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPrizeDescription(data);
                    setErrors({ ...errors, prizeDescError: null });
                  }}
                />
                <div className="buttonandspantag">
                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: errors.prizeDescError ? 1 : 0,
                    }}
                  >
                    {errors.prizeDescError ?? "valid"}
                  </span>
                </div>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="AddCompbutton2 mt-4">
                  {" "}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      }
    </>
  );
};

export default AddCompetitions;
