import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_API_URL, FRONTEND_URL, PAYMENT_URL } from "../../Environment";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

const PaymentComponent = () => {
  // const [userId, setUserId] = useState();
  // useEffect(() => {
  //   const userData = localStorage.getItem("userData");
  //   if (userData) {
  //     const parsedUserData = JSON.parse(userData);
  //     console.log("parsedUserData", parsedUserData);
  //     setUserId(parsedUserData?.user_id);
  //   }
  // }, []);

  // useEffect(() => {
  //   const hasReloaded = localStorage.getItem("hasReloadedd");
  //   if (!hasReloaded) {
  //     localStorage.setItem("hasReloadedd", "true");
  //     window.location.reload();
  //   }
  // }, []);

  const orderId = localStorage.getItem("order_Id");
  const shopperResultUrl = `${FRONTEND_URL}after-payment?orderId=${orderId}`;

  const [isLoadingg, setIsLoadingg] = useState(true);

  // useEffect(() => {
  //   const hasReloaded = localStorage.getItem("hasReloadedd");
  //   if (!hasReloaded) {
  //     localStorage.setItem("hasReloadedd", "true");
  //     window.location.reload();
  //   }
  // }, []);

  setTimeout(() => {
    setIsLoadingg(false);
  }, 4000);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderId = localStorage.getItem("order_Id");
        if (orderId) {
          const response = await axios.get(
            `${BACKEND_API_URL}get-check-id/${orderId}/`
          );
          try {
            if (response?.data?.check_id) {
              window.wpwlOptions = {
                applePay: {
                  displayName: "MyStore",
                  total: { label: "COMPANY, INC." },
                },
              };
              const script = document.createElement("script");
              script.src = `${PAYMENT_URL}/v1/paymentWidgets.js?checkoutId=${response?.data?.check_id}`;
              script.async = true;
              script.onerror = () => {
                console.error("Error loading COPYandPAY script");
              };

              document.body.appendChild(script);
              return () => {
                document.body.removeChild(script);
              };
            }
          } catch (error) {
            console.error("API Error:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [localStorage]);

  return (
    <>
      {isLoadingg && (
        <>
          <div className="loader_payment_gif">
            <img src={FRONTEND_URL + "images/kOnzy.gif"} />
          </div>
        </>
      )}
      <div className="">
        <Header />
        <div className="paymentform">
          <form
            action={shopperResultUrl}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX APPLEPAY"
          ></form>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default PaymentComponent;
