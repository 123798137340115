import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  HomePageContentSendAction,
  GetHomePageContentAction,
  UpdateHomePageContentAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";

const HomePages = () => {
  const dispatch = useDispatch();
  const [contentId, setContentId] = useState();
  const [header_title, setHeader_title] = useState("");
  const [link, setLink] = useState("");
  const [background_media, setBackground_media] = useState(null);
  const [mobile_media, setMobile_media] = useState(null);
  const [Show_background_media, setShow_Background_media] = useState(false);
  const [Show_mobile_media, setShow_Mobile_media] = useState(false);
  const [content_title, setContent_title] = useState("");
  const [homepage_content, setHomepage_content] = useState("");
  const [homepage_content_video, setHomepage_content_video] = useState(null);
  const [Show_homepage_content_video, setShow_Homepage_content_video] =
    useState(false);
  const [footer_image, setFooter_image] = useState(null);
  const [Show_footer_image, setShow_Footer_image] = useState(false);
  const [footer_content, setFooter_content] = useState();
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { GetHomeContentData } = useSelector(
    (state) => state.GetHomePageContentReducer
  );

  const { updateHomeContentData, error: updateHomeContentDataError } =
    useSelector((state) => state.UpdateHomePageContentReducer);

  const {
    HomeContentData,
    error: HomeContentDataError,
    success: HomeContentDataSuccess,
  } = useSelector((state) => state.HomePageContentSendReducer);

  useEffect(() => {
    if (GetHomeContentData) {
      const data = GetHomeContentData?.[0];
      setContentId(data?.id);
      setHeader_title(data?.header_title);
      setLink(data?.link);
      setBackground_media(data?.background_video);
      setMobile_media(data?.mobile_video);
      setContent_title(data?.content_title);
      setHomepage_content(data?.homepage_content);
      setHomepage_content_video(data?.homepage_content_video);
      setFooter_image(data?.footer_image);
      setFooter_content(data?.footer_content);
      setShow_Background_media(false);
      setShow_Mobile_media(false);
      setShow_Homepage_content_video(true);
      setShow_Footer_image(true);
    }
  }, [GetHomeContentData]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (updateHomeContentData && render2) {
      swal({
        title: "Successfully Complete",
        text: "Successfully Update",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 3000,
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      setRender2(false);
    }
    if (updateHomeContentDataError && render2) {
      swal({
        title: "Error",
        text: updateHomeContentDataError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRender2(false);
    }
  }, [dispatch, updateHomeContentData, updateHomeContentDataError]);

  const isVideo = (url) => {
    const videoExtensions = ['mp4', 'webm', 'ogg'];
    const extension = url.split('.').pop().split('?')[0]; // To handle query parameters
    return videoExtensions.includes(extension);
  };


  useEffect(() => {
    if (HomeContentData && render) {
      swal({
        title: "Successfully Complete",
        text: "Successfully submitted",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 3000,
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      setRender(false);
    }
    if (HomeContentDataError && render) {
      swal({
        title: "Error",
        text: HomeContentDataError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRender(false);
    }
  }, [dispatch, HomeContentData, HomeContentDataError]);

  useEffect(() => {
    dispatch(GetHomePageContentAction());
  }, [HomeContentDataSuccess]);

  const [error, setError] = useState({
    background_mediaError: null,
    mobile_mediaError: null,
    homepage_content_videoError: null,
    footer_imageError: null,
  });

  const validationSubmit = (e) => {
    e.preventDefault();
    const tempError = {
      background_mediaError:
        !background_media && "Background media is required",
    };
    setError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    HandleSubmit(e);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const fromData = new FormData();
    if (header_title) {
      fromData.append("header_title", header_title);
    }
    if (Show_background_media) {
      fromData.append("background_video", background_media);
    }
    if (Show_mobile_media) {
      fromData.append("mobile_video", mobile_media);
    }
    if (homepage_content) {
      fromData.append("homepage_content", homepage_content);
    }
    if (content_title) {
      fromData.append("content_title", content_title);
    }
    if (link) {
      fromData.append("link", link);
    }
    if (!Show_homepage_content_video) {
      fromData.append("homepage_content_video", homepage_content_video);
    }
    if (!Show_footer_image) {
      fromData.append("footer_image", footer_image);
    }
    if (footer_content) {
      fromData.append("footer_content", footer_content);
    }
    setIsLoading(true);
    if (contentId) {
      setRender2(true);
      dispatch(UpdateHomePageContentAction(contentId, fromData));
    } else {
      setRender(true);
      dispatch(HomePageContentSendAction(fromData));
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="main-center">
          <div className="showthecontent">
            <h1 className="componentheading">Home Pages</h1>
          </div>
          <div className="center-section">
            <form onSubmit={validationSubmit}>
              <div className="px-5 header_edit py-10">
                <h4 className="text-center mb-4">Header</h4>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="floating_email"
                    id="floating_email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={header_title}
                    onChange={(e) => {
                      setHeader_title(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="floating_email"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Title
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="floating_company"
                    id="floating_company"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="floating_company"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Link
                  </label>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="formFile"
                    className="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
                  >
                    Desktop Image/Video
                  </label>
                  <input
                    className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                    type="file"
                    id="formFile"
                    accept="video/mp4, video/webm, video/ogg, image/jpeg, image/png, image/gif"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];

                      if (selectedFile) {
                        const allowedTypes = [
                          "video/mp4",
                          "video/webm",
                          "video/ogg",
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        if (allowedTypes.includes(selectedFile.type)) {
                          setBackground_media(selectedFile);
                          setError({
                            ...error,
                            background_mediaError: null,
                          });
                          setShow_Background_media(true);
                        } else {
                          setError({
                            ...error,
                            background_mediaError:
                              "Please select a valid image/video file (mp4, webm, ogg, jpeg, png, gif).",
                          });
                        }
                      }
                    }}
                  />
                  <span
                    style={{
                      color: "#D14F4F",
                      display: "flex",
                      justifyContent: " end",
                      opacity: error?.background_mediaError ? 1 : 0,
                    }}
                  >
                    {error.background_mediaError ?? "valid"}
                  </span>
                </div>

                {background_media && (
                  <>
                    {Show_background_media && (
                      <>
                        {background_media.type?.startsWith("video") ? (
                          <video controls width="300">
                            <source
                              src={URL.createObjectURL(background_media)}
                              type={background_media.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={URL.createObjectURL(background_media)}
                            alt="Desktop Media"
                            style={{ maxWidth: "300px", maxHeight: "300px" }}
                          />
                        )}
                      </>
                    )}
{!Show_background_media && (
  <>
    {isVideo(background_media) ? (
      <video controls width="300">
        <source
          src={background_media}
          type="video/mp4" // Adjust the type if needed
        />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        src={background_media}
        alt="Desktop Media"
        style={{ maxWidth: "300px", maxHeight: "300px" }}
      />
    )}
  </>
)}
                  </>
                )}

                <div className="mb-3 mt-4">
                  <label
                    htmlFor="formFileMobile"
                    className="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
                  >
                    Mobile Image/Video
                  </label>
                  <input
                    className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                    type="file"
                    id="formFileMobile"
                    accept="video/mp4, video/webm, video/ogg, image/jpeg, image/png, image/gif"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];

                      if (selectedFile) {
                        const allowedTypes = [
                          "video/mp4",
                          "video/webm",
                          "video/ogg",
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        if (allowedTypes.includes(selectedFile.type)) {
                          setMobile_media(selectedFile);
                          setError({
                            ...error,
                            mobile_mediaError: null,
                          });
                          setShow_Mobile_media(true);
                        } else {
                          setError({
                            ...error,
                            mobile_mediaError:
                              "Please select a valid image/video file (mp4, webm, ogg, jpeg, png, gif).",
                          });
                        }
                      }
                    }}
                  />
                  <span
                    style={{
                      color: "#D14F4F",
                      display: "flex",
                      justifyContent: " end",
                      opacity: error?.mobile_mediaError ? 1 : 0,
                    }}
                  >
                    {error.mobile_mediaError ?? "valid"}
                  </span>
                </div>

                {mobile_media && (
                  <>
                    {Show_mobile_media && (
                      <>
                        {mobile_media.type?.startsWith("video") ? (
                          <video controls width="300">
                            <source
                              src={URL.createObjectURL(mobile_media)}
                              type={mobile_media.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={URL.createObjectURL(mobile_media)}
                            alt="Mobile Media"
                            style={{ maxWidth: "300px", maxHeight: "300px" }}
                          />
                        )}
                      </>
                    )}
                    {!Show_mobile_media && (
                      <>
                        {isVideo(mobile_media) ? (
                          <video controls width="300">
                            <source
                              src={mobile_media}
                              type="video/mp4" // You may need to dynamically determine the type
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={mobile_media}
                            alt="Mobile Media"
                            style={{ maxWidth: "300px", maxHeight: "300px" }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <hr />
              <div className="px-4 who_we_are_section py-10">
                <h4 className="text-center mb-4">Who We Are</h4>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="floating_email"
                    id="floating_email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={content_title}
                    onChange={(e) => {
                      setContent_title(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="floating_email"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Title
                  </label>
                </div>
                <div className="ckdeditorShow">
                  <label>Home Page Content</label>
                  <CKEditor
                    config={{
                      toolbar: {
                        items: [
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "|",
                          "fontfamily",
                          "fontsize",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "bold",
                          "italic",
                          "strikethrough",
                          "subscript",
                          "superscript",
                          "code",
                          "|",
                          "link",
                          "blockQuote",
                          "codeBlock",
                          "|",
                          "alignment",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "todoList",
                          "outdent",
                          "indent",
                        ],
                        shouldNotGroupWhenFull: true,
                      },
                    }}
                    data={homepage_content ?? ""}
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setHomepage_content(data);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="formFile"
                    className="mb-2 mt-2 inline-block text-neutral-700 dark:text-neutral-200"
                  >
                    Homepage Content Video
                  </label>
                  <input
                    className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                    type="file"
                    id="formFile"
                    accept="video/mp4, video/webm, video/ogg"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];

                      if (selectedFile) {
                        const allowedTypes = [
                          "video/mp4",
                          "video/webm",
                          "video/ogg",
                        ];
                        if (allowedTypes.includes(selectedFile.type)) {
                          setHomepage_content_video(selectedFile);
                          setError({
                            ...error,
                            homepage_content_videoError: null,
                          });
                          setShow_Homepage_content_video(false);
                        } else {
                          setError({
                            ...error,
                            homepage_content_videoError:
                              "Please select a valid video file (mp4, webm, ogg).",
                          });
                        }
                      }
                    }}
                  />
                  <span
                    style={{
                      color: "#D14F4F",
                      display: "flex",
                      justifyContent: " end",
                      opacity: error?.homepage_content_videoError ? 1 : 0,
                    }}
                  >
                    {error.homepage_content_videoError ?? "valid"}
                  </span>
                </div>

                {homepage_content_video && (
                  <>
                    {Show_homepage_content_video && (
                      <video controls width="300">
                        <source src={homepage_content_video} type="video/mp4" />
                        <source
                          src={homepage_content_video}
                          type="video/webm"
                        />
                        <source src={homepage_content_video} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    )}

                    {!Show_homepage_content_video && (
                      <video controls width="300">
                        <source
                          src={URL.createObjectURL(homepage_content_video)}
                          type="video/mp4"
                        />
                        <source
                          src={URL.createObjectURL(homepage_content_video)}
                          type="video/webm"
                        />
                        <source
                          src={URL.createObjectURL(homepage_content_video)}
                          type="video/ogg"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </>
                )}
              </div>
              <hr />
              <div className="px-4 footer_aria py-10">
                <h4 className="text-center mb-4">Footer</h4>

                <div className="ckdeditorShow">
                  <label>Footer Content </label>
                  <CKEditor
                    config={{
                      toolbar: {
                        items: [
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "|",
                          "fontfamily",
                          "fontsize",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "bold",
                          "italic",
                          "strikethrough",
                          "subscript",
                          "superscript",
                          "code",
                          "|",
                          "link",
                          "blockQuote",
                          "codeBlock",
                          "|",
                          "alignment",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "todoList",
                          "outdent",
                          "indent",
                        ],
                        shouldNotGroupWhenFull: true,
                      },
                    }}
                    data={footer_content ?? ""}
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFooter_content(data);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="formFile"
                    className="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
                  >
                    Footer Image
                  </label>
                  <input
                    className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                    type="file"
                    id="formFile"
                    accept="image/jpeg, image/png, image/gif"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];

                      if (selectedFile) {
                        const allowedTypes = [
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        if (allowedTypes.includes(selectedFile.type)) {
                          setFooter_image(selectedFile);
                          setError({
                            ...error,
                            footer_imageError: null,
                          });
                          setShow_Footer_image(false);
                        } else {
                          setError({
                            ...error,
                            footer_imageError:
                              "Please select a valid image file (jpeg, png, gif).",
                          });
                        }
                      }
                    }}
                  />
                  <span
                    style={{
                      color: "#D14F4F",
                      display: "flex",
                      justifyContent: " end",
                      opacity: error?.footer_imageError ? 1 : 0,
                    }}
                  >
                    {error.footer_imageError ?? "valid"}
                  </span>
                </div>

                {footer_image && (
                  <div className="footerimgesSection">
                    <div className="viewimagefootersection">
                      {Show_footer_image ? (
                        <img
                          src={footer_image}
                          alt="Footer Image"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(footer_image)}
                          alt="Footer Image"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="submitbuttonsection">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePages;
