import React from "react";
import "./LandingSpinner.css";

const index = () => {
  return (
    <>
      <div className="loaderSection">
        <div className="loaderSectionChild">
          {/* <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div> */}
          <img src="/images/moving-hands-loading-dknfn3tdw6bzwql7.gif"/>
        </div>
      </div>
    </>
  );
};

export default index;
