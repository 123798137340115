import {
  SUPPORT_SEND_SUCCESS,
  SUPPORT_SEND_REQUEST,
  SUPPORT_SEND_FAILURE,
  GET_HOME_cOMPETION_ISFEATURED_SUCCESS,
  GET_HOME_cOMPETION_ISFEATURED_FAILURE,
  GET_HOME_cOMPETION_ISFEATURED_REQUEST,
  GET_COMPETITION_DETAILS_SUCCESS,
  GET_COMPETITION_DETAILS_REQUEST,
  GET_COMPETITION_DETAILS_FAILURE,
  UPDATE_PROFILE_USER_SUCCESS,
  UPDATE_PROFILE_USER_REQUEST,
  UPDATE_PROFILE_USER_FAILURE,
} from "../Constants/PublicConstants";
import axios from "axios";
import { BACKEND_API_URL } from "../../Environment";

export const SupportSendAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUPPORT_SEND_REQUEST,
    });
    const { data } = await axios.post(
      `${BACKEND_API_URL}contact-support/`,
      params
    );

    dispatch({
      type: SUPPORT_SEND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPORT_SEND_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const GetcHomeIsFeaturedAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_HOME_cOMPETION_ISFEATURED_REQUEST,
    });
    const { data } = await axios.get(
      `${BACKEND_API_URL}featured-competitions/`
    );

    dispatch({
      type: GET_HOME_cOMPETION_ISFEATURED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOME_cOMPETION_ISFEATURED_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};


export const GetCompetitionCompAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPETITION_DETAILS_REQUEST,
    });

    const { data } = await axios.get(
      `${BACKEND_API_URL}competitions/?competition=${id}`,
    );

    dispatch({
      type: GET_COMPETITION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPETITION_DETAILS_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};


export const UpdateProfileAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PROFILE_USER_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.put(
        `${BACKEND_API_URL}user-profile/`,
        params,
        config
      );

      dispatch({
        type: UPDATE_PROFILE_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_USER_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };