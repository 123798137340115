import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./Components/Layout/Dashboard";
import Competitions from "./Components/Layout/Competitions";
import AppLayout from "./Components/Layout/AppLayout";
import AdminLayout from "./Components/Layout/AdminLayout";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import TermsToPlay from "./Components/Pages/TermsToPlay";
import Blog from "./Components/Pages/Blog";
import Blogs from "./Components/Pages/Blogs";
import CookiePolicy from "./Components/Pages/CookiePolicy";
import Support from "./Components/Pages/Support";
import Cart from "./Components/Pages/Cart";
import UserProfile from "./Components/Pages/UserProfile";
import HowToPlay from "./Components/Pages/HowToPlay";
import CompetitionDeatilsPages from "./Components/Pages/CompetitionDeatilsPages";
import WinnersPage from "./Components/Pages/WinnersPage";
import PlaceOrder from "./Components/Pages/PlaceOrder";
import PaymentComponent from "./Components/Pages/PaymentComponent";
import AfterPayment from "./Components/Pages/AfterPayment";
import WithoutpayPayment from "./Components/Pages/Withoutpaytickets";

// ---------------------------Authentication-------------------------------------
import Login from "./Components/Authentication/Login";
import Registration from "./Components/Authentication/Registration";
import ForgotPassword from "./Components/Authentication/ForgotPassword";
import Thankyou from "./Components/Authentication/Thankyou";
import VerifyEmail from "./Components/Authentication/Verify-email";
import ResetPassword from "./Components/Authentication/ResetPassword";

// --------------------------------Admin-------------------------------------------------------
import AddCompetitions from "./Components/AdminPages/AddCompetitions";
import TestFile from "./Components/AdminPages/TestFile";
import CompetitionList from "./Components/AdminPages/CompetitionList";
import EditBeforeAfterImages from "./Components/AdminPages/EditBeforeAfterImages";
import SlideComp from "./Components/AdminPages/SlideComp";
import SlideList from "./Components/AdminPages/SlideList";
import HomePages from "./Components/AdminPages/HomePages";
import AddWinners from "./Components/AdminPages/AddWinners";
import WinnersList from "./Components/AdminPages/WinnersList";
import Userlist from "./Components/AdminPages/Userlist";
import Promolist from "./Components/AdminPages/Promolist";
import PastCompetition from "./Components/AdminPages/PastCompetition";

import ProtectedRoute from "./Routing/ProtectedRoute";
import AdminRoute from "./Routing/AdminRoute";

import CookieConsent from "react-cookie-consent";
import { useCookies } from 'react-cookie';
import {  useSelector } from "react-redux";

const App = () => {

  const {
    error: userDataError,
    userData,
  } = useSelector((state) => state.authReducer);


  const [cookies] = useCookies(['myAwesomeCookieName2']);
  const myAwesomeCookieName2 = cookies['myAwesomeCookieName2'];
  localStorage.setItem('myAwesomeCookieName2', myAwesomeCookieName2);
const [localData , setLocalData]= useState()
  const handleMoreInformationClick = () => {
    window.location.href = "/cookie-policy"; // Redirect to the root page
  };
useEffect(()=>{
  if(userData){
    setLocalData({...userData,myAwesomeCookieName2:true})
  }
},[userData])
  return (
    <>
      {localData?.myAwesomeCookieName2 !== true ?
        <CookieConsent
          location="bottom"
          buttonText="I ACCEPT"
          declineButtonText="I DECLINE"
          declineButtonStyle={{
            padding: "12px 32px",
            "background-color": "#d5d7da",
            outline: "none",
            border: "none",
            color: "#000",
            "font-size": "14px",
            "font-weight": "500",
          }}
          cookieName="myAwesomeCookieName2"
          buttonStyle={{
            background: "transparent",
            color: "#ffffff",
            transition: "1s",
            padding: "8px 26px",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Poppins",
            gap: "14px",
            boxShadow: " 0 0 20px 0 #d0a965, 0 0 20px 0 #d0a965 inset",
            border: " 3px solid #fff",
            borderImage: "linear-gradient(45deg, #ffffff, #d0a965) 10",
            borderImageSlice: "1",
          }}
          expires={150}
          enableDeclineButton
        >
          This website uses cookies to enhance the user experience.
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={handleMoreInformationClick}
          >
            Click here for more information.
          </span>
        </CookieConsent>
        : null}
      <Router>
        <Routes>
          <Route element={<AppLayout />}>
            <Route
              path="/"
              element={
                <Dashboard />
              }
            />
            <Route
              path="/competitons"
              element={
                <Competitions />
              }
            />
            <Route
              path="/my-profile"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />

            <Route
              path="after-payment"
              element={
                <AfterPayment />
              }
            />
             <Route
              path="/order-successfully"
              element={
                  <WithoutpayPayment />
              }
            />
             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-to-play" element={<TermsToPlay />} />
            <Route path="/blog/:slug" element={<Blog />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/how-to-play" element={<HowToPlay />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/support" element={<Support />} />
            <Route path="/basket" element={<Cart />} />
            <Route path="/checkout" element={<PlaceOrder />} />
            <Route
              path="/competition-deatils/:comdetailsid"
              element={<CompetitionDeatilsPages />}
            />
            <Route path="/winners" element={<WinnersPage />} />
          </Route>

          {/* --------------------------------------Admin Layout-----------------------------------------------    */}
          <Route element={<AdminLayout />}>
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <TestFile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-edit-competitons"
              element={
                <AdminRoute>
                  <AddCompetitions />
                </AdminRoute>
              }
            />
            <Route
              path="/add-edit-competitons/:id"
              element={
                <AdminRoute>
                  <AddCompetitions />
                </AdminRoute>
              }
            />

            <Route
              path="/past-competition"
              element={
                <AdminRoute>
                  <PastCompetition />
                </AdminRoute>
              }
            />

            <Route
              path="/competition-list"
              element={
                <AdminRoute>
                  <CompetitionList />
                </AdminRoute>
              }
            />
            <Route
              path="/edit-after-before-images/:id"
              element={
                <AdminRoute>
                  <EditBeforeAfterImages />
                </AdminRoute>
              }
            />
            
            <Route
              path="/slider"
              element={
                <AdminRoute>
                  <SlideList />
                </AdminRoute>
              }
            />
            <Route
              path="/add-edit-slide"
              element={
                <AdminRoute>
                  <SlideComp />
                </AdminRoute>
              }
            />
            <Route
              path="/add-edit-slide/:sliderId"
              element={
                <AdminRoute>
                  <SlideComp />
                </AdminRoute>
              }
            />
            <Route
              path="/home-page"
              element={
                <AdminRoute>
                  <HomePages />
                </AdminRoute>
              }
            />
            <Route
              path="/winner-list"
              element={
                <AdminRoute>
                  <WinnersList />
                </AdminRoute>
              }
            />
            <Route
              path="/user-list"
              element={
                <AdminRoute>
                  <Userlist />
                </AdminRoute>
              }
            />
              <Route
              path="/promo-code"
              element={
                <AdminRoute>
                  <Promolist />
                </AdminRoute>
              }
            />
            <Route
              path="/winner-add-edit"
              element={
                <AdminRoute>
                  <AddWinners />
                </AdminRoute>
              }
            />
            <Route
              path="/winner-add-edit/:IdW"
              element={
                <AdminRoute>
                  <AddWinners />
                </AdminRoute>
              }
            />
          </Route>

          {/* --------------------------------------Admin Layout-----------------------------------------------    */}

          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/verify-email/:token/:uid/" element={<VerifyEmail />} />
          <Route
            path="/reset-password/:ResetpasswordId/:userId/"
            element={<ResetPassword />}
          />
          <Route
            path="/payment"
            element={
              <ProtectedRoute>
                <PaymentComponent />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};
export default App;
