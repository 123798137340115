import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  GetWinnersAction,
  DeleteWinnersAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert";

const WinnersList = () => {
  const dispatch = useDispatch();
  const [usersForRender, setUsersForRender] = useState([]);
  const [render2, setRender2] = useState(false);

  const {
    GetWinner,
    success: GetWinnertSuccess,
  } = useSelector((state) => state.GetWinnersReducer);

  const {
    deleteWinner,
    error: deleteWinnerError,
    success: deleteWinnerSuccess,
  } = useSelector((state) => state.DeleteWinnersActionReducer);

  useEffect(() => {
    dispatch(GetWinnersAction());
  }, [deleteWinnerSuccess,dispatch]);

  useEffect(() => {
    let userData = [];

    if (GetWinner) {
      GetWinner?.map((item, index) => {
        item.title = item.title;
        item.winning_image = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <button
                className="iconbtn newbtn44"
              >
                <p className="editiconDelete1 m-2">
                  <img
                    className="displaythesliderimages"
                    src={item?.winning_image}
                    alt="image"
                  />
                </p>
              </button>
            </div>
          </div>
        );

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <Link to={`/winner-add-edit/${item.id}`}>
                <button
                  title="edit"
                  className="iconbtn newbtn44"
                >
                  <p className="editiconDelete1 m-2">
                    {" "}
                    <i className="fas fa-pencil-alt"></i>
                  </p>
                </button>
              </Link>
              <button title="delete" className=" newbtn44 iconbtn">
                <p
                  className="editiconDelete1 m-2"
                  onClick={() => deleteHandler(item.id)}
                >
                  <i className="fa fa-trash"></i>
                </p>
              </button>
            </div>
          </div>
        );
        userData.push(item);
      });
    }

    setUsersForRender(userData);
  }, [GetWinnertSuccess]);

  const data1 = {
    columns: [
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 500,
      },
      {
        label: "Winner Image",
        field: "winning_image",
        sort: "asc",
        width: 500,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: usersForRender,
  };

  useEffect(() => {
    if (deleteWinner && render2) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
      }, 1000);
      setRender2(false);
    }
    if (deleteWinnerError && render2) {
      swal({
        title: "Error",
        text: deleteWinnerError,
        className: "errorAlert-login",
        buttons: false,
        timer: 1500,
      });
      setRender2(false);
    }
  }, [dispatch, deleteWinner, deleteWinnerError]);

  const deleteHandler = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setRender2(true);
        dispatch(DeleteWinnersAction(id));
      }
    });
  };

  return (
    <>
      <div className="main-center">
        <div className="showthecontent">
          <h1 className="componentheading">Winners List</h1>
          <Link to="/winner-add-edit">
            <button className="AddCompbutton">Add Winner</button>
          </Link>
        </div>
        <div className="center-section">
          <div>
            <MDBDataTable
              style={{}}
              responsive
              striped
              bordered
              small
              data={data1}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WinnersList;
