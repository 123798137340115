import React, { useEffect } from "react";
import { FRONTEND_URL } from "../../Environment";

const TermsToPlay = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const savedUserData = localStorage.getItem("Redirect_id");
    if (savedUserData) {
      const element = document.getElementById("eightPointThree");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      setTimeout(() => {
        localStorage.removeItem("Redirect_id");
      }, 5000);
    }
  }, []);

  
  return (
    <div className="top term_to_play">
      <div className="container mx-auto md:px-[50px] sm:py-[12px] ">
        <div className="headingPolicy_first">
          <h3 className="policyHeading1 text-[#cead51]">LUX499 Ltd</h3>

          <h3 className="policyHeading1 mb-8">
            Draw Entry Terms and Conditions
          </h3>
        </div>
        <ol className="terms_list">
          <li>
            <h2 className="title-5 M-title text88P">
              THE PROMOTOR AND WHO WE ARE
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                We are LUX499 Ltd (trading as and collectively referred to as
                (“LUX499 Competitions”, “we”, “us”, “our”), a company
                incorporated in England and Wales with company number 15157904.
                Our registered office is at 124 City Road, London, United
                Kingdom, EC1V 2NX. We are the “Promotor” of the prize draw
                (“Draw”) operated at{" "}
                <a href={FRONTEND_URL} target="_blank" rel="noreferrer">
                  {" "}
                  LUX499{" "}
                </a>
                (“the “Website”) which means that we are responsible for making
                sure it runs properly and fairly.
              </p>
              <p className="paragraphnormal text-7 mt-4">
                When you participate in any Draw (“Participant”, “you”, “your”),
                you will be subject to these Terms and our Privacy Policy which
                shall be incorporated herein. Our Privacy Policy can be found
                here{" "}
                <a href={`${FRONTEND_URL}privacy-policy`} target="_blank" rel="noreferrer">
                  {" "}
                  {FRONTEND_URL}privacy-policy
                </a>
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">
              THESE TERMS AND CONDITIONS
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                2.1. These terms and conditions (the “Terms”) let you know how
                we operate each Draw and the rules of entry.
              </p>

              <p className="paragraphnormal text-7" style={{ display: "flex" }}>
                <span> 2.2.</span> You should always read these Terms to make
                sure you understand them before you enter into any Draw.
              </p>

              <p className="paragraphnormal text-7" style={{ display: "flex" }}>
                <span>2.3.</span>
                We may change these Terms from time to time so you should check
                this page each time you enter into a Draw. We will let you know
                on our Website if we have updated our Terms and any changes will
                apply from the date that they are published on our Website.
              </p>
              <p className="paragraphnormal text-7">
                2.4. By entering into any Draw, you accept that you understand
                these Terms and our Privacy Policy and agree to be legally bound
                by them.
              </p>
              <p className="paragraphnormal text-7">
                2.5. Should you have any queries, concerns or complaints about a
                Draw then please contact us at{" "}
                <a href= {`${FRONTEND_URL}support`} target="_blank" rel="noreferrer">
                  support@lux499.com
                </a>
              </p>
              <p className="paragraphnormal text-7">
                If you have any difficulty accessing or entering this promotion,
                please contact us at
                <a href={`${FRONTEND_URL}support`} target="_blank" rel="noreferrer">
                  support@lux499.com
                </a>
                .If you would like these terms and conditions in another format
                (for example: audio, large print, braille) please contact us and
                we will endeavour to provide it.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">ENTRY RULES</h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                {" "}
                3.1. Each Draw is open to all persons aged 18 years and over who
                are resident in England, Wales, and Scotland.
              </p>
              <p className="paragraphnormal text-7">
                3.2. By entering into a draw you confirm that:
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.2.1.you are at least 18 years of age;
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.2.2. you have legal capacity to enter into the Draw;
              </p>
              <p
                className="paragraphnormal text-7 ml-4"
                style={{ display: "flex" }}
              >
                <span>3.2.3.</span> You are complying with all legal
                requirements in your country of residence with regard to
                entering this prize competition and prize competitions generally
                and are lawfully able to enter the Draw (and we advise that you
                seek legal advice and/or check with the relevant authorities in
                this regard);
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.2.4.You accept these Terms and other Draw requirements as
                detailed on our website.
              </p>
              <p className="paragraphnormal text-7">
                3.3. The following persons are not eligible to enter:
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.3.1. our employees or workers, or the employees or workers of
                any company in our group;
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.3.2. employees or workers of any organisation involved in the
                operation or administration of the Draw including prize
                suppliers and advertising agencies; and
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.3.3. members of their immediate families.
              </p>
              <p className="paragraphnormal text-7">
                3.4. Entries will be void if they:
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.4.1.do not comply with these Terms;
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.4.2.are incomplete or illegible;
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.4.3.are postal entries that are sent with the incorrect
                postage or to the incorrect address;
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.4.4.are received after the closing date and time of each Draw;
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.4.5.are considered by the Promoter to be part of an attempt to
                manipulate or unfairly influence the outcome of the Draw.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                3.5. We may ask for proof of age, residence or eligibility.
                Delay or failure to provide the evidence to our reasonable
                satisfaction may result in an entry being void or a prize being
                forfeited.
              </p>
              <p className="paragraphnormal text-7">
                3.6. Our decision as to whether an entrant (or their entry) is
                eligible for the Draw is final and we are not obliged to provide
                any reasons for disqualification.
              </p>
            </li>
          </ol>
          {/* --------------------------------4 point------------------------------------ */}

          <li>
            <h2 className="title-5 M-title text88P">HOW TO ENTER</h2>
          </li>
          <p>You can enter this promotion in any of the following ways:</p>
          <ol className="marginu77">
            <li>
              <table className="table_term_of_play">
                <tbody>
                  <tr>
                    <th style={{ borderRight: "2px solid #000" }}>Online</th>
                    <td>
                      <p>
                        Complete the entry steps online on our website at LUX499
                        The cost of entry shall be displayed on our Website.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>Post</th>
                    <td>
                      <p>
                        You can enter by post, but you will first have to
                        register an account with us (see clause 4.1 below).
                      </p>
                      <p id="eightPointThree">
                        Please send a postcard your with your name, LUX499
                        Competitions Ltd account number, postal address, date of
                        birth, email address and telephone number and the name
                        of the competition you are entering to 124 City Road,
                        London, United Kingdom, EC1V 2NX.
                      </p>
                      <p>
                        Valid free entries will be found within the history of
                        your account.
                      </p>
                      <p>One entry per postcard to each Draw</p>
                      <p>
                        Postal entries must be received by the closing date and
                        time shown on each Draw in order for it to be processed
                        before the Draw. Postal entries received after the
                        closing date and time will not be entered into the Draw.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="paragraphnormal text-7">
                4.1. In order to enter a Draw, you will need to create an
                account with us via our Website. Please follow the on-screen
                instructions. You must provide us with your name and contact
                details which must include your email and postal address. It is
                really important that these details are correct, accurate and up
                to date so that we can contact you about the Draw if we need to.
                We cannot be responsible or liable to you this regard if you
                have provided us with inaccurate information.
              </p>
              <p className="paragraphnormal text-7">
                4.2. You will create a user name and password for your account.
                It is your responsibility to keep these details safe and secure
                and to not choose a password which can be easily guessed, and we
                are neither responsible nor liable to you in this regard. If you
                believe that somebody else is using your account please contact
                us.
              </p>
              <p className="paragraphnormal text-7">
                4.3. Once you have paid for each entry you will then receive an
                email to confirm your entry into the Draw together with your
                Draw numbers.
              </p>
              <p className="paragraphnormal text-7">
                4.4. If you are entering by Post, we will allocate a randomly
                selected available Draw number to you.
              </p>
              <p className="paragraphnormal text-7">
                4.5. Please note that when entering the Draw either Online
                and/or by Post you will not have been deemed to have entered the
                Draw until we have confirmed your entry into the Draw by
                emailing you and by confirmation to your account by logging into
                your account and checking under the “My Account” section. You
                will then be asked to enter your date of birth to confirm that
                you are over 18 years of age and that you have read and accepted
                these Terms and our Privacy Policy
              </p>
              <p className="paragraphnormal text-7">
                4.6. We reserve the right to refuse or disqualify your entry if
                we have reasonable grounds to believe that you have acted in
                breach of these Terms, and you shall be liable for the return
                and/or reimbursement of all and any prizes (as defined below) to
                us.
              </p>
              <p className="paragraphnormal text-7">
                4.7. We reserve the right to reject entries that are unlawful,
                indecent, racist, inflammatory, defamatory or which we consider
                to be otherwise harmful. We also have the right to suspend or
                cancel your account.
              </p>
              <p className="paragraphnormal text-7">
                4.8. We accept no responsibility for any late, lost or
                misdirected entries including but not limited to entries not
                received due to technical disruptions, network congestion, loss
                in service of online entry mechanisms, computer error in
                transit, delay in postal services or any other reason
              </p>
              <p className="paragraphnormal text-7">
                .4.9. There will only be one Draw operating at any one time for
                each competition.
              </p>
              <p className="paragraphnormal text-7">
                4.10. The Draw will close when the closing date with the count
                down clock has ended.
              </p>
              <p className="paragraphnormal text-7">
                4.11. The Prize for each Draw (“Prize”) will be displayed on our
                Website.
              </p>
              <p className="paragraphnormal text-7">
                4.12. There will only be one winner chosen for each Draw.
              </p>
              <p className="paragraphnormal text-7">
                4.13. You must create your online account and only enter the
                Draw on your own behalf. You are not allowed to enter a Draw on
                behalf of anyone else.
              </p>
              <p className="paragraphnormal text-7">
                4.14. You can enter a maximum number of times as displayed in
                the description section under the relevant competition.
              </p>
              <p className="paragraphnormal text-7">
                4.15. Where the Prize amounts to circumstances where we agree to
                pay for a Winner’s cosmetic procedure the additional rules of
                eligibility will apply:
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.1. The Winner shall be responsible for arranging and
                booking the cosmetic procedure at their chosen location within
                the United Kingdom and it shall be their responsibility to make
                the appropriate enquiries as to the qualification and
                suitability of their chosen provider of the cosmetic procedure
                and we will not carry out these checks on your behalf.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.2. We make no representations, nor do we provide any
                warranties about the Prize, its value, or in relation to any
                other information provided on our Website regarding the Prize.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.3. We will pay for the cost of the Winner’s cosmetic
                procedure only up to the value as expressly described in the
                description section under the relevant competition that you are
                entering in relation to that Prize. This payment will be
                transferred to the Winner’s bank account within 7 working days
                of you booking the cosmetic procedure upon us receiving written
                confirmation from your chosen provider confirming that the
                cosmetic procedure has been booked. If a Winner decides to
                cancel their cosmetic procedure after we have transferred this
                payment to their bank account the Winner may keep this cash
                payment as a cash prize alternative instead of using this cash
                payment to pay for the cost of the Winner’s cosmetic procedure.
                The Winner is responsible for any cancellation fees payable to
                their chosen location within the United Kingdom for the
                cancellation of the procedure.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.4. The Winner is responsible for arranging (and bearing the
                costs of) any element of the cosmetic procedure that is not
                expressly described in the description section under the
                relevant competition that you are entering including (but not
                limited to):
              </p>
              <p className="paragraphnormal text-7 ml-6">
                4.15.4.1. transfers and travel;
              </p>
              <p className="paragraphnormal text-7 ml-6">
                4.15.4.2. accommodation;
              </p>
              <p className="paragraphnormal text-7 ml-6">
                4.15.4.3. insurance and other travel documentation;
              </p>

              <p className="paragraphnormal text-7 ml-6">
                4.15.4.4. car hire; and
              </p>
              <p className="paragraphnormal text-7 ml-6">
                4.15.4.5. food and drink.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.5. The Winner must ensure that they are not subject to any
                restriction which may impact their ability to travel.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.6. If you have any accessibility requirements, please
                inform us as soon as possible.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.7. The Prize will be subject to additional terms and
                conditions imposed by your chosen provider. The Winner must
                review and comply with these terms and conditions, and we shall
                have no responsibility or liability to you in this regard.
              </p>
              <p className="paragraphnormal text-7 ml-4">
                4.15.8. If necessary due to circumstances beyond its control, we
                may (at our option) substitute the prize for:
              </p>
              <p className="paragraphnormal text-7 ml-6">
                4.15.8.1. a reasonable equivalent of equal or higher value; or
              </p>
              <p className="paragraphnormal text-7 ml-6">
                4.15.8.2. a cash alternative of the value of the Prize.
              </p>

              <p className="paragraphnormal text-7">
                4.15.9. The prize is for the named Winner only and cannot be
                given or transferred to any other person.
              </p>
              <p className="paragraphnormal text-7">
                4.15.10. Winners must attend initial consultations with their
                chosen provider of the cosmetic procedure within 6 months of the
                Winner being drawn, and treatments must be redeemed within 12
                months of the Winner being drawn. Failure to adhere to these
                timeframes will result in the disqualification of the potential
                Winner for breaching these Terms and the Winner will forfeit the
                prize and the Prize will be re-drawn from the remaining entries
                in accordance with clause 5.2 and 5.3.
              </p>
            </li>

            {/* --------------------------------4 point------------------------------------ */}
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">THE DRAW</h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                5.1. Draw numbers will be entered into using Google’s random
                 number generator or such other random number generator used 
                 from time to time. The Draw Number randomly selected will be
                  deemed the winner of the Draw (“Winner”), as long as they
                   answer the question attached to the competition correctly.
                    If incorrect, we will re-draw numbers using Google's
                     random number generator until we have a qualified winner.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.2. There will be one Winner per Draw unless otherwise stated
                on our Website.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.3. The Draw will be performed and streamed via Facebook and
                Instagram live on our page “LUX499 Competitions” and/or such
                other social media platform that we decide.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.4. The Winner will be notified as soon as possible. We shall
                initially attempt to contact the Winner using the email address
                and contact details you provided at the time of creating your
                account. It is your responsibility to make sure that the details
                provided to us are correct and up to date. It is also your
                responsibility to make sure that an email from us has not gone
                into your spam or junk email folders. We will not be responsible
                or liable if you have provided inaccurate details or if you have
                failed to contact us in response to one of our emails within 5
                days from the date of our email to you.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.5. If we are unable to contact a Winner within 5 days (which
                we may extend at out absolute and sole discretion) from the Draw
                taking place, or the Winner fails to respond to us or the Winner
                has breached these Terms, the Winner will forfeit the Prize and
                the Draw will be drawn again from the remaining entries in
                accordance with clause 5.2 and 5.3 above.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.6. The Winner shall provide us with two valid forms of
                identification (one of which must be photo identification) prior
                to receiving any Prize. Failure to provide identification which
                is acceptable to us shall mean that the Winner forfeits his
                entry and Prize and the Draw will be redrawn in accordance with
                clause 5.1 and 5.2 above.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.7. Following our successful verification of the Winner (and we
                reserve the right to verify the Winner in our sole and absolute
                discretion) we will contact you to arrange the free delivery of
                your Prize to the address in Great Britain as stated on your
                account.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.8. We shall endeavour to transfer cash Prizes to the Winner
                within 30 days from the date of the Draw.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.9. In all other cases, we will provide the Winner with
                instructions on how to book or obtain their Prize.
              </p>
            </li>

            <li>
              <p className="paragraphnormal text-7">
                5.10. Some Prizes, including but not limited to bespoke and/or
                custom made Prizes are subject to availability.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.11. The Winner is responsible for any costs or expenses
                involved in claiming or using the Prize other than those that
                are expressly stated as being included as part of the Prize.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.12. We are not liable for any damage or loss to a Prize caused
                by any third party. If a Prize is damaged or fails to be
                delivered, we have no obligation to provide a replacement Prize.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.13. Winners shall be responsible for all tax and other such
                charges which could apply as a result of receiving a Prize and
                should seek independent financial advice. We shall have no
                responsibility or liability to you or any tax authority in this
                regard.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.14. The Prize may be subject to additional Terms imposed by
                the supplier or other organisation connected to this promotion.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.15. If necessary due to circumstances beyond our control, we
                may (at our option) substitute the Prize for:
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.15.1. a reasonable equivalent of equal or higher value; or
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.15.2. a cash alternative.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.16. The Prize is for the named winner only and cannot be given
                or transferred to any other person.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.17. Partial details of the Winner can be obtained by sending
                an email to us at lux499team@gmail.com and will be published at
                https://lux499.com/winners-page within one week after the
                closing date and time.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.18. Entrants who do not want their details included on the
                list of Winners referred to above must notify us within a
                reasonable period of time before the closing date and time of
                this promotion.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                5.19. All Winners will be required for post Draw publicity which
                may include interviews with press, the provision or taking of
                photographs and/or videos for use in press and on social media
                platforms. Please see our PersonalInformation section below.
                Please note that your current social media profile picture may
                be displayed on our Website under the Winners section.
              </p>
            </li>

          </ol>

          {/* -----------------------------------------start six----------------------------------------------------------- */}
          <li>
            <h2 className="title-5 M-title text88P">
              Winner Notification & Prize Collection/Delivery
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                6.1. We collect and use personal information that you provide to
                us when entering a Draw and when you visit our Website in
                accordance with our Privacy Policy. This information will be
                used by us and our third parties who assist us with the
                operation and administration of the Draw. We will only ever use
                your personal information in accordance with our Privacy Policy.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                6.2. You should read our Privacy Policy carefully prior to
                entering into a Draw so that our use of your personal
                information is acceptable to you.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                6.3. By entering into a Draw, you agree and consent that we may
                use and/or publish your surname, county, occupation, character,
                appearance and likeness without any consideration or payment to
                you in accordance with our Privacy Policy.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                6.4. We shall ensure that we protect your personal information
                in accordance with the applicable data protection laws including
                but not limited to the Data Protection Act 2018 and the UK GDPR.
              </p>
            </li>
          </ol>
          {/* -----------------------------------------end six----------------------------------------------------------- */}

          {/* -----------------------------------------start seven----------------------------------------------------------- */}

          <li>
            <h2 className="title-5 M-title text88P">
              IMPORTANT LEGAL INFORMATION
            </h2>
          </li>

          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7 md:font-bold">
                It is really important that you pay particular attention to this
                clause as it contains important legal information.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.1. Entry into a Draw is non-refundable.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.2. We accept no responsibility for liability to you for
                reasons outside of our control to include (but not limited to)
                technical failures, malfunctions, internet accessibility or
                availability, web congestion, acts or omissions of any service
                provider, unauthorised intervention, computer virus, tampering,
                fraud or any other reason which affects the running, integrity,
                fairness, or administration of the Draw in any way and no
                compensation or damages will be payable to you.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.3. We reserve the right in our absolute and sole discretion to
                suspend or cancel or terminate the Draw in exceptional
                circumstances and to disqualify any person from that Draw and
                from future Draws who has directly or indirectly caused or who
                causes the Draw to be terminated, cancelled, delayed or
                suspended.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.4. We may vary these Terms or terminate, cancel, delay or
                suspend a Draw at any time in our absolute and sole discretion
                if we feel that it is reasonable to do so. If we terminate,
                cancel delay, or suspend a Draw we shall not be liable to you
                and no compensation will be offered.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.5. Our decision in relation to any Draw is final.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.6. We give no warranty or guarantee as to the quality,
                suitability and/or fitness for any particular purpose of any
                Prize. To the maximum extent permitted by law, all conditions,
                warranties and representations expressed or implied by law are
                hereby expressly excluded.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.7. To the maximum extent permitted by law, we shall not have
                any liability to you or any Winner in connection with or arising
                from any Draw howsoever caused, including costs, expenses,
                damages and any other liabilities, provided that nothing in this
                clause shall limit our liability for personal injury or death
                caused by our negligence.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.8. Our total maximum aggregate liability to each Winner shall
                be limited to the total value of any one Prize.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.9. Our total maximum aggregate liability for non-winners shall
                be limited to the amount paid to enter the Draw.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.10. Except for any legal responsibility that we cannot exclude
                in law (such as for death or personal injury) or arising under
                applicable laws relating to the protection of your personal
                information, we are not legally responsible for any:
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7 ml-4">
                7.10.1. losses that were not foreseeable to you and us when
                these Terms were formed;
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7 ml-4">
                7.10.2. losses that were not caused by any breach on our part;
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7 ml-4">
                7.10.3. business losses; and
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7 ml-4">
                7.10.4. losses to non-consumers.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.11. Nothing in these Terms shall affect your statutory rights.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7">
                7.12. If any provision or part-provision of these Terms is or
                becomes invalid, illegal or unenforceable, it shall be deemed
                deleted, but that shall not affect the validity and
                enforceability of the rest of these Terms.
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7 ml-4">
                7.13. If you want to contact us about this promotion or have a
                complaint, you can reach us by:
              </p>
            </li>
            <li>
              <p className="paragraphnormal text-7 ml-6">
                7.13.1. email: lux499team@gmail.com or 124 City Road, London,
                United Kingdom, EC1V 2NX.
              </p>
            </li>
          </ol>
          {/* -----------------------------------------end seven----------------------------------------------------------- */}
          <li>
            <h2 className="title-5 M-title text88P">
              ADDITIONAL INFORMATION ABOUT OUR WEBSITE TERMS
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                8.1. The following Terms explain how you may use this Website
                and any of its content.
              </p>
              <p className="paragraphnormal text-7">
                8.2. You should read these Terms carefully before using the
                Website. By using the Website or otherwise indicating your
                consent, you agree to be bound by these Terms. If you do not
                agree with any of these Terms, you should stop using the Website
                immediately.
              </p>
              <p className="paragraphnormal text-7">
                8.3. These Terms apply to any parts of the Website, its
                functionality and content provided to you free of charge for
                entertainment purposes only.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">USING THE WEBSITE</h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                9.1.The Website is for your personal and non-commercial use
                only.
              </p>
              <p className="paragraphnormal text-7">
                9.2.You agree that you are solely responsible for all costs and
                expenses you may incur in relation to your use of the Website.
              </p>
              <p className="paragraphnormal text-7">
                9.3.We make no promise that the Website is appropriate or
                available for use in locations outside of Great Britain. If you
                choose to access the Website from locations outside Great
                Britain, you acknowledge you do so at your own initiative and
                are responsible for compliance with local laws where they apply.
              </p>
              <p className="paragraphnormal text-7">
                9.4.We try to make the Website as accessible as possible. If you
                have any difficulties using the Website, please contact us using
                the contact details at the top of this page.
              </p>
              <p className="paragraphnormal text-7">
                9.5.As a condition of your use of the Website, you agree not to:
              </p>
              <p className="paragraphnormal text-7 ml-4">
                9.5.1. misuse or attack our Website by knowingly introducing
                viruses, trojans, worms, logic bombs or any other material which
                is malicious or technologically harmful (such as by way of a
                denial-of-service attack), or
              </p>
              <p className="paragraphnormal text-7 ml-4">
                9.5.2. attempt to gain unauthorised access to our Website, the
                server on which our Website is stored or any server, computer or
                database connected to our Website.
              </p>
              <p className="paragraphnormal text-7">
                9.6. We may prevent or suspend your access to the Website if you
                do not comply with these Terms or any applicable law.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">
              REGISTRATION AND PASSWORD SECURITY
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                10.1.Use of the Website requires registration, particularly in
                order to access your account area of the Website.
              </p>
              <p className="paragraphnormal text-7">
                10.2.We are not obliged to permit anyone to register with the
                Website and we may refuse, terminate or suspend registration to
                anyone at any time.
              </p>
              <p className="paragraphnormal text-7">
                10.3.You are responsible for making sure that your password and
                any other account details are kept secure and confidential.
              </p>

              <p className="paragraphnormal text-7">
                10.4. If we have reason to believe there is likely to be a
                breach of security or misuse of the Website through your account
                or the use of your password, we may notify you and require you
                to change your password, or we may suspend or terminate your
                account.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">INFRINGING CONTENT</h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                11.1. We will use reasonable efforts to:
              </p>
              <p className="paragraphnormal text-7 ml-4">
                11.1.1. delete accounts which are being used in an inappropriate
                manner or in breach of these Terms; and
              </p>
              <p className="paragraphnormal text-7 ml-4">
                11.1.2. identify and remove any content that is inappropriate,
                defamatory, infringes intellectual property rights or is
                otherwise in our reasonable opinion unacceptable to us when we
                are notified, but we cannot be responsible if you have failed to
                provide us with the relevant information.
              </p>
              <p className="paragraphnormal text-7">
                11.2. If you believe that any content which is distributed or
                published by the Website is inappropriate, defamatory or
                infringing on intellectual property rights, you should contact
                us immediately using the contact details at the top of this
                page.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">
              OWNERSHIP, USE AND INTELLECTUAL PROPERTY RIGHTS
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                12.1. The intellectual property rights in the Website and in any
                text, images, video, audio or other multimedia content, software
                or other information or material submitted to or accessible from
                the Website (Content) are owned by us and our licensors.
              </p>
              <p className="paragraphnormal text-7">
                12.2. We and our licensors reserve all our intellectual property
                rights (including, but not limited to, all copyright, trade
                marks, domain names, design rights, database rights, patents and
                all other intellectual property rights of any kind) whether
                registered or unregistered anywhere in the world. This means,
                for example, that we remain owners of them and are free to use
                them as we see fit.
              </p>
              <p className="paragraphnormal text-7">
                12.3. Nothing in these Terms grants you any legal rights in the
                Website or the Content other than as necessary for you to access
                it. You agree not to adjust, try to circumvent or delete any
                notices contained on the Website or the Content (including any
                intellectual property notices) and in particular, in any digital
                rights or other security technology embedded or contained within
                the Website or the Content.
              </p>
              <p className="paragraphnormal text-7">
                12.4. Trade marks: LUX499 Ltd is our brand and our trademark.
                Other trade marks and trade names may also be used on the
                Website or in the Content. Use by you of any trade marks on the
                Website or in the Content is strictly prohibited unless you have
                our prior written permission.
              </p>
              <p className="paragraphnormal text-7">
                12.5. You will retain ownership of all intellectual property
                rights (including copyright) in your entry, but you agree to
                grant us a licence to use it for any purpose connected to this
                promotion.
              </p>
              <p className="paragraphnormal text-7">
                12.6. The licence will last for the duration of the relevant
                intellectual property right and includes the right for us to:
              </p>
              <p className="paragraphnormal text-7 ml-4">
                12.6.1. edit or modify your entry (including resizing, adjusting
                the colour and adding elements such as text);
              </p>
              <p className="paragraphnormal text-7 ml-4">
                12.6.2. adapt it or incorporate it into other materials;
              </p>

              <p className="paragraphnormal text-7 ml-4 ml-4">
                12.6.3. sub-licence it to third parties or companies in our
                group to use for the purposes described in these Terms; and
              </p>
              <p className="paragraphnormal text-7 ml-4">
                12.6.4. republish it (or any version modified in the way
                described above) on any media anywhere in the world.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">
              {" "}
              SUBMITTING INFORMATION TO THE WEBSITE
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                13.1. While we try to make sure that the Website is secure, we
                do not actively monitor or check whether information supplied to
                us through the Website is confidential, commercially sensitive
                or valuable.
              </p>
              <p className="paragraphnormal text-7">
                13.2. Other than any personal information which will be dealt
                with in accordance with our Privacy Policy, we do not guarantee
                that information supplied to us through the Website will be kept
                confidential and we may use it on an unrestricted and
                free-of-charge basis as we reasonably see fit.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">
              ACCURACY OF INFORMATION AND AVAILABILITY OF THE WEBSITE
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                14.1. We try to make sure that the Website is accurate,
                up-to-date and free from bugs, but we cannot promise that it
                will be. Furthermore, we cannot promise that the Website will be
                fit or suitable for any purpose. Any reliance that you may place
                on the information on the Website is at your own risk.
              </p>
              <p className="paragraphnormal text-7">
                14.2. We may suspend or terminate access or operation of the
                Website at any time as we see fit.
              </p>
              <p className="paragraphnormal text-7">
                14.3. Any Content is provided for your general information
                purposes only and to inform you about us and our products and
                news, features, services and other websites that may be of
                interest, but has not been tailored to your specific
                requirements or circumstances. It does not constitute technical,
                financial or legal advice or any other type of advice and should
                not be relied on for any purposes. You should always use your
                own independent judgment when using our Website and its Content.
              </p>
              <p className="paragraphnormal text-7">
                14.4. While we try to make sure that the Website is available
                for your use, we do not promise that the Website will be
                available at all times or that your use of the Website will be
                uninterrupted.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">
              HYPERLINKS AND THIRD PARTY SITES
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                The Website may contain hyperlinks or references to third party
                advertising and websites other than the Website. Any such
                hyperlinks or references are provided for your convenience only.
                We have no control over third party advertising or websites and
                accept no legal responsibility for any content, material or
                information contained in them. The display of any hyperlink and
                reference to any third party advertising or website does not
                mean that we endorse that third party’s website, products or
                services. Your use of a third party site may be governed by the
                Terms of that third-party site and is at your own risk.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">
              EVENTS BEYOND OUR CONTROL
            </h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                We are not liable to you if we fail to comply with these Terms
                because of circumstances beyond our reasonable control,
                including, but not limited to, strikes, lock-outs or other
                industrial disputes; breakdown of systems or network access;
                flood, fire, explosion or accident; or epidemics or pandemics.
              </p>
            </li>
          </ol>

          <li>
            <h2 className="title-5 M-title text88P">RIGHTS OF THIRD PARTIES</h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                No one other than a party to these Terms has any right to
                enforce any of these Terms.
              </p>
            </li>
          </ol>
          <li>
            <h2 className="title-5 M-title text88P">VARIATION</h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                18.1. No changes to these Terms are valid or have any effect
                unless agreed by us in writing or made in accordance with this
                clause.
              </p>
              <p className="paragraphnormal text-7">
                18.2. We reserve the right to vary these Terms from time to
                time. Our updated Terms will be displayed on the Website and by
                continuing to use and access the Website following such changes,
                you agree to be bound by any variation made by us. It is your
                responsibility to check these Terms from time to time to verify
                such variations.
              </p>
            </li>
          </ol>

          <li>
            <h2 className="title-5 M-title text88P">DISPUTES</h2>
          </li>
          <ol className="marginu77">
            <li>
              <p className="paragraphnormal text-7">
                19.1. We will try to resolve any disputes with you quickly and
                efficiently. If you are unhappy with us, please contact us as
                soon as possible using the contact details set out at the top of
                this page.
              </p>
              <p className="paragraphnormal text-7">
                19.2. If a dispute cannot be resolved or you are unhappy with
                the outcome, you may want to use alternative dispute resolution
                (“ADR”). ADR is a process for resolving disputes between you and
                us that does not involve going to court.
              </p>

              <p className="paragraphnormal text-7">
                19.3. If you do not wish to use ADR or are unhappy with the
                outcome of ADR, you can still bring court proceedings.
              </p>
              <p className="paragraphnormal text-7">
                19.4. The laws of England and Wales will apply to these Terms.
                If you want to take court proceedings, the relevant courts of
                England and Wales will have exclusive jurisdiction in relation
                to these Terms.
              </p>
            </li>
          </ol>
        </ol>
      </div>
    </div>
  );
};

export default TermsToPlay;
