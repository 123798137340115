import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddWinnersAction,
  GetByIdWinnersAction,
  EditWinnersAction,
} from "../../Redux/Actions/AdminAction";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";

const AddWinners = () => {
  const dispatch = useDispatch();
  const { IdW } = useParams();
  let navigate = useNavigate();
  const [winning_image, setWinning_image] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [ticket_number, setTicket_number] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [showImage,setShowImage]=useState(false)

  const [itemErrors, setItemErrors] = useState([
    {
      titleError: null,
      winnerimageError: null,
      descriptionError: null,
      ticketnumberError: null,
    },
  ]);

  const {
    EditWinner,
    error:EditWinnerError,
  } = useSelector((state) => state.EditWinnersReducer);

  const {
    GetByIdWinner,
  } = useSelector((state) => state.GetByIdWinnersReducer);

  const {
    WinnerD,
    error: WinnerDError,
  } = useSelector((state) => state.AddWinnersReducer);

  useEffect(()=>{
    if(IdW){
      if(GetByIdWinner){
        setTitle(GetByIdWinner?.title)
        setWinning_image(GetByIdWinner?.winning_image)
        setDescription(GetByIdWinner?.user_description)
        setTicket_number(GetByIdWinner?.ticket_number)
        setShowImage(false)
      }
    }else{
      setTitle("")
        setWinning_image(null)
        setDescription("")
        setTicket_number("")
    }
   

  },[GetByIdWinner,IdW])




  useEffect(()=>{
if(IdW){
  dispatch(GetByIdWinnersAction(IdW))
}
  },[IdW])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (EditWinner && render2) {
      swal({
        title: "Successfully Complete",
        text: "Update Winner",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      setTimeout(() => {
        navigate("/winner-list");
      }, 2000);
      setRender2(false);
      setTitle()
      setWinning_image(null)
      setDescription()
      setTicket_number()
    }
    if (EditWinnerError && render2) {
      swal({
        title: "Error",
        text: EditWinnerError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRender2(false);
    }
  }, [dispatch, EditWinner, EditWinnerError]);



  useEffect(() => {
    if (WinnerD && render) {
      swal({
        title: "Successfully Complete",
        text: "Created Winner",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      setTimeout(() => {
        navigate("/winner-list");
      }, 2000);
      setRender(false);
      setTitle()
      setWinning_image(null)
      setDescription()
      setTicket_number()
    }
    if (WinnerDError && render) {
      swal({
        title: "Error",
        text: WinnerDError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRender(false);
    }
  }, [dispatch, WinnerD, WinnerDError]);

  const validationSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      titleError: !title && "Please Enter Title",
      winnerimageError: !winning_image && "Please Enter Image",
      descriptionError: !description && "Please Enter description",
      ticketnumberError: !ticket_number && "Please Enter Ticket number",
    };

    setItemErrors(tempErrors);

    if (Object.values(tempErrors).filter((value) => value).length) {
      // console.log(
      //   "..values",
      //   Object.values(tempErrors).filter((value) => value)
      // );
      return;
    }

    handleAddBeforeAfter(e);
  };

  const handleAddBeforeAfter = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", title);

    if (showImage) {
    formData.append("winning_image", winning_image);
    }
    formData.append("ticket_number", ticket_number);
    formData.append("user_description", description);

    if (IdW) {
    setRender2(true);
    dispatch(EditWinnersAction(IdW, formData));
    } else {
    setRender(true);
    dispatch(AddWinnersAction(formData));
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="main-center">
          <div className="showthecontent">
            <h1 className="componentheading"> {IdW ? "Edit" : "Add"} Winner</h1>
            <Link to="/winner-list">
              <button className="AddCompbutton">Back</button>
            </Link>
          </div>
          <div className="center-section">
            <form onSubmit={validationSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6 m-6">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="Text"
                    name="floating_email"
                    id="floating_email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                    placeholder=" "
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setItemErrors({
                        ...itemErrors,
                        compNtitleErrorameError: null,
                      });
                    }}
                  />
                  <label
                    htmlFor="floating_email"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Title
                  </label>

                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: itemErrors.titleError ? 1 : 0,
                    }}
                  >
                    {itemErrors.titleError ?? "valid"}
                  </span>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setItemErrors({ ...itemErrors, descriptionError: null });
                    }}
                  />
                  <label
                    htmlFor="floating_first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    User Description
                  </label>
                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: itemErrors.descriptionError ? 1 : 0,
                    }}
                  >
                    {itemErrors.descriptionError ?? "valid"}
                  </span>
                </div>

                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="number"
                    name="floating_company"
                    id="floating_company"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={ticket_number}
                    onChange={(e) => {
                      setTicket_number(parseInt(e.target.value, 10));
                      setItemErrors({ ...itemErrors, ticketnumberError: null });
                    }}
                  />
                  <label
                    htmlFor="floating_company"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Ticket Number
                  </label>
                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: itemErrors.ticketnumberError ? 1 : 0,
                    }}
                  >
                    {itemErrors.ticketnumberError ?? "valid"}
                  </span>
                </div>

                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="file"
                    name="floating_company"
                    id="floating_company"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  

                    onChange={(e) => {
                      const selectedFile = e.target.files[0];

                      if (selectedFile) {
                        // Check if the selected file is an image (e.g., jpeg, png, gif)
                        const allowedTypes = [
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        if (allowedTypes.includes(selectedFile.type)) {
                          setWinning_image(selectedFile);
                          setItemErrors({
                            ...itemErrors,
                            winnerimageError: null,
                          });
                          setShowImage(true);
                        } else {
                          setItemErrors({
                            ...itemErrors,
                            winnerimageError:
                              "Please select a valid image file (jpeg, png, gif).",
                          });
                        }
                      }
                    }}
                    accept="image/jpeg, image/png, image/gif"
                  />
                  <label
                    htmlFor="floating_company"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Winner Image
                  </label>

                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: itemErrors.winnerimageError ? 1 : 0,
                    }}
                  >
                    {itemErrors.winnerimageError ?? "valid"}
                  </span>

                  {winning_image && (
                    <>
                      {showImage ? (
                      <>
                        <img
                          src={URL.createObjectURL(winning_image)}
                          alt="After Image"
                          style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                          }}
                        />
                      </>
                       ) : (
                      <img
                        src={winning_image}
                        alt="After Image"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    )} 
                    </>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="AddCompbutton2">
                  {" "}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddWinners;
