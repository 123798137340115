import {
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_REQUEST,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
  } from "../Constants/AuthConstants";

  export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_REGISTER_REQUEST:
        return { loading: true, success: false };
  
      case USER_REGISTER_SUCCESS:
        return {
          loading: false,
          success: true,
          RegData: action.payload,
          message: action.payload.message,
        };
  
      case USER_REGISTER_FAIL:
        return { loading: false, error: action.payload, success: false };
  
      default:
        return state;
    }
  };

  export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LOGIN_REQUEST:
        return { loading: true };
  
      case USER_LOGIN_SUCCESS:
        return {
          loading: false,
          userData: action.payload,
          message: action.payload.message,
        };
  
      case USER_LOGIN_FAIL:
        return { loading: false, error: action.payload };
  
      case USER_LOGOUT:
        return {};
  
      default:
        return state;
    }
  };