import React, { useState, useEffect } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";

const Header = (props) => {
  const location = useLocation();
  const { comdetailsid } = useParams();
  const [show, setShow] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const pathname = location.pathname;

  const {
    userData,
  } = useSelector((state) => state.authReducer);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 1) {
        setIsSticky(true);
        setShow(false)
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    const hasReloadedd = localStorage.getItem("hasReloadedd");
    if (!hasReloadedd) {
      localStorage.setItem("hasReloadedd", "true");
      if (pathname == "/payment") {
        window.location.reload();
      }
    }

    if (pathname !== "/payment") {
      import("../../custom-tailwind.css")
        .then(() =>
          setTimeout(function () {
            // setIsLoading(false);
            document.body.style.overflowY = "auto";
          }, 2000)
        )
        .catch((err) => console.error("Error loading CSS: ", err));
    } else {
      setTimeout(function () {
        // setIsLoading(false);
        document.body.style.overflowY = "auto";
      }, 2000);
    }
  }, [location.pathname]); // Reacting to path changes if needed


  return (
    <>
      <div
        className={
          pathname == `/competition-deatils/${comdetailsid}`
            ? "Show_Pay_Section"
            : pathname == "/payment"
              ? "Not_Show_Pay_Section Payment_Section-top" // Add your className name for '/payment' here
              : "Not_Show_Pay_Section"
        }
      >
        <p className="mb-0 header-para23">Buy quick and easy! Lux499 accept</p>
        <img
          className="header-img598 mx-2"
          src="/images/apple-pay.svg"
          alt="image"
        />
      </div>

      <div
        className={`header ${pathname == "/blogs" ? "headerblogs" : ""} ${pathname == "/" ? "headerConditions" : ""} ${pathname == "/competitons"
            ? "headerConditions header-Competition"
            : ""
          } ${pathname == "/how-to-play"
            ? "headerConditions header-Competition"
            : ""
          } ${pathname == "/payment"
            ? "Payment_Section" // Add your className name for '/payment' here
            : ""
          } ${isSticky ? "sticky" : ""}`}
      >
        <header
          className={`main-header-function 
         `}
        >
          <nav
            className="ms-auto mx-md-auto flex max-w-7xl items-center justify-between menutogglediv"
            aria-label="Global"
          >
            <div className="flex lg:flex-1 company_header_responsive">
              <span className="sr-only">Your Company</span>
              <div className="hidden lg:flex lg:gap-x-12 navbar-main">
                <a
                  href="/competitons"
                  className={`text-sm font-semibold leading-6 text-gray-900 ${pathname == "/competitons" ? "gold-underline" : ""
                    }`}
                >
                  Competitions
                </a>
                <a
                  href="/how-to-play"
                  className={`text-sm font-semibold leading-6 text-gray-900 ${pathname == "/how-to-play" ? "gold-underline" : ""
                    }`}
                >
                  How To Play
                </a>
                <a
                  href="/winners"
                  className={`text-sm font-semibold leading-6 text-gray-900 ${pathname == "/winners" ? "gold-underline" : ""
                    }`}                >
                  Winners
                </a>
              </div>
            </div>
            <div className="md:hidden lg:hidden">
              <button
                type="button"
                onClick={() => setShow(true)}
                className={`-m-2.5 inline-flex items-center responsive_toggle ${pathname == "/winners" ||
                    pathname == "/my-profile" ||
                    pathname == "/checkout" ||
                    pathname == "/cookie-policy" ||
                    pathname == "/support" ||
                    pathname == "/terms-to-play" ||
                    pathname == "/privacy-policy" ||
                    pathname == "/basket" ||
                    pathname == `/competition-deatils/${comdetailsid}`
                    ? "winner_toggle"
                    : ""
                  } justify-center rounded-md p-2.5 text-gray-700 btn-toggle-1 `}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12 logodiv">
              <a
                href="/"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {pathname == "/" ||
                  pathname == "/competitons" ||
                  pathname == "/how-to-play" ? (
                  <>
                    {isSticky ? (
                      <>
                        <img className="logoimg " src="/images/logonew.png" alt="logonew" />
                      </>
                    ) : (
                      <>
                        <img
                          className={`logoimg ${pathname == "/" ? "homepagelogogTransparent" : ""
                            }`}
                          src="/images/transparent_logo.png"
                          alt="image"
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <img className="logoimg " src="/images/logonew.png" alt="image" />
                  </>
                )}
              </a>
            </div>

            <div className="hidden lg:flex lg:flex-1 lg:justify-end right_side_menuu">
              <span className="sr-only">Your Company</span>
              <div className=" lg:flex lg:gap-x-12 Dashboardlink">
                {userData ? (
                  <>
                    {userData?.user?.role == 0 ? (
                      <a
                        href="/dashboard"
                        className="sign-inbutton text-sm fontw1 leading-6 Dashboard "
                      >
                        Dashboard
                      </a>
                    ) : (
                      <a
                        href="/my-profile"
                        className="sign-inbutton text-sm  leading-6 Dashboard"
                      >
                        My profile
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    <a
                      href="/login"
                      className="sign-inbutton text-sm font-semibold leading-6 text-gray-900"
                    >
                      <i className="fas fa-sign-in-alt loginbutton"></i>
                    </a>
                  </>
                )}

                <a
                  href="https://www.instagram.com/lux499competitions"
                  target="_blank"
                  className="block instagram rounded-lg text-sm font-semibold leading-7 text-gray-900"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>

                <a
                  href="https://www.facebook.com/people/Lux499-Aesthetic-Competitions/61551121679837/"
                  target="_blank"
                  className="text-sm font-semibold leading-6 text-gray-900"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </a>

                <a
                  href="/basket"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  <svg
                    className="h-8 w-8 cardbtn"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <circle cx="9" cy="19" r="2" />{" "}
                    <circle cx="17" cy="19" r="2" />{" "}
                    <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" />
                  </svg>
                </a>
              </div>
            </div>
          </nav>
          <div
            className={`lg:hidden responsive_header ${pathname == `/competition-deatils/${comdetailsid}`
                ? "AddPropertySection"
                : ""
              }`}
            role="dialog"
            aria-modal="true"
          >
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              {isSticky ? (
                <>
                  <img
                    className={`h-[120px] w-auto responsive_logo ${pathname == "/winners" ||
                        pathname == "/terms-to-play" ||
                        pathname == "/my-profile" ||
                        pathname == "/privacy-policy" ||
                        pathname == "/cookie-policy" ||
                        pathname == "/basket" ||
                        pathname == `/competition-deatils/${comdetailsid}`
                        ? "winner_logo_section"
                        : ""
                      }`}
                    src="/images/logonew_old_one.png"
                    alt="image"
                  />
                </>
              ) : (
                <>
                  {pathname == "/winners" ||
                    pathname == "/basket" ||
                    pathname == "/support" ||
                    pathname == "/cookie-policy" ||
                    pathname == "/checkout" ||
                    pathname == "/my-profile" ||
                    pathname == "/privacy-policy" ||
                    pathname == "/terms-to-play" ||
                    pathname == `/competition-deatils/${comdetailsid}` ? (
                    <>
                      <img
                        className={`h-[120px] w-auto responsive_logo ${pathname == "/winners" ||
                            pathname == "/terms-to-play" ||
                            pathname == "/my-profile" ||
                            pathname == "/privacy-policy" ||
                            pathname == "/cookie-policy" ||
                            pathname == "/basket" ||
                            pathname == `/competition-deatils/${comdetailsid}`
                            ? "winner_logo_section"
                            : ""
                          }`}
                        src="/images/logonew_old_one.png"
                        alt="image"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className={`h-[120px] w-auto responsive_logo ${pathname == "/winners" ||
                            pathname == "/terms-to-play" ||
                            pathname == "/my-profile" ||
                            pathname == "/privacy-policy" ||
                            pathname == "/cookie-policy" ||
                            pathname == "/basket" ||
                            pathname == `/competition-deatils/${comdetailsid}`
                            ? "winner_logo_section"
                            : ""
                          }`}
                        src="/images/transparent_logo.png"
                        alt="image"
                      />
                    </>
                  )}
                </>
              )}
            </a>
            {show && (
              <>
                <div className=" sm:ring-gray-900/10 togglediv">
                  <div className="flex items-center justify-between">
                    <button
                      type="button"
                      onClick={() => setShow(false)}
                      className="-m-2.5 rounded-md p-2.5 text-gray-700 closebtn"
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mt-2 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                      <div className="">
                        <div className="-mx-3">
                          <div
                            className="mt-2 space-y-2 header_menu_custom"
                            id="disclosure-1"
                          >
                            <a
                              href="/competitons"
                              className={`block rounded-lg  pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50  ${pathname == "/competitons" ? "gold-underline" : ""
                                }`}
                              onClick={() => setShow(false)}
                            >
                              Competitions
                            </a>
                            <a
                              href="/how-to-play"
                              className={`block rounded-lg  pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${pathname == "/how-to-play" ? "gold-underline" : ""
                                }`}
                              onClick={() => setShow(false)}
                            >
                              How To Play
                            </a>
                            <a
                              href="/winners"
                              className={`block rounded-lg  pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${pathname == "/winners" ? "gold-underline" : ""
                                }`}
                              onClick={() => setShow(false)}
                            >
                              Winners
                            </a>
                            {userData ? (
                              <>
                                {userData?.user?.role == 0 ? (
                                  <>
                                    <a
                                      href="/dashboard"
                                      className="block rounded-lg  pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                      onClick={() => setShow(false)}
                                    >
                                      Dashboard
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <a
                                      href="/my-profile"
                                      className="sign-inbutton text-sm leading-6 Dashboard"
                                    >
                                      My profile
                                    </a>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <a
                                  href="/login"
                                  className="block sign-inbutton text-sm font-semibold leading-6 text-gray-900"
                                  onClick={() => setShow(false)}
                                >
                                  <i className="fas fa-sign-in-alt loginbutton"></i>
                                </a>
                              </>
                            )}

                            <div className="social_icon">
                              <div className="mt-0">
                                <a
                                  href="https://www.instagram.com/lux499competitions"
                                  target="_blank"
                                  className="block instagram rounded-lg text-sm font-semibold leading-7 text-gray-900"
                                  onClick={() => setShow(false)}
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </div>
                              <a
                                href="https://www.facebook.com/people/Lux499-Aesthetic-Competitions/61551121679837/"
                                target="_blank"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => setShow(false)}
                                rel="noreferrer"
                              >
                                <i className="fa-brands fa-facebook-f"></i>
                              </a>

                              <a
                                href="/basket"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => setShow(false)}
                              >
                                <svg
                                  className="h-8 w-8 cardbtn"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  {" "}
                                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                  <circle cx="9" cy="19" r="2" />{" "}
                                  <circle cx="17" cy="19" r="2" />{" "}
                                  <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
