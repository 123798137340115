import {
  ADMIN_CREATE_COMPETITION_SUCCESS,
  ADMIN_CREATE_COMPETITION_REQUEST,
  ADMIN_CREATE_COMPETITION_FAIL,
  ADMIN_COMPETITION_LIST_SUCCESS,
  ADMIN_COMPETITION_LIST_REQUEST,
  ADMIN_COMPETITION_LIST_FAIL,
  COMPETITION_DELETE_SUCCESS,
  COMPETITION_DELETE_REQUEST,
  COMPETITION_DELETE_FAILURE,
  EDIT_COMPETITION_SUCCESS,
  EDIT_COMPETITION_REQUEST,
  EDIT_COMPETITION_FAILURE,
  GET_COMPETITION_BY_ID_SUCCESS,
  GET_COMPETITION_BY_ID_REQUEST,
  GET_COMPETITION_BY_ID_FAILURE,
  ADD_BEFORE_AFTER_IMAGES_SUCCESS,
  ADD_BEFORE_AFTER_IMAGES_REQUEST,
  ADD_BEFORE_AFTER_IMAGES_FAILURE,
  EDIT_BEFORE_AFTER_IMAGES_SUCCESS,
  EDIT_BEFORE_AFTER_IMAGES_REQUEST,
  EDIT_BEFORE_AFTER_IMAGES_FAILURE,
  GET_EDIT_BEFORE_AFTER_IMAGES_SUCCESS,
  GET_EDIT_BEFORE_AFTER_IMAGES_REQUEST,
  GET_EDIT_BEFORE_AFTER_IMAGES_FAILURE,
  UPDATE_BEFORE_AFTER_IMAGES_SUCCESS,
  UPDATE_BEFORE_AFTER_IMAGES_REQUEST,
  UPDATE_BEFORE_AFTER_IMAGES_FAILURE,
  DELETE_BEFOR_AFTER_IMAGES_SUCCESS,
  DELETE_BEFOR_AFTER_IMAGES_REQUEST,
  DELETE_BEFOR_AFTER_IMAGES_FAILURE,
  ADD_SLIDER_IMAGES_SUCCESS,
  ADD_SLIDER_IMAGES_REQUEST,
  ADD_SLIDER_IMAGES_FAILURE,
  GET_SLIDER_IMAGES_LIST_SUCCESS,
  GET_SLIDER_IMAGES_LIST_REQUEST,
  GET_SLIDER_IMAGES_LIST_FAILURE,
  DELETE_SLIDER_IMAGES_SUCCESS,
  DELETE_SLIDER_IMAGES_REQUEST,
  DELETE_SLIDER_IMAGES_FAILURE,
  EDIT_SLIDER_IMAGES_SUCCESS,
  EDIT_SLIDER_IMAGES_REQUEST,
  EDIT_SLIDER_IMAGES_FAILURE,
  UPDATE_SLIDER_IMAGES_SUCCESS,
  UPDATE_SLIDER_IMAGES_REQUEST,
  UPDATE_SLIDER_IMAGES_FAILURE,
  SHOW_SLIDER_IMAGES_SUCCESS,
  SHOW_SLIDER_IMAGES_REQUEST,
  SHOW_SLIDER_IMAGES_FAILURE,
  SECOND_SHOW_SLIDER_IMAGES_SUCCESS,
  SECOND_SHOW_SLIDER_IMAGES_REQUEST,
  SECOND_SHOW_SLIDER_IMAGES_FAILURE,
  HOME_PAGE_CONTENT_SEND_SUCCESS,
  HOME_PAGE_CONTENT_SEND_FAILURE,
  HOME_PAGE_CONTENT_SEND_REQUEST,
  HOME_PAGE_CONTENT_GET_SUCCESS,
  HOME_PAGE_CONTENT_GET_REQUEST,
  HOME_PAGE_CONTENT_GET_FAILURE,
  UPDATE_HOME_PAGE_CONTENT_SUCCESS,
  UPDATE_HOME_PAGE_CONTENT_REQUEST,
  UPDATE_HOME_PAGE_CONTENT_FAILURE,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_FAILURE,
  ADD_WINNER_SUCCESS,
  ADD_WINNER_REQUEST,
  ADD_WINNER_FAILURE,
  GET_WINNER_SUCCESS,
  GET_WINNER_REQUEST,
  GET_WINNER_FAILURE,
  DELETE_WINNER_SUCCESS,
  DELETE_WINNER_REQUEST,
  DELETE_WINNER_FAILURE,
  EDIT_WINNER_SUCCESS,
  EDIT_WINNER_REQUEST,
  EDIT_WINNER_FAILURE,
  GET_BY_WINNER_SUCCESS,
  GET_BY_WINNER_REQUEST,
  GET_BY_WINNER_FAILURE,
  GET_PRICE_SUCCESS,
  GET_PRICE_REQUEST,
  GET_PRICE_FAILURE,
  GET_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_FAILURE,
  GET_CART_DATA_SUCCESS,
  GET_CART_DATA_REQUEST,
  GET_CART_DATA_FAILURE,
  UPDATE_CART_DATA_SUCCESS,
  UPDATE_CART_DATA_REQUEST,
  UPDATE_CART_DATA_FAILURE,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_REQUEST,
  DELETE_CART_ITEM_FAILURE,
  PAST_COMPETITION_LIST_SUCCESS,
  PAST_COMPETITION_LIST_REQUEST,
  PAST_COMPETITION_LIST_FAILURE,
  PROCESS_TO_PAY_SUCCESS,
  PROCESS_TO_PAY_REQUEST,
  PROCESS_TO_PAY_FAILURE,
  GET_PROCESS_TO_PAY_SUCCESS,
  GET_PROCESS_TO_PAY_REQUEST,
  GET_PROCESS_TO_PAY_FAILURE,
  USER_COMPETITION_LIST_SUCCESS,
  USER_COMPETITION_LIST_REQUEST,
  USER_COMPETITION_LIST_FAILURE,
  WITHOUT_LOGIN_ADD_TO_CART_SUCCESS,
  WITHOUT_LOGIN_ADD_TO_CART_REQUEST,
  WITHOUT_LOGIN_ADD_TO_CART_FAILURE,
  WITHOUT_LOGIN_GET_CART_DATA_SUCCESS,
  WITHOUT_LOGIN_GET_CART_DATA_REQUEST,
  WITHOUT_LOGIN_GET_CART_DATA_FAILURE,
  WITHOUT_LOGIN_DELETE_CART_ITEM_SUCCESS,
  WITHOUT_LOGIN_DELETE_CART_ITEM_REQUEST,
  WITHOUT_LOGIN_DELETE_CART_ITEM_FAILURE,
  W_LOGIN_UPDATE_CART_DATA_SUCCESS,
  W_LOGIN_UPDATE_CART_DATA_REQUEST,
  W_LOGIN_UPDATE_CART_DATA_FAILURE,
  COMPETITON_ANS_DELETE_SUCCESS,
  COMPETITON_ANS_DELETE_REQUEST,
  COMPETITON_ANS_DELETE_FAILURE,
} from "../Constants/AdminConstants";

export const userlistreducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return { loading: true, success: false };

    case GET_USER_REQUEST:
      return {
        loading: false,
        success: true,
        userlist: action.payload,
        message: action.payload.message,
      };

    case GET_USER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const pricelistreducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRICE_SUCCESS:
      return { loading: true, success: false };

    case GET_PRICE_REQUEST:
      return {
        loading: false,
        success: true,
        pricelist: action.payload,
        message: action.payload.message,
      };

    case GET_PRICE_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const CreateCompetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CREATE_COMPETITION_REQUEST:
      return { loading: true, success: false };

    case ADMIN_CREATE_COMPETITION_SUCCESS:
      return {
        loading: false,
        success: true,
        createComp: action.payload,
        message: action.payload.message,
      };

    case ADMIN_CREATE_COMPETITION_FAIL:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const CompetitionListReducer = (state = { compList: [] }, action) => {
  switch (action.type) {
    case ADMIN_COMPETITION_LIST_REQUEST:
      return { loading: true, success: false };

    case ADMIN_COMPETITION_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        compList: action.payload,
      };

    case ADMIN_COMPETITION_LIST_FAIL:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const CompetitionListForUserReducer = (
  state = { compList: [] },
  action
) => {
  switch (action.type) {
    case USER_COMPETITION_LIST_REQUEST:
      return { loading: true, success: false };

    case USER_COMPETITION_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        compList: action.payload,
      };

    case USER_COMPETITION_LIST_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetCompetitionByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COMPETITION_BY_ID_REQUEST:
      return { loading: true, success: false };

    case GET_COMPETITION_BY_ID_SUCCESS:
      return {
        loading: false,
        success: true,
        getcomp: action.payload,
      };

    case GET_COMPETITION_BY_ID_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const CompetitionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_DELETE_REQUEST:
      return { loading: true, success: false };

    case COMPETITION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        delcomp: action.payload,
        message: action.payload.message,
      };

    case COMPETITION_DELETE_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const EditCompetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_COMPETITION_REQUEST:
      return { loading: true, success: false };

    case EDIT_COMPETITION_SUCCESS:
      return {
        loading: false,
        success: true,
        EditComp: action.payload,
      };

    case EDIT_COMPETITION_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const AddBeforeAfterImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_BEFORE_AFTER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case ADD_BEFORE_AFTER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        AddBFImages: action.payload,
        message: action.payload.message,
      };

    case ADD_BEFORE_AFTER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const EditBeforeAfterImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_BEFORE_AFTER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case EDIT_BEFORE_AFTER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        EditBFImages: action.payload,
        message: action.payload.message,
      };

    case EDIT_BEFORE_AFTER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetEditBeforeAfterImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EDIT_BEFORE_AFTER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case GET_EDIT_BEFORE_AFTER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        getEditBFImages: action.payload,
        message: action.payload.message,
      };

    case GET_EDIT_BEFORE_AFTER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const UpdateBeforeAfterImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BEFORE_AFTER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case UPDATE_BEFORE_AFTER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        UpdateBFImages: action.payload,
        message: action.payload.message,
      };

    case UPDATE_BEFORE_AFTER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const DeleteBeforeAfterImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BEFOR_AFTER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case DELETE_BEFOR_AFTER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        delBFImages: action.payload,
        message: action.payload.message,
      };

    case DELETE_BEFOR_AFTER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const AddSliderImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SLIDER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case ADD_SLIDER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        AddSlider: action.payload,
        message: action.payload.message,
      };

    case ADD_SLIDER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetSliderImageListReducer = (
  state = { getSlideList: [] },
  action
) => {
  switch (action.type) {
    case GET_SLIDER_IMAGES_LIST_REQUEST:
      return { loading: true, success: false };

    case GET_SLIDER_IMAGES_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        getSlideList: action.payload,
      };

    case GET_SLIDER_IMAGES_LIST_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const DeleteSliderReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SLIDER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case DELETE_SLIDER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        deleteSlide: action.payload,
        message: action.payload.message,
      };

    case DELETE_SLIDER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const EditSliderImageReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SLIDER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case EDIT_SLIDER_IMAGES_SUCCESS:
      return { loading: false, getEditSlider: action.payload, success: true };

    case EDIT_SLIDER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const UpdateSliderImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SLIDER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case UPDATE_SLIDER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        UpdateSlider: action.payload,
      };

    case UPDATE_SLIDER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const ShowSliderImageHomeReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_SLIDER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case SHOW_SLIDER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        SliderShowImageHome: action.payload,
      };

    case SHOW_SLIDER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const ShowSliderImageCompetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case SECOND_SHOW_SLIDER_IMAGES_REQUEST:
      return { loading: true, success: false };

    case SECOND_SHOW_SLIDER_IMAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        SliderShowImagecompetion: action.payload,
      };

    case SECOND_SHOW_SLIDER_IMAGES_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const HomePageContentSendReducer = (state = {}, action) => {
  switch (action.type) {
    case HOME_PAGE_CONTENT_SEND_REQUEST:
      return { loading: true, success: false };

    case HOME_PAGE_CONTENT_SEND_SUCCESS:
      return {
        loading: false,
        success: true,
        HomeContentData: action.payload,
        message: action.payload.message,
      };

    case HOME_PAGE_CONTENT_SEND_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetHomePageContentReducer = (state = {}, action) => {
  switch (action.type) {
    case HOME_PAGE_CONTENT_GET_REQUEST:
      return { loading: true, success: false };

    case HOME_PAGE_CONTENT_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        GetHomeContentData: action.payload,
      };

    case HOME_PAGE_CONTENT_GET_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const UpdateHomePageContentReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_HOME_PAGE_CONTENT_REQUEST:
      return { loading: true, success: false };

    case UPDATE_HOME_PAGE_CONTENT_SUCCESS:
      return {
        loading: false,
        success: true,
        updateHomeContentData: action.payload,
      };

    case UPDATE_HOME_PAGE_CONTENT_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const AddToCartReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_CART_REQUEST:
      return { loading: true, success: false };

    case ADD_TO_CART_SUCCESS:
      return {
        loading: false,
        success: true,
        AddtocartD: action.payload,
        message: action.payload.message,
      };

    case ADD_TO_CART_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const WithoutUserAddToCartReducer = (state = {}, action) => {
  switch (action.type) {
    case WITHOUT_LOGIN_ADD_TO_CART_REQUEST:
      return { loading: true, success: false };

    case WITHOUT_LOGIN_ADD_TO_CART_SUCCESS:
      return {
        loading: false,
        success: true,
        WihtoutLoginAddtocartD: action.payload,
        message: action.payload.message,
      };

    case WITHOUT_LOGIN_ADD_TO_CART_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const AddWinnersReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_WINNER_REQUEST:
      return { loading: true, success: false };

    case ADD_WINNER_SUCCESS:
      return {
        loading: false,
        success: true,
        WinnerD: action.payload,
      };

    case ADD_WINNER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetWinnersReducer = (state = { GetWinner: [] }, action) => {
  switch (action.type) {
    case GET_WINNER_REQUEST:
      return { loading: true, success: false };

    case GET_WINNER_SUCCESS:
      return {
        loading: false,
        success: true,
        GetWinner: action.payload,
      };

    case GET_WINNER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const DeleteWinnersActionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_WINNER_REQUEST:
      return { loading: true, success: false };

    case DELETE_WINNER_SUCCESS:
      return {
        loading: false,
        success: true,
        deleteWinner: action.payload,
      };

    case DELETE_WINNER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const EditWinnersReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_WINNER_REQUEST:
      return { loading: true, success: false };

    case EDIT_WINNER_SUCCESS:
      return {
        loading: false,
        success: true,
        EditWinner: action.payload,
      };

    case EDIT_WINNER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetByIdWinnersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BY_WINNER_REQUEST:
      return { loading: true, success: false };

    case GET_BY_WINNER_SUCCESS:
      return {
        loading: false,
        success: true,
        GetByIdWinner: action.payload,
      };

    case GET_BY_WINNER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetCartListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CART_DATA_REQUEST:
      return { loading: true, success: false };

    case GET_CART_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        GetCartList: action.payload,
      };

    case GET_CART_DATA_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const WithoutLoginGetCartListReducer = (state = {}, action) => {
  switch (action.type) {
    case WITHOUT_LOGIN_GET_CART_DATA_REQUEST:
      return { loading: true, success: false };

    case WITHOUT_LOGIN_GET_CART_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        WithoutLoginGetCartList: action.payload,
      };

    case WITHOUT_LOGIN_GET_CART_DATA_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const UpdateCartListReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CART_DATA_REQUEST:
      return { loading: true, success: false };

    case UPDATE_CART_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        UpdateCart: action.payload,
      };

    case UPDATE_CART_DATA_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const WLoginUpdateCartListReducer = (state = {}, action) => {
  switch (action.type) {
    case W_LOGIN_UPDATE_CART_DATA_REQUEST:
      return { loading: true, success: false };

    case W_LOGIN_UPDATE_CART_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        WLoignUpdateCart: action.payload,
      };

    case W_LOGIN_UPDATE_CART_DATA_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const DeleteCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CART_ITEM_REQUEST:
      return { loading: true, success: false };

    case DELETE_CART_ITEM_SUCCESS:
      return {
        loading: false,
        success: true,
        DelCartItem: action.payload,
        message: action.payload.message,
      };

    case DELETE_CART_ITEM_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const WLoginDeleteCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case WITHOUT_LOGIN_DELETE_CART_ITEM_REQUEST:
      return { loading: true, success: false };

    case WITHOUT_LOGIN_DELETE_CART_ITEM_SUCCESS:
      return {
        loading: false,
        success: true,
        WLoginDelCartItem: action.payload,
        message: action.payload.message,
      };

    case WITHOUT_LOGIN_DELETE_CART_ITEM_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetPastCompetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case PAST_COMPETITION_LIST_REQUEST:
      return { loading: true, success: false };

    case PAST_COMPETITION_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        GetPastCompetition: action.payload,
        message: action.payload.message,
      };

    case PAST_COMPETITION_LIST_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const ProcessToPayReducer = (state = {}, action) => {
  switch (action.type) {
    case PROCESS_TO_PAY_REQUEST:
      return { loading: true, success: false };

    case PROCESS_TO_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
        ProcessToPay: action.payload,
      };

    case PROCESS_TO_PAY_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetProcessToPayReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROCESS_TO_PAY_REQUEST:
      return { loading: true, success: false };

    case GET_PROCESS_TO_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
        GetProcessTo: action.payload,
        message: action.payload.message,
      };

    case GET_PROCESS_TO_PAY_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const CompetitionAnsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITON_ANS_DELETE_REQUEST:
      return { loading: true, success: false };

    case COMPETITON_ANS_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        delcompAns: action.payload,
        message: action.payload.message,
      };

    case COMPETITON_ANS_DELETE_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};
