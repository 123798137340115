import React from "react";
import {
  useLocation,
} from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <section
        className={
          pathname == "/payment"
            ? "bg-white Payment_Section-footer" // Add your className name for '/payment' here
            : "bg-white"
        }
      >
        <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8 headaddpadding">
          <nav className="flex flex-wrap justify-center -mx-5 -my-2">
            <div className=" footerContent">
              <a
                href="/terms-to-play"
                className={`text-base leading-6 text-gray-500 hover:text-gray-900 headerdata  ${
                  pathname == "/terms-to-play" ? "gold-underlineFooter" : ""
                }`}
              >
                Terms of Play
              </a>
            </div>
            <div className=" footerContent">
              <a
                href="/privacy-policy"
                className={`text-base leading-6 text-gray-500 hover:text-gray-900 headerdata  ${
                  pathname == "/privacy-policy" ? "gold-underlineFooter-privacy" : ""
                }`}
              >
                Privacy Policy
              </a>
            </div>
            <div className=" footerContent">
              <a
                href="/cookie-policy"
                className={`text-base leading-6 text-gray-500 hover:text-gray-900 headerdata  ${
                  pathname == "/cookie-policy" ? "gold-underlineFooter-cookies" : ""
                }`}
              >
                Cookie Policy
              </a>
            </div>
            <div className=" footerContent ">
              <a
                href="/support"
                className={`text-base leading-6 text-gray-500 hover:text-gray-900 headerdata  ${
                  pathname == "/support" ? "gold-underlineFooter-support" : ""
                }`}
              >
                Support
              </a>
            </div>
            <div className=" footerContent ">
              <a
                href="/blogs"
                className={`text-base leading-6 text-gray-500 hover:text-gray-900 headerdata Supporttext  ${
                  pathname == "/blogs/" ? "gold-underlineFooter-blogs" : ""
                }`}
              >
                Blogs
              </a>
            </div>
          </nav>
        </div>
      </section>

      <div
        className={
          pathname == "/payment"
            ? "headerIcons Payment_Section-footer-bottom" // Add your className name for '/payment' here
            : "headerIcons"
        }
      >
        <p className="copyright">
          © Copyright 2024. All Rights Reserved Lux499 Ltd Design and Developed
          By <a href="https://studio45creations.com/" target="_blank" className="studio_lnk_section" rel="noreferrer"> Studio45 Creations </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
