import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Validations } from "../../utils";
import { BACKEND_API_URL } from "../../Environment";
import axios from "axios";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";

const ForgotPassword = () => {
  const location = useLocation();
  const pathname = location.pathname;
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (pathname === "/forgot-password") {
      import("../../custom-tailwind.css");
    }
  }, [pathname]);

  const [error, setError] = useState({
    emailError: null,
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const validateSubmit = (e) => {
    e.preventDefault();

    const tempError = {
      emailError: Validations.email(email),
    };
    setError(tempError);

    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    submitHandler(e);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${BACKEND_API_URL}forget-password/`, {
        email,
      })
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        swal({
          title: "Successfully Complete",
          text: "Email has been sent successfully!",
          className: "successAlert",
          icon: "success",
          buttons: false,
          timer: 5000,
        });
        navigate("/login");
      })
      .catch((err) => {
        setIsLoading(false);
        var error = "";
        if (err.response.data["message"]) {
          error = err.response.data["message"];
        } else if (err.response.data["email"][0]) {
          error = err.response.data["email"][0];
        }
        swal({
          title: "Error",
          text: error,
          className: "errorAlert",
          icon: "error",
          buttons: false,
          timer: 5000,
        });
      });
  };
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex h-screen w-full items-center justify-center forgot_password">
          <div className="forgot_password_from bg-white px-16 py-10 shadow-2xl  backdrop-blur-md max-sm:px-8">
            <div className="text-white">
              <div className="mb-6 flex flex-col items-center">
                <Link to="/">
                  <img src="/images/logonew.png" width="150" alt="" srcSet="" />
                </Link>
                <span className="text-black mt-2">Enter your Email</span>
              </div>
              <form onSubmit={validateSubmit}>
                <div className="grid mb-2 text-lg text-black forgot_input">
                  <input
                    className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                    type="text"
                    name="name"
                    placeholder="E-mail.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError({ ...error, emailError: null });
                    }}
                  />

                  <span
                    style={{
                      color: "#D14F4F",
                      textAlign: "end",
                      fontSize: "14px",
                      opacity: error.emailError ? 1 : 0,
                    }}
                  >
                    {error.emailError ?? "valid"}
                  </span>
                </div>
                <div className="mt-2 mb-6 flex justify-center text-lg text-black">
                  <button
                    type="submit"
                    className="rounded-3xl w-80 bg-yellow-400 bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600"
                  >
                    Send
                  </button>
                </div>
                <div className="mt-2 mb-8 flex justify-center text-lg text-black forgot_btn">
                  <Link to="/login">
                    <button
                      type="submit"
                      className="rounded-3xl w-80 bg-opacity-50 forgot_cancel_btn px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
