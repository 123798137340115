import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Data from "./Data.json";
import { Helmet } from "react-helmet";
const Blog = () => {
  const { slug } = useParams();
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    if (slug) {
      const filteredProducts = Data.filter((product) => product.slug === slug);
      setFilteredData(filteredProducts?.[0]);
    }
  }, [slug]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{filteredData?.meta_title}</title>
        <meta name="description" content={filteredData?.meta_desc} />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="top term_to_play">
        <div className="container mx-auto md:px-[50px] sm:py-[12px] ">
          <div className="blogheading">
            <h1 className="blogheading1">
              <b>{filteredData?.title} </b>
            </h1>
            <div className="blogaDetailsImage">
              <img
                src={filteredData?.image}
                alt={filteredData?.img_alt}
                className="my-auto mx-auto"
              />
            </div>
            <p
              className="blogparagraph"
              dangerouslySetInnerHTML={{
                __html: filteredData?.shortdescription,
              }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: filteredData?.description }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
