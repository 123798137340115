import {
  SUPPORT_SEND_SUCCESS,
  SUPPORT_SEND_REQUEST,
  SUPPORT_SEND_FAILURE,
  GET_HOME_cOMPETION_ISFEATURED_SUCCESS,
  GET_HOME_cOMPETION_ISFEATURED_FAILURE,
  GET_HOME_cOMPETION_ISFEATURED_REQUEST,
  GET_COMPETITION_DETAILS_SUCCESS,
  GET_COMPETITION_DETAILS_REQUEST,
  GET_COMPETITION_DETAILS_FAILURE,
  UPDATE_PROFILE_USER_SUCCESS,
  UPDATE_PROFILE_USER_REQUEST,
  UPDATE_PROFILE_USER_FAILURE,
} from "../Constants/PublicConstants";

export const SupportSendReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPORT_SEND_REQUEST:
      return { loading: true, success: false };

    case SUPPORT_SEND_SUCCESS:
      return {
        loading: false,
        success: true,
        SupportSend: action.payload,
      };

    case SUPPORT_SEND_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const GetcHomeIsFeaturedReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_cOMPETION_ISFEATURED_REQUEST:
      return { loading: true, success: false };

    case GET_HOME_cOMPETION_ISFEATURED_SUCCESS:
      return {
        loading: false,
        success: true,
        getIsFeatured: action.payload,
      };

    case GET_HOME_cOMPETION_ISFEATURED_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};


export const  GetCompetitionCompReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COMPETITION_DETAILS_REQUEST:
      return { loading: true, success: false };

    case GET_COMPETITION_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        getCompDetails: action.payload,
      };

    case GET_COMPETITION_DETAILS_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const UpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_USER_REQUEST:
      return { loading: true, success: false };

    case UPDATE_PROFILE_USER_SUCCESS:
      return {
        loading: false,
        success: true,
        updateProfileData: action.payload,
      };

    case UPDATE_PROFILE_USER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};