import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
} from "../Constants/AuthConstants";
import axios from "axios";
import { BACKEND_API_URL } from "../../Environment";

export const register = (token,uid) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    const { data } = await axios.get(`${BACKEND_API_URL}verify-email/${token}/${uid}/`);
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const login = (params,orderID) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    let data = [];
     if(orderID){
      data = await axios.get(`${BACKEND_API_URL}user-data/${orderID}/`);

     }else{

       data = await axios.post(`${BACKEND_API_URL}login/`, params);
     }


    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data?.data
    });
    localStorage.setItem("userData", JSON.stringify(data?.data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const logoutAction = () => (dispatch) => {
  localStorage.removeItem("userData");
  dispatch({ type: USER_LOGOUT });
};
