import React, { useState, useEffect, Suspense } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import LoadingSpinnerLanding from "../LoadingSpinnerLanding";

const AppLayout = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const pathname = location.pathname;
    document.body.style.overflowY = "hidden";
    const hasReloaded = localStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      localStorage.setItem("hasReloaded", "true");
      if (pathname == "/payment") {
        window.location.reload();
      }
    }

    if (pathname !== "/payment") {
      import("../../custom-tailwind.css")
        .then(() =>
          setTimeout(function () {
            setIsLoading(false);
            document.body.style.overflowY = "auto";
          }, 2000)
        )
        .catch((err) => console.error("Error loading CSS: ", err));
    } else {
      setTimeout(function () {
        setIsLoading(false);
        document.body.style.overflowY = "auto";
      }, 2000);
    }
  }, [location.pathname]); // Reacting to path changes if needed

  setTimeout(() => {
    setIsLoading(false);
  }, 3000);

  return (
    <>
      {isLoading && <LoadingSpinnerLanding />}
      <div className="appLayout">
        <div className="importantHeader">
          <Suspense fallback={<LoadingSpinnerLanding />}>
            <Header />
          </Suspense>
        </div>

        <div className="importantOutlet">
          <Suspense fallback={<LoadingSpinnerLanding />}>
            <Outlet />
          </Suspense>
        </div>

        <div className="importantFooter">
          <Suspense fallback={<div>Loading footer...</div>}>
            <Footer />
          </Suspense>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default AppLayout;
