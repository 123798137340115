import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPastCompetitionAction,
  CompetitionDeleteAction,
  AddBeforeAfterImagesAction,
} from "../../Redux/Actions/AdminAction";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import swal from "sweetalert";
import moment from "moment";
import { BACKEND_API_URL } from "../../Environment";
import { saveAs } from "file-saver";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
const PastCompetition = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [website_url, setWebsite_url] = useState("");
  const [after_image, setAfter_image] = useState(null);
  const [before_image, setBefore_image] = useState(null);
  const [competitionId, setCompetitionId] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [description, setDescription] = useState("");
  const [usersForRender, setUsersForRender] = useState([]);
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [competitionTitle, setCompetitionTitle] = useState("");
  const [nameShow, setNameShow] = useState("Add");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [Competionid, setcompetionid] = useState('');
  const [error, setError] = useState('');

  const validateDates = () => {
    if (new Date(startDate) > new Date(endDate)) {
      setError('Start date must be before end date.');
      return false;
    }
    setError('');
    return true;
  };


  const [itemErrors, setItemErrors] = useState([
    {
      afterImageError: null,
      beforeImageError: null,
      titleError: null,
      website_urlError: null,
    },
  ]);

  const [open, setOpen] = useState(false);

  const { GetPastCompetition, success } = useSelector(
    (state) => state.GetPastCompetitionReducer
  );

  const {
    AddBFImages,
    error: AddBFImagesError,
    success: AddBFImagesSuccess,
  } = useSelector((state) => state.AddBeforeAfterImagesReducer);

  const {
    userData: userDataToken,
  } = useSelector((state) => state.authReducer);

  const {
    delcomp,
    error: delcompError,
    success: delcompSuccess,
  } = useSelector((state) => state.CompetitionDeleteReducer);

 
  const handleClickOpen = (id, title) => {
    setCompetitionId(id);
    setCompetitionTitle(title);
    setOpen(true);
    setNameShow("Add");
  };

  const handleClose = () => {
    setOpen(false);
    setTitle("");
    setWebsite_url("");
    setAfter_image(null);
    setBefore_image(null);
    setCompetitionId();
    setIsChecked(true);
    setDescription("");
    setItemErrors([
      {
        afterImageError: null,
        beforeImageError: null,
        titleError: null,
        website_urlError: null,
      },
    ]);
  };


  const validateSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      titleError: !title && "Please Enter Title",
      website_urlError:
        website_url &&
        !website_url.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/)
          ? "Please Enter valid WebSite Url"
          : null,
      afterImageError: !after_image ? "Please Enter After Image" : null,
      beforeImageError: !before_image ? "Please Enter Before Image" : null,
      descriptionError: !description && "Please Enter Description",
    };

    setItemErrors(tempErrors);

    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    handleAddBeforeAfter(e);
  };

  const handleAddBeforeAfter = (e) => {
    setRender2(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("website_url", website_url);
    formData.append("after_image", after_image);
    formData.append("before_image", before_image);
    formData.append("competition", competitionId);
    formData.append("is_active", isChecked);
    formData.append("short_desc", description);
    dispatch(AddBeforeAfterImagesAction(formData));
  };

  useEffect(() => {
    dispatch(GetPastCompetitionAction());
  }, [delcompSuccess, AddBFImagesSuccess,dispatch]);

  const HandlerDowloadCsv = async (CId) => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}competition/${CId}/paid-orders-csv/`,
        {
          headers: {
            Authorization: `Bearer ${userDataToken?.token}`,
          },
        }
      );

      if (response.ok) {
        const filenameHeader = response.headers.get("Content-Disposition");
        const matches = /filename="([^"]+)"/.exec(filenameHeader);
        const filename = matches ? matches[1] : "competition.csv";

        const blob = await response.blob();
        saveAs(blob, filename);
      } else {
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    let userData = [];
    if (GetPastCompetition) {
        GetPastCompetition?.map((item, index) => {
        item.title = item.title;
        var utc1 = null;
        var utc = null;
        if (item.start_date || item.end_date) {
          utc1 = moment(item.start_date).format("MM-DD-yyyy hh:mm a");
          utc = moment(item.end_date).format("MM-DD-yyyy hh:mm a");
        }
        item.start_date = utc1 + " - " + utc;

        item.is_active = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              {item.is_active ? (
                <>
                  <p>
                    <i
                      className="fa fa-check"
                      style={{ color: "green", fontSize: "18px" }}
                    ></i>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <i
                      className="fa fa-close"
                      style={{ color: "red", fontSize: "18px" }}
                    ></i>
                  </p>
                </>
              )}
              
             
            </div>
          </div>
        );
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <Link to={`/add-edit-competitons/${item.id}`}>
                <button title="Edit" className="iconbtn newbtn44">
                  <p className="editiconDelete1 m-2">
                    {" "}
                    <i className="fas fa-pencil-alt"></i>
                  </p>
                </button>
              </Link>
              <button title="Delete" className=" newbtn44 iconbtn">
                <p
                  className="editiconDelete1 m-2"
                  onClick={() => deleteHandler(item.id)}
                >
                  <i className="fa fa-trash"></i>
                </p>
              </button>
            </div>
          </div>
        );
        item.afterbefore = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              
              {item?.has_after_before_images ? (
                <>
                  <Link to={`/edit-after-before-images/${item.id}`}>
                    <button title="View" className="AddCompbutton1">
                      <p
                        className="editiconDelete1"
                      >
                        <i className="fa fa-eye"></i>
                      </p>
                    </button>
                  </Link>
                  <button
                    title="Add"
                    className="AddCompbutton1"
                    onClick={() => handleClickOpen(item.id, item.title)}
                  >
                    <p className="editiconDelete1">
                      <i className="fa fa-plus"></i>
                    </p>
                  </button>
                </>
              ) : (
                <>
                  <button
                    title="add"
                    className="AddCompbutton1"
                    onClick={() => handleClickOpen(item.id, item.title)}
                  >
                    <p className="editiconDelete1">
                      <i className="fa fa-plus"></i>
                    </p>
                  </button>
                </>
              )}
            </div>
          </div>
        );
        item.dwonload = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <button title="Download Csv" className=" newbtn44 iconbtn">
                <p
                  className="editiconDelete1 m-2"
                  onClick={() => HandlerDowloadCsv(item.id)}
                >
                  <i className="fas fa-file-export"></i>
                </p>
              </button>
            </div>
          </div>
        );
        userData.push(item);
      });
    }

    setUsersForRender(userData);
  }, [success]);

  const data1 = {
    columns: [
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 500,
      },
      {
        label: "Start Date - End Date",
        field: "start_date",
        sort: "asc",
        width: 500,
      },
      {
        label: "Status",
        field: "is_active",
        sort: "asc",
        width: 500,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
      {
        label: "After Before Image",
        field: "afterbefore",
        sort: "asc",
        width: 100,
      },
      {
        label: "Download",
        field: "dwonload",
        sort: "asc",
        width: 500,
      },
    ],
    rows: usersForRender,
  };

  useEffect(() => {
    if (AddBFImages && render2) {
      swal({
        title: "Successfully Complete",
        text: "Add Success",
        className: "successAlert-login",
        buttons: false,
        timer: 1500,
      });
     
      setRender2(false);
      setOpen(false);
      setCompetitionId();
      setCompetitionTitle("");
      setTitle("");
      setWebsite_url("");
      setAfter_image(null);
      setBefore_image(null);
    }
    if (AddBFImagesError && render2) {
      swal({
        title: "Error",
        text: AddBFImagesError?.validation_errors?.[0]?.website_url?.[0],
        className: "errorAlert-login",
        buttons: false,
        timer: 2000,
      });
      setRender2(false);
    }
  }, [dispatch, AddBFImages, AddBFImagesError]);

  useEffect(() => {
    if (delcomp && render) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
      }, 1000);
      setRender(false);
    }
    if (delcompError && render) {
      swal({
        title: "Error",
        text: delcompError,
        className: "errorAlert-login",
        buttons: false,
        timer: 1500,
      });
      setRender(false);
    }
  }, [dispatch, delcomp, delcompError]);

  const deleteHandler = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(CompetitionDeleteAction(id));
        setRender(true);
      }
    });
  };


  return (
    <>
      <div className="main-center">
        <div className="showthecontent">
          <h1 className="componentheading">Past Competition List</h1>
          <Link to="/add-edit-competitons">
            <button className="AddCompbutton">Add Competition</button>
          </Link>
        </div>
       
        <div className="center-section">
          <div>
            <MDBDataTable
              style={{}}
              responsive
              striped
              bordered
              small
              data={data1}
            />
          </div>
        </div>
        <Dialog
          className="dialogsctionmain"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4 className="flex justify-center title-top">
              {nameShow} After Before Images
            </h4>

            <h4 className="title-2">Competition : {competitionTitle}</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-6 m-6">
                <>
                 
                  <div>
                    <label
                      for="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Title"
                      required
                      
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setItemErrors({ ...itemErrors, titleError: null });
                      }}
                    />
                    <span
                      style={{
                        color: "#D14F4F",
                        opacity: itemErrors.titleError ? 1 : 0,
                      }}
                    >
                      {itemErrors?.titleError ?? "valid"}
                    </span>
                  </div>
                  <div>
                    <label
                      for="website"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Web Site Url
                    </label>
                    <input
                      type="text"
                      id="website"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Website Url"
                      required
                      
                      value={website_url}
                      onChange={(e) => {
                        setWebsite_url(e.target.value);
                        setItemErrors({
                          ...itemErrors,
                          website_urlError: null,
                        });
                      }}
                    />
                    <span
                      style={{
                        color: "#D14F4F",
                        opacity: itemErrors?.website_urlError ? 1 : 0,
                      }}
                    >
                      {itemErrors.website_urlError ?? "valid"}
                    </span>
                  </div>

                  <div>
                    <label>Before Image</label>
                    <input
                     
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          const allowedTypes = [
                            "image/jpeg",
                            "image/png",
                            "image/gif",
                          ];
                          if (allowedTypes.includes(selectedFile.type)) {
                            setBefore_image(selectedFile);
                            setItemErrors({
                              ...itemErrors,
                              beforeImageError: null,
                            });
                          } else {
                            setItemErrors({
                              ...itemErrors,
                              beforeImageError:
                                "Please select a valid image file (jpeg, png, gif).",
                            });
                          }
                        }
                      }}
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                    />
                    <span
                      style={{
                        color: "#D14F4F",
                        opacity: itemErrors?.beforeImageError ? 1 : 0,
                      }}
                    >
                      {itemErrors?.beforeImageError ?? "valid"}
                    </span>
                    {before_image && (
                      <img
                        src={URL.createObjectURL(before_image)}
                        alt="After_Image"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    )}
                  </div>
                  <div>
                    <label>After Image</label>
                    <input
                      

                      onChange={(e) => {
                        const selectedFile = e.target.files[0];

                        if (selectedFile) {
                          const allowedTypes = [
                            "image/jpeg",
                            "image/png",
                            "image/gif",
                          ];
                          if (allowedTypes.includes(selectedFile.type)) {
                            setAfter_image(selectedFile);
                            setItemErrors({
                              ...itemErrors,
                              afterImageError: null,
                            });
                          } else {
                            setItemErrors({
                              ...itemErrors,
                              afterImageError:
                                "Please select a valid image file (jpeg, png, gif).",
                            });
                          }
                        }
                      }}
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                    />
                    <span
                      style={{
                        color: "#D14F4F",
                        opacity: itemErrors?.afterImageError ? 1 : 0,
                      }}
                    >
                      {itemErrors?.afterImageError ?? "valid"}
                    </span>
                    {after_image && (
                      <>
                        <img
                          src={URL.createObjectURL(after_image)}
                          alt="After_Image"
                          style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                          }}
                        />
                      </>
                    )}
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <div className="flex items-center">
                      <label
                        for="checked-checkbox"
                        className="ml-2 text-sm font-medium mb-0 text-gray-900 dark:text-gray-300"
                      >
                        Status
                      </label>
                      <input
                        checked={isChecked} 
                        onChange={(e) => setIsChecked(e.target.checked)}
                        id="checked-checkbox"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="comment"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Description
                    </label>
                    <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                      <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                        <textarea
                          id="comment"
                          rows="4"
                          className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                          placeholder="Write a description..."
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                       
                      </div>
                    </div>
                  </div>
                </>
                {/* // ))} */}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="AddCompbutton" onClick={handleClose}>
              Cancel
            </button>
            <button
              className="AddCompbutton"
              onClick={(e) => validateSubmit(e)}
              autoFocus
            >
              Submit
            </button>
          </DialogActions>
        </Dialog>
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the start and end dates for the CSV download.
          </DialogContentText>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            id="start-date"
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="end-date"
            label="End Date"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary">
            Download
          </Button>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </>
  );
};

export default PastCompetition;
