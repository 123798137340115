import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  GetSliderImageListAction,
  DeleteSliderAction,
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert";

const SlideList = () => {
  const dispatch = useDispatch();
  const [usersForRender, setUsersForRender] = useState([]);
  const [render,setRender]=useState(false)

  const {
    getSlideList,
    success: getSlideListSuccess,
  } = useSelector((state) => state.GetSliderImageListReducer);

  const {
    deleteSlide,
    error: deleteSlideError,
    success: deleteSlideSuccess,
  } = useSelector((state) => state.DeleteSliderReducer);

  useEffect(() => {
    dispatch(GetSliderImageListAction());
  }, [deleteSlideSuccess]);

  useEffect(() => {
    let userData = [];

    if (getSlideList) {
      getSlideList?.map((item, index) => {
        item.title = item.title;
        item.slider_image = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <button
                className="iconbtn newbtn44"
              >
                <p className="editiconDelete1 m-2">
                 <img className="displaythesliderimages" src={item?.slider_image} alt="image"/>
                </p>
              </button>
            </div>
          </div>
        )
        item.type = item.type;

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <Link to={`/add-edit-slide/${item.id}`}>
              <button
                title="edit"
                className="iconbtn newbtn44"
              >
                <p className="editiconDelete1 m-2">
                  {" "}
                  <i className="fas fa-pencil-alt"></i>
                </p>
              </button>
              </Link>
              <button title="delete" className=" newbtn44 iconbtn">
                <p
                  className="editiconDelete1 m-2"
                    onClick={() => deleteHandler(item.id)}
                >
                  <i className="fa fa-trash"></i>
                </p>
              </button>
            </div>
          </div>
        );
        userData.push(item);
      });
    }

    setUsersForRender(userData);
  }, [getSlideListSuccess]);

  const data1 = {
    columns: [
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 500,
      },
      {
        label: "Slider image",
        field: "slider_image",
        sort: "asc",
        width: 500,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 500,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: usersForRender,
  };

  useEffect(() => {
    if (deleteSlide && render) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
      }, 1000);
      setRender(false);
    }
    if (deleteSlideError && render) {
      swal({
        title: "Error",
        text: deleteSlideError,
        className: "errorAlert-login",
        buttons: false,
        timer: 1500,
      });
      setRender(false);
    }
  }, [dispatch, deleteSlide, deleteSlideError]);


  const deleteHandler = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setRender(true)
        dispatch(DeleteSliderAction(id))
      }
    });
   
  };

  return (
    <>
      <div className="main-center">
        <div className="showthecontent">
          <h1 className="componentheading">Slider List</h1>
          <Link to="/add-edit-slide">
            <button className="AddCompbutton">Add Slider</button>
          </Link>
        </div>
        <div className="center-section">
          <div>
            <MDBDataTable
              style={{}}
              responsive
              striped
              bordered
              small
              data={data1}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SlideList;
