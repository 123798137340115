import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_API_URL, PAYMENT_URL } from "../../Environment";
import LoadingSpinner from "../LoadingSpinner";
import { login } from "../../Redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";

const WithoutpayPayment = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const {
        error: userDataError,
        userData,
    } = useSelector((state) => state.authReducer);
    const [paymentSuccess, setpaymentSuccess] = useState();
    const [paymentFail, setpaymentFail] = useState();
    const [loading, setLoading] = useState(false);
    const order_id = localStorage.getItem("order_Id")

    useEffect(() => {
        if (!userData) {
            dispatch(login('', order_id))
        }
    }, [])

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                if (order_id) {
                    let formData = new FormData(); //formdata object
                    formData.append("order_id", order_id);
                    const db_response = await axios.post(
                        `${BACKEND_API_URL}get-payment-status-zero-amount/`,
                        formData
                    );
                    try {
                        setpaymentSuccess(db_response.data);
                        // await axios.get(`${BACKEND_API_URL}email-sent/${order_id}`);
                        localStorage.removeItem("order_Id")
                        setLoading(false);
                    } catch (error) {
                        console.log("DB response error", error);
                        setLoading(false);
                    }

                }
            } catch (error) {
                setLoading(false);
                console.error("Error from the payment status API-----", error);
                setpaymentFail(
                    "Something went wrong, please try again to purchase tickets."
                );
                // navigate("/");
            }
        };
        checkPaymentStatus();
    }, [location]);

    return (
        <div className="afterPaymentDiv pay-section">
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {!order_id && !paymentSuccess && (
                        <div className="borderDivafterPAy payment_unsecessful">
                            <h2 className="font-semibold text-center">
                                Order Not Found
                            </h2>
                            <div className="usuccess_full">
                                <span>
                                    <i className="fa-solid fa-xmark"></i>
                                </span>
                            </div>
                            <p className="font-medium mb-2">
                                We're sorry this didn't go through.{" "}
                            </p>
                            <p className="font-medium mb-4">
                                {" "}
                                Create a New order , Let's buy ticket and enjoy Lux499.
                                
                            </p>
                            <p className="font-medium mb-4">
                                {" "}
                                We look forward to you playing with us.
                            </p>
                            <p className="font-medium mb-4">Lux499</p>
                            <div className="back_to_home">
                                <Link to="/">Back To Home</Link>
                            </div>
                        </div>
                    )}
                    {paymentSuccess && (
                        <div className="borderDivafterPAy payment_unsecessful">
                            <h2 className="font-semibold text-center capitalize">
                              Order has been Placed
                            </h2>
                            <div className="success_full">
                                <span>
                                    <i className="fa-solid fa-check"></i>
                                </span>
                            </div>
                            <div className="text-center congratulation_text text-xl font-medium">
                                <p className="">
                                    Congratulations, the following ticket's are yours:
                                </p>
                                <p style={{ width: "300px", textAlign: "justify" }}>
                                    {paymentSuccess?.data?.join(", ")}
                                </p>
                            </div>
                            <p className="font-medium">Keep tuned for the live draw. </p>
                            <p className="font-medium"> Good luck!</p>
                            <p className="font-medium">Lux499</p>
                            <div className="back_to_home">
                                <Link to="/">Back To Home</Link>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default WithoutpayPayment;
