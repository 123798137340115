export const ADMIN_CREATE_COMPETITION_SUCCESS =
  "ADMIN_CREATE_COMPETITION_SUCCESS";
export const ADMIN_CREATE_COMPETITION_REQUEST =
  "ADMIN_CREATE_COMPETITION_REQUEST";
export const ADMIN_CREATE_COMPETITION_FAIL = "ADMIN_CREATE_COMPETITION_FAIL";

export const ADMIN_COMPETITION_LIST_SUCCESS = "ADMIN_COMPETITION_LIST_SUCCESS";
export const ADMIN_COMPETITION_LIST_REQUEST = "ADMIN_COMPETITION_LIST_REQUEST";
export const ADMIN_COMPETITION_LIST_FAIL = "ADMIN_COMPETITION_LIST_FAIL";

export const EDIT_COMPETITION_SUCCESS = "EDIT_COMPETITION_SUCCESS";
export const EDIT_COMPETITION_REQUEST = "EDIT_COMPETITION_REQUEST";
export const EDIT_COMPETITION_FAILURE = "EDIT_COMPETITION_FAILURE";

export const GET_COMPETITION_BY_ID_SUCCESS = "GET_COMPETITION_BY_ID_SUCCESS";
export const GET_COMPETITION_BY_ID_REQUEST = "GET_COMPETITION_BY_ID_REQUEST";
export const GET_COMPETITION_BY_ID_FAILURE = "GET_COMPETITION_BY_ID_FAILURE";

export const COMPETITION_DELETE_SUCCESS = "COMPETITION_DELETE_SUCCESS";
export const COMPETITION_DELETE_REQUEST = "COMPETITION_DELETE_REQUEST";
export const COMPETITION_DELETE_FAILURE = "COMPETITION_DELETE_FAILURE";

export const ADD_BEFORE_AFTER_IMAGES_SUCCESS =
  "ADD_BEFORE_AFTER_IMAGES_SUCCESS";
export const ADD_BEFORE_AFTER_IMAGES_REQUEST =
  "ADD_BEFORE_AFTER_IMAGES_REQUEST";
export const ADD_BEFORE_AFTER_IMAGES_FAILURE =
  "ADD_BEFORE_AFTER_IMAGES_FAILURE";

export const EDIT_BEFORE_AFTER_IMAGES_SUCCESS =
  "EDIT_BEFORE_AFTER_IMAGES_SUCCESS";
export const EDIT_BEFORE_AFTER_IMAGES_REQUEST =
  "EDIT_BEFORE_AFTER_IMAGES_REQUEST";
export const EDIT_BEFORE_AFTER_IMAGES_FAILURE =
  "EDIT_BEFORE_AFTER_IMAGES_FAILURE";

export const GET_EDIT_BEFORE_AFTER_IMAGES_SUCCESS =
  "GET_EDIT_BEFORE_AFTER_IMAGES_SUCCESS";
export const GET_EDIT_BEFORE_AFTER_IMAGES_REQUEST =
  "GET_EDIT_BEFORE_AFTER_IMAGES_REQUEST";
export const GET_EDIT_BEFORE_AFTER_IMAGES_FAILURE =
  "GET_EDIT_BEFORE_AFTER_IMAGES_FAILURE";

export const UPDATE_BEFORE_AFTER_IMAGES_SUCCESS =
  "UPDATE_BEFORE_AFTER_IMAGES_SUCCESS";
export const UPDATE_BEFORE_AFTER_IMAGES_REQUEST =
  "UPDATE_BEFORE_AFTER_IMAGES_REQUEST";
export const UPDATE_BEFORE_AFTER_IMAGES_FAILURE =
  "UPDATE_BEFORE_AFTER_IMAGES_FAILURE";

export const DELETE_BEFOR_AFTER_IMAGES_SUCCESS =
  "DELETE_BEFOR_AFTER_IMAGES_SUCCESS";
export const DELETE_BEFOR_AFTER_IMAGES_REQUEST =
  "DELETE_BEFOR_AFTER_IMAGES_REQUEST";
export const DELETE_BEFOR_AFTER_IMAGES_FAILURE =
  "DELETE_BEFOR_AFTER_IMAGES_FAILURE";

export const ADD_SLIDER_IMAGES_SUCCESS = "ADD_SLIDER_IMAGES_SUCCESS";
export const ADD_SLIDER_IMAGES_REQUEST = "ADD_SLIDER_IMAGES_REQUEST";
export const ADD_SLIDER_IMAGES_FAILURE = "ADD_SLIDER_IMAGES_FAILURE";

export const GET_SLIDER_IMAGES_LIST_SUCCESS = "GET_SLIDER_IMAGES_LIST_SUCCESS";
export const GET_SLIDER_IMAGES_LIST_REQUEST = "GET_SLIDER_IMAGES_LIST_REQUEST";
export const GET_SLIDER_IMAGES_LIST_FAILURE = "GET_SLIDER_IMAGES_LIST_FAILURE";

export const DELETE_SLIDER_IMAGES_SUCCESS = "DELETE_SLIDER_IMAGES_SUCCESS";
export const DELETE_SLIDER_IMAGES_REQUEST = "DELETE_SLIDER_IMAGES_REQUEST";
export const DELETE_SLIDER_IMAGES_FAILURE = "DELETE_SLIDER_IMAGES_FAILURE";

export const EDIT_SLIDER_IMAGES_SUCCESS = "EDIT_SLIDER_IMAGES_SUCCESS";
export const EDIT_SLIDER_IMAGES_REQUEST = "EDIT_SLIDER_IMAGES_REQUEST";
export const EDIT_SLIDER_IMAGES_FAILURE = "EDIT_SLIDER_IMAGES_FAILURE";

export const UPDATE_SLIDER_IMAGES_SUCCESS = "UPDATE_SLIDER_IMAGES_SUCCESS";
export const UPDATE_SLIDER_IMAGES_REQUEST = "UPDATE_SLIDER_IMAGES_REQUEST";
export const UPDATE_SLIDER_IMAGES_FAILURE = "UPDATE_SLIDER_IMAGES_FAILURE";

export const SHOW_SLIDER_IMAGES_SUCCESS = "SHOW_SLIDER_IMAGES_SUCCESS";
export const SHOW_SLIDER_IMAGES_REQUEST = "SHOW_SLIDER_IMAGES_REQUEST";
export const SHOW_SLIDER_IMAGES_FAILURE = "SHOW_SLIDER_IMAGES_FAILURE";

export const SECOND_SHOW_SLIDER_IMAGES_SUCCESS =
  "SECOND_SHOW_SLIDER_IMAGES_SUCCESS";
export const SECOND_SHOW_SLIDER_IMAGES_REQUEST =
  "SECOND_SHOW_SLIDER_IMAGES_REQUEST";
export const SECOND_SHOW_SLIDER_IMAGES_FAILURE =
  "SECOND_SHOW_SLIDER_IMAGES_FAILURE";

export const HOME_PAGE_CONTENT_SEND_SUCCESS = "HOME_PAGE_CONTENT_SEND_SUCCESS";
export const HOME_PAGE_CONTENT_SEND_FAILURE = "HOME_PAGE_CONTENT_SEND_FAILURE";
export const HOME_PAGE_CONTENT_SEND_REQUEST = "HOME_PAGE_CONTENT_SEND_REQUEST";

export const HOME_PAGE_CONTENT_GET_SUCCESS = "HOME_PAGE_CONTENT_GET_SUCCESS";
export const HOME_PAGE_CONTENT_GET_REQUEST = "HOME_PAGE_CONTENT_GET_REQUEST";
export const HOME_PAGE_CONTENT_GET_FAILURE = "HOME_PAGE_CONTENT_GET_FAILURE";

export const UPDATE_HOME_PAGE_CONTENT_SUCCESS =
  "UPDATE_HOME_PAGE_CONTENT_SUCCESS";
export const UPDATE_HOME_PAGE_CONTENT_REQUEST =
  "UPDATE_HOME_PAGE_CONTENT_REQUEST";
export const UPDATE_HOME_PAGE_CONTENT_FAILURE =
  "UPDATE_HOME_PAGE_CONTENT_FAILURE";

export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

export const WITHOUT_LOGIN_ADD_TO_CART_SUCCESS =
  "WITHOUT_LOGIN_ADD_TO_CART_SUCCESS";
export const WITHOUT_LOGIN_ADD_TO_CART_REQUEST =
  "WITHOUT_LOGIN_ADD_TO_CART_REQUEST";
export const WITHOUT_LOGIN_ADD_TO_CART_FAILURE =
  "WITHOUT_LOGIN_ADD_TO_CART_FAILURE";

export const ADD_WINNER_SUCCESS = "ADD_WINNER_SUCCESS";
export const ADD_WINNER_REQUEST = "ADD_WINNER_REQUEST";
export const ADD_WINNER_FAILURE = "ADD_WINNER_FAILURE";

export const GET_WINNER_SUCCESS = "GET_WINNER_SUCCESS";
export const GET_WINNER_REQUEST = "GET_WINNER_REQUEST";
export const GET_WINNER_FAILURE = "GET_WINNER_FAILURE";

export const DELETE_WINNER_SUCCESS = "DELETE_WINNER_SUCCESS";
export const DELETE_WINNER_REQUEST = "DELETE_WINNER_REQUEST";
export const DELETE_WINNER_FAILURE = "DELETE_WINNER_FAILURE";

export const EDIT_WINNER_SUCCESS = "EDIT_WINNER_SUCCESS";
export const EDIT_WINNER_REQUEST = "EDIT_WINNER_REQUEST";
export const EDIT_WINNER_FAILURE = "EDIT_WINNER_FAILURE";

export const GET_BY_WINNER_SUCCESS = "GET_BY_WINNER_SUCCESS";
export const GET_BY_WINNER_REQUEST = "GET_BY_WINNER_REQUEST";
export const GET_BY_WINNER_FAILURE = "GET_BY_WINNER_FAILURE";

export const GET_PRICE_SUCCESS = "GET_PRICE_SUCCESS";
export const GET_PRICE_REQUEST = "GET_PRICE_REQUEST";
export const GET_PRICE_FAILURE = "GET_PRICE_FAILURE";

export const GET_USER_SUCCESS = "GET_PRICE_SUCCESS";
export const GET_USER_REQUEST = "GET_PRICE_REQUEST";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS";
export const GET_CART_DATA_REQUEST = "GET_CART_DATA_REQUEST";
export const GET_CART_DATA_FAILURE = "GET_CART_DATA_FAILURE";

export const WITHOUT_LOGIN_GET_CART_DATA_SUCCESS = "WITHOUT_LOGIN_GET_CART_DATA_SUCCESS";
export const WITHOUT_LOGIN_GET_CART_DATA_REQUEST = "WITHOUT_LOGIN_GET_CART_DATA_REQUEST";
export const WITHOUT_LOGIN_GET_CART_DATA_FAILURE = "WITHOUT_LOGIN_GET_CART_DATA_FAILURE";

export const UPDATE_CART_DATA_SUCCESS = "UPDATE_CART_DATA_SUCCESS";
export const UPDATE_CART_DATA_REQUEST = "UPDATE_CART_DATA_REQUEST";
export const UPDATE_CART_DATA_FAILURE = "UPDATE_CART_DATA_FAILURE";

export const W_LOGIN_UPDATE_CART_DATA_SUCCESS = "W_LOGIN_UPDATE_CART_DATA_SUCCESS";
export const W_LOGIN_UPDATE_CART_DATA_REQUEST = "W_LOGIN_UPDATE_CART_DATA_REQUEST";
export const W_LOGIN_UPDATE_CART_DATA_FAILURE = "W_LOGIN_UPDATE_CART_DATA_FAILURE";

export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_REQUEST = "DELETE_CART_ITEM_REQUEST";
export const DELETE_CART_ITEM_FAILURE = "DELETE_CART_ITEM_FAILURE";

export const WITHOUT_LOGIN_DELETE_CART_ITEM_SUCCESS = "WITHOUT_LOGIN_DELETE_CART_ITEM_SUCCESS";
export const WITHOUT_LOGIN_DELETE_CART_ITEM_REQUEST = "WITHOUT_LOGIN_DELETE_CART_ITEM_REQUEST";
export const WITHOUT_LOGIN_DELETE_CART_ITEM_FAILURE = "WITHOUT_LOGIN_DELETE_CART_ITEM_FAILURE";

export const PAST_COMPETITION_LIST_SUCCESS = "PAST_COMPETITION_LIST_SUCCESS";
export const PAST_COMPETITION_LIST_REQUEST = "PAST_COMPETITION_LIST_REQUEST";
export const PAST_COMPETITION_LIST_FAILURE = "PAST_COMPETITION_LIST_FAILURE";

export const PROCESS_TO_PAY_SUCCESS = "PROCESS_TO_PAY_SUCCESS";
export const PROCESS_TO_PAY_REQUEST = "PROCESS_TO_PAY_REQUEST";
export const PROCESS_TO_PAY_FAILURE = "PROCESS_TO_PAY_FAILURE";

export const GET_PROCESS_TO_PAY_SUCCESS = "GET_PROCESS_TO_PAY_SUCCESS";
export const GET_PROCESS_TO_PAY_REQUEST = "GET_PROCESS_TO_PAY_REQUEST";
export const GET_PROCESS_TO_PAY_FAILURE = "GET_PROCESS_TO_PAY_FAILURE";

export const USER_COMPETITION_LIST_SUCCESS = "USER_COMPETITION_LIST_SUCCESS";
export const USER_COMPETITION_LIST_REQUEST = "USER_COMPETITION_LIST_REQUEST";
export const USER_COMPETITION_LIST_FAILURE = "USER_COMPETITION_LIST_FAILURE";

export const COMPETITON_ANS_DELETE_SUCCESS = "COMPETITON_ANS_DELETE_SUCCESS"
export const COMPETITON_ANS_DELETE_REQUEST = "COMPETITON_ANS_DELETE_REQUEST"
export const COMPETITON_ANS_DELETE_FAILURE = "COMPETITON_ANS_DELETE_FAILURE"

