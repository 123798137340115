import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_API_URL, PAYMENT_URL } from "../../Environment";
import LoadingSpinner from "../LoadingSpinner";
import { login } from "../../Redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";

const AfterPayment = () => {
  const location = useLocation();
  const shouldLog = useRef(true);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {
    error: userDataError,
    userData,
  } = useSelector((state) => state.authReducer);
  const searchParams = new URLSearchParams(location.search);
  const orderID = searchParams.get("orderId");
  const resourcePath = searchParams.get("resourcePath");
  const [paymentSuccess, setpaymentSuccess] = useState();
  const [paymentFail, setpaymentFail] = useState();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (orderID) {
  //       try {
  //         const response = await axios.get(`${BACKEND_API_URL}user-data/${orderID}/`);
  //         console.log(response)
  //         localStorage.setItem('hasReloaded',response.user_data.hasReloaded);
  //         localStorage.setItem('DeviceIdUser',response.user_data.DeviceIdUser);
  //         localStorage.setItem('competitondetailid',response.user_data.competitondetailid);
  //         localStorage.setItem('slidervalue',response.user_data.slidervalue);
  //         localStorage.setItem('userData',response.user_data.userData);
  //         localStorage.setItem('loglevel',response.user_data.loglevel);
  //         // Handle response data as needed
  //       } catch (error) {
  //         console.error('Error:', error);
  //         // Handle error as needed
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [orderID]);

  useEffect(()=>{
    if(!userData){
      dispatch(login('',orderID))
    }
  },[])

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        if (resourcePath && shouldLog.current) {
          shouldLog.current = false;
          const response = await axios.get(`${PAYMENT_URL}${resourcePath}`, {});
          try {
            if (response && orderID) {
              let formData = new FormData(); //formdata object
              formData.append("Data", JSON.stringify(response)); //append the values with key, value pair
              formData.append("order_Id", orderID);
              const db_response = await axios.post(
                `${BACKEND_API_URL}get-payment-status/`,
                formData
              );
              try {
                setpaymentSuccess(db_response.data);
                await axios.get(`${BACKEND_API_URL}email-sent/${orderID}`);

                setLoading(false);
              } catch (error) {
                console.log("DB response error", error);
                setLoading(false);
              }
            }
          } catch (error) {
            console.error("API Error -------", error);
            setpaymentFail(error?.response?.data?.message);
            setLoading(false);
            // navigate("/");
          }
        }
      } catch (error) {
        setLoading(false);
        console.error("Error from the payment status API-----", error);
        setpaymentFail(
          "Something went wrong, please try again to purchase tickets."
        );
        // navigate("/");
      }
    };
    checkPaymentStatus();
  }, [location]);

  return (
    <div className="afterPaymentDiv pay-section">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {paymentFail && (
            <div className="borderDivafterPAy payment_unsecessful">
              <h2 className="font-semibold text-center">
                Payment Unsuccessful
              </h2>
              <div className="usuccess_full">
                <span>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
              <p className="font-medium mb-2">
                We're sorry this didn't go through.{" "}
              </p>
              <p className="font-medium mb-4">
                {" "}
                {/* We'd suggest, making sure the information entered is correct or
                checking in with your bank. */}
                {paymentFail}
              </p>
              <p className="font-medium mb-4">
                {" "}
                We look forward to you playing with us.
              </p>
              <p className="font-medium mb-4">Lux499</p>
              <div className="back_to_home">
                <Link to="/">Back To Home</Link>
              </div>
            </div>
          )}
          {paymentSuccess && (
            <div className="borderDivafterPAy payment_unsecessful">
              <h2 className="font-semibold text-center capitalize">
                {paymentSuccess?.message}
              </h2>
              <div className="success_full">
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <div className="text-center congratulation_text text-xl font-medium">
                <p className="">
                  Congratulations, the following ticket's are yours:
                </p>
                <p style={{ width: "300px", textAlign: "justify" }}>
                  {paymentSuccess?.data?.join(", ")}
                </p>
              </div>
              <p className="font-medium">Keep tuned for the live draw. </p>
              <p className="font-medium"> Good luck!</p>
              <p className="font-medium">Lux499</p>
              <div className="back_to_home">
                <Link to="/">Back To Home</Link>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default AfterPayment;
