import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AdminSiderBar from "./AdminSiderBar";
import LoadingSpinnerLanding from "../LoadingSpinnerLanding";

const AdminLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [adminShow,setAdminShow]=useState(true)
  
  setTimeout(function () {
    setIsLoading(false);
  }, 1800);

  const showDisplayHandle=()=>{
    setAdminShow(!adminShow)
    console.log("click")
  }

  return (
    <>
      {isLoading ? (
        <>
          <LoadingSpinnerLanding />{" "}
        </>
      ) : (
        <></>
      )}
      <body className="flex bg-gray-100 min-h-screen admin-dashboard">
        <AdminSiderBar adminShow={adminShow}/>
        <div className="flex-grow text-gray-800">
          <AdminHeader showDisplayHandle={showDisplayHandle}/>
          <div>
            <Outlet />
          </div>
        </div>
      </body>
    </>
  );
};

export default AdminLayout;
