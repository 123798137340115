import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetuserAction } from "../../Redux/Actions/AdminAction";
import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { saveAs } from "file-saver";
import { BACKEND_API_URL } from "../../Environment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';


const Userlist = () => {
  const dispatch = useDispatch();
  const [usersForRender, setUsersForRender] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const [userid, setuserid] = useState();
  const [isDialogOpen1, setIsDialogOpen1] = useState(false);
  const [status, setStatus] = useState('active');

  const handleClickOpen = (id) => {
    setuserid(id)
    const user = userlist.find(user => user.id === id);
    if (user) {
      if (user.is_active) {
        setStatus("active")
      } else {
        setStatus("inactive")
      }

      setIsDialogOpen1(true);
    }
  }

  const handleClose1 = () => {
    setIsDialogOpen1(false);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
    console.log(event.target.value)
  };

  const validateDates = () => {
    if (new Date(startDate) > new Date(endDate)) {
      setError('Start date must be before end date.');
      return false;
    }
    setError('');
    return true;
  };


  const handleDelete = async (userId) => {
    const confirmed = await Swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this user?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (confirmed) {
      try {
        const response = await fetch(
          `${BACKEND_API_URL}delete-user/${userId}/`,
          {
            method: 'DELETE', // Assuming you're using DELETE method for deletion
            headers: {
              Authorization: `Bearer ${userDataToken?.token}`,
            },
          }
        );

        if (response.ok) {
          Swal("Deleted!", "User has been deleted!", "success");
          dispatch(GetuserAction());
        } else {
          throw new Error("Failed to delete user");
        }
      } catch (error) {
        Swal("Error", "Failed to delete user", "error");
      }
    }
  };


  const handleSubmit = async () => {
    if (validateDates()) {
      setIsDialogOpen(false);
      try {
        const response = await fetch(`${BACKEND_API_URL}export-user-emails/?start_date=${startDate}&end_date=${endDate}`, {
          headers: {
            Authorization: `Bearer ${userDataToken?.token}`,
          },
        });

        if (response.status === 200) {
          const blob = await response.blob();
          saveAs(blob, "User.csv");
          setStartDate('')
          setEndDate('')
        } else {
        }
      } catch (error) {
      }
    }
  };

  const {
    userData: userDataToken,
  } = useSelector((state) => state.authReducer);

  const { userlist, success: userlistsucess } = useSelector(
    (state) => state.userlistreducer
  );

  useEffect(() => {
    dispatch(GetuserAction());
  }, []);

  useEffect(() => {
    let userData = [];

    if (userlist) {
      userlist.map((item, index) => {
        item.title = item.first_name;
        item.Lastname = item?.last_name;
        item.Phoneno = item?.phone;
        item.Action = (<>
          <div className="btncentre">
            <button onClick={() => handleDelete(item.id)} ><i class="fa fa-trash" aria-hidden="true"></i></button>
            <button onClick={() => handleClickOpen(item.id)} ><i class="fa fa-edit" aria-hidden="true"></i></button>
          </div>

        </>
        );
        userData.push(item);
      });
    }
    setUsersForRender(userData);
  }, [userlist]);



  const data1 = {
    columns: [
      {
        label: "First Name",
        field: "title",
        sort: "asc",
        width: 500,
      },
      {
        label: "Last Name",
        field: "Lastname",
        sort: "asc",
        width: 500,
      },
      {
        label: "Phone No",
        field: "Phoneno",
        sort: "asc",
        width: 500,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 500,
      },
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 500,
      },

    ],
    rows: usersForRender,
  };

  const HandlerDowloadCsv = () => {
    setIsDialogOpen(true)
  };

  const handleSubmituser = async () => {
    setIsDialogOpen1(false);
    const formData = new FormData();
    formData.append('action', status);
    formData.append('user_id', userid);
    try {
      const response = await fetch(
        `${BACKEND_API_URL}users-info/`,
        {
          method: 'POST', // Assuming you're using DELETE method for deletion
          headers: {
            Authorization: `Bearer ${userDataToken?.token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        Swal("Success!", "Status of User Has Been Changed", "success");
        dispatch(GetuserAction());
      } else {
        throw new Error("Failed to Update user");
      }
    } catch (error) {
      Swal("Error", "Failed to Update user", "error");
    }
  };


  return (
    <>
      <div className="main-center">
        <h1 className="componentheading">User List</h1>
        <div className="center-section">
          <div>
            <div className="exportUserList">
              <button className="AddCompbutton" onClick={() => HandlerDowloadCsv()}>Export</button>
            </div>
            <MDBDataTable
              style={{}}
              responsive
              striped
              bordered
              small
              data={data1}
            />
          </div>
        </div>
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
          <DialogTitle>Select Date Range</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the start and end dates for the CSV download.
            </DialogContentText>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              autoFocus
              margin="dense"
              id="start-date"
              label="Start Date"
              type="date"
              fullWidth
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              id="end-date"
              label="End Date"
              type="date"
              fullWidth
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} color="primary">
              Download
            </Button>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                setStartDate('');
                setEndDate('');
              }}
              color="secondary"
            >
              Cancel
            </Button>

          </DialogActions>
        </Dialog>
        <Dialog open={isDialogOpen1} onClose={handleClose1}>
          <DialogTitle>Select Status</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the status from the dropdown below.
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Cancel</Button>
            <Button onClick={handleSubmituser}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Userlist;
