import React from "react";
import { Link } from "react-router-dom";
import Data from "./Data.json";

const Blogs = () => {
  return (
    <>
      <div>
        <div className="flex items-center justify-center text-center">
          <div className="flex flex-col p-2 m-2  max-w-7xl ">
            <div className="text-xl md:text-3xl mb-5 font-medium text-[#cead51]">
              Blogs
            </div>
            <div className="grid grid-cols-1 gap-4">
              {Data?.map((item) => (
                <div className="blog_parent gap-5 blog_parent-Div" key={item?.id}>
                  <div className="h-100 blog_parent-Divflex1 rounded-2xl">
                    <img
                      src={item?.image}
                      alt=""
                      className="h-auto blog_img w-full"
                    />
                  </div>
                  <div className="blogs_content_section blog_parent-Divflex2">
                    <Link to={`/blog/${item.slug}`}>
                      <h2 className="blogs_title mb-3 text-stone-600 cursor-pointer">
                        {item?.title}
                      </h2>
                    </Link>
                    <p
                      className="blog_text mb-3 text-stone-600 cursor-pointer"
                      dangerouslySetInnerHTML={{
                        __html: item?.shortdescription,
                      }}
                    />

                    <p className="text-stone-600 cursor-pointer mb-3 blog_date">
                      {item?.created_at}
                    </p>

                    <div>
                      <Link to={`/blog/${item.slug}`}>
                        <button className=" read_more_button mt-3">
                          Read More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <script src="https://cdn.tailwindcss.com"></script>
      </div>
    </>
  );
};

export default Blogs;
