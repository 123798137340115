import React from "react";
import {  useSelector } from "react-redux";

const TestFile = () => {
  const {
    userData,
  } = useSelector((state) => state.authReducer);

  return (
    <>
      <div className="main-center">
        <div className="center-section">
          <div>
            <h2 className="flex justify-center m-auto">
              Welcome {userData?.user?.first_name} {userData?.user?.last_name}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestFile;
