import React, { useEffect } from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";

const Thankyou = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === "/thank-you") {
      import("../../custom-tailwind.css");
    }
  }, [pathname]);

  return (
    <>
      <div className="flex h-screen w-full items-center justify-center login">
        <div className="w-1/2 bg-white px-16 py-10 shadow-2xl  backdrop-blur-md max-sm:px-8 login_form">
          <div className="text-blue-800 text-center">
            <div className="flex flex-col items-center">
              <img src="/images/logonew.png" width="150" alt="" srcSet="" />
            </div>

            <h1 className="thanku_page">
              Thank you for registering yourself at LUX499. An email has been
              sent to your registered email Id. Kindly verify the email to login
              and start using our services
            </h1>
            <div className="mt-8 mb-4 flex justify-center text-lg text-black login_main">
              <Link to="/login">
                <button
                  type="submit"
                  className="rounded-3xl btn-block bg-yellow-400 bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600 thanku_login_button"
                >
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
