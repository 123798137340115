import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Validations } from "../../utils";
import { login } from "../../Redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (pathname === "/login") {
      import("../../custom-tailwind.css");
    }
  }, []);

  const {
    error: userDataError,
    userData,
  } = useSelector((state) => state.authReducer);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const [error, setError] = useState({
    userNameError: null,
    passwordError: null,
  });

  useEffect(() => {
    if (userData && render) {
      swal({
        title: "Successfully Complete",
        text: "Login Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      setTimeout(() => {
        if (userData?.user?.role === 0) {
          navigate("/dashboard");
        } else {
          navigate("/my-profile");
        }
      }, 1500);
      setRender(false);
    }
    if (userDataError && render) {
      swal({
        title: "Error",
        text: userDataError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 1500,
      });
      setIsLoading(false);
      setRender(false);
    }
  }, [dispatch, userData, userDataError]);

  const validateSubmit = (e) => {
    e.preventDefault();

    const tempError = {
      userNameError: Validations.emailRequired(username),
      passwordError: Validations.password(password),
    };
    setError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    submitHandler(e);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setRender(true);
    await dispatch(login({ email: username, password }));
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="flex w-full items-center justify-center login">
            <div className="bg-white px-16 py-10 shadow-2xl login_form  backdrop-blur-md max-sm:px-8">
              <div className="text-white">
                <div className="mb-6 flex flex-col items-center">
                  <Link to="/">
                    <img
                      className="login_main_logo"
                      src="/images/logonew.png"
                      width="150"
                      alt=""
                      srcSet=""
                    />
                  </Link>
                  <span className="text-black mt-2">Enter Login Details</span>
                </div>
                <form onSubmit={validateSubmit}>
                  <div className="grid login_inputp text-lg text-black">
                    <input
                      className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                      type="emai"
                      name="name"
                      placeholder="E-mail"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        setError({ ...error, userNameError: null });
                      }}
                    />

                    <span
                      style={{
                        color: "#D14F4F",
                        textAlign: "end",
                        fontSize: "14px",
                        opacity: error.userNameError ? 1 : 0,
                      }}
                    >
                      {error.userNameError ?? "valid"}
                    </span>
                  </div>

                  <div className="grid login_inputp text-lg text-black">
                    <input
                      className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                      type="Password"
                      name="name"
                      placeholder="*********"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError({ ...error, passwordError: null });
                      }}
                    />

                    <span
                      style={{
                        color: "#D14F4F",
                        textAlign: "end",
                        fontSize: "14px",
                        opacity: error.passwordError ? 1 : 0,
                      }}
                    >
                      {error.passwordError ?? "valid"}
                    </span>
                  </div>
                  <Link to="/forgot-password">
                    <span className="text-sm ml-2 text-black hover:text-blue-500 cursor-pointer">
                      Forgot Password ?
                    </span>
                  </Link>
                  <div className="mt-2 mb-3 mb-md-4 flex justify-center text-lg text-black">
                    <button
                      type="submit"
                      className="rounded-3xl  w-80 bg-yellow-400 bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600"
                    >
                      Login
                    </button>
                  </div>
                  <div className="">
                    <Link to="/register">
                      <button
                        type="submit"
                        className="rounded-3xl  bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600 create_btn_login"
                      >
                        Create Account
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
