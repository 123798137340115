export const username = (value) => {
  if (!value) return "Username is required";
  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";
  // if (value > 20) return "You can enter only 20 characters";
  return null;
};

export const fullname = (value) => {
  if (!value) return "Full name is required";
  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";
  // if (value > 20) return "You can enter only 20 characters";
  return null;
};

export const firstName = (value) => {
  if (!value) return "First name is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";

  return null;
};

export const doorNumberIs = (value) => {
  if (!value) return "Door number is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";

  return null;
};

export const isValidZipCode = (value) => {
  if (!value) {
    console.log("Debug: Empty value detected");
    return "Postcode is required";
  }

  // New regular expression for UK postcodes
  // const ukPostcodeRegex = /^(?!.*(ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA|KY\d|MSR|VG|AI|GE\s?CX|GIR\s?0A{2}|SAN\s?TA1))(([A-Z]{1,2}[0-9][A-Z0-9]?|[A-Z]{2}\d|\d[A-Z]{2}|[A-Z]\d|\d[A-Z])\s?\d[A-Z]{2})$/;

  const ukPostcodeRegex =
    /^(?!.*(ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA|KY\d|MSR|VG|AI|GE\s?CX|GIR\s?0A{2}|SAN\s?TA1))(([A-Za-z]{1,2}[0-9][A-Za-z0-9]?|[A-Za-z]{2}\d|\d[A-Za-z]{2}|[A-Za-z]\d|\d[A-Za-z])\s?\d[A-Za-z]{2})$/i;

  if (!ukPostcodeRegex.test(value.trim())) {
    console.log("Debug: Invalid format detected");
    return "Postcode is not valid";
  }

  return null;
};

export const lastName = (value) => {
  if (!value) return "Last name is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "Last name can only contain characters ";

  return null;
};

export const email = (value) => {
  if (!value) return "Email is required";

  if (
    !value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
    return "Email is not valid";

  return null;
};

export const password = (value) => {
  if (!value) return "Password is required";

  if (value.length < 7) return "Atleast 7 characters Required";

  return null;
};

export const confirmPassword = (password, confirmPassword) => {
  if (!password) return "Confirm password is required";

  if (password !== confirmPassword) return "Passwords do not match";

  return null;
};

export const emailRequired = (value) => {
  if (!value) return "Username/Email is required";
  return null;
};

export const AddressRequired = (value) => {
  if (!value) return "Address is required";
  return null;
};
export const passwordRequired = (value) => {
  if (!value) return "Password is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";

  return null;
};

export const phoneNumber = (value) => {
  if (!value) return "Phone number is required";

  const sanitizedValue = value.replace(/\s/g, "");

  const ukPhoneNumberRegex =
    /^(\+44\d{10}|\b0\d{10}\d{6}|\+44 \d{5} \d{6}|\b0\d{5} \d{6}|07\d{9})$/;

  console.log("Sanitized Value:", sanitizedValue);

  if (!sanitizedValue.match(ukPhoneNumberRegex))
    return "Phone number is not valid";

  return null;
};
