import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import ReactPlayer from "react-player";
import LazyLoad from "react-lazyload";
import { Link, useNavigate } from "react-router-dom";
import { GetWinnersAction } from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ShowSliderImageHomeAction,
  GetHomePageContentAction,
} from "../../Redux/Actions/AdminAction";
import { GetcHomeIsFeaturedAction } from "../../Redux/Actions/PublicActions";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const Dashboard = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [competitionImage, setCompetitionImage] = useState([]);
  const [homeContent, setHomeContent] = useState();
  const [showMore, setShowMore] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    dispatch(GetWinnersAction());
  }, [dispatch]);

  const parseContent = () => {
    let contentParts;
    if (window.innerWidth < 768) {
      contentParts = homeContent?.homepage_content.split("MobileResponsive");
    } else {
      contentParts = homeContent?.homepage_content.split("[ReadMore]");
    }

    if (!showMore && contentParts && contentParts.length > 0) {
      const firstPart = contentParts[0];
      if (firstPart.includes("MobileResponsive")) {
        return firstPart
          .replace("MobileResponsive", "")
          .replace("[ReadMore]", "");
      }
    }

    return showMore
      ? contentParts.join("").replace(/\[ReadMore\]|MobileResponsive/g, "")
      : contentParts?.[0];
  };

  const { getIsFeatured } = useSelector(
    (state) => state.GetcHomeIsFeaturedReducer
  );

  const { GetHomeContentData } = useSelector(
    (state) => state.GetHomePageContentReducer
  );

  useEffect(() => {
    if (GetHomeContentData) {
      setHomeContent(GetHomeContentData?.[0]);
    }
  }, [GetHomeContentData]);

  useEffect(() => {
    dispatch(ShowSliderImageHomeAction());
    dispatch(GetHomePageContentAction());
    dispatch(GetcHomeIsFeaturedAction());
  }, [dispatch]);

  const { SliderShowImageHome, success: SliderShowImageSuccess } = useSelector(
    (state) => state.ShowSliderImageHomeReducer
  );

  useEffect(() => {
    if (SliderShowImageHome) {
      setCompetitionImage(SliderShowImageHome);
    }
  }, [SliderShowImageSuccess]);

  const images = competitionImage.map((item) => ({
    original: item?.slider_image,
    title: item?.title || "",
    link: item?.link || "#",
  }));

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const haldeClickShow = () => {
    if (currentSlide == 2) {
      localStorage.setItem("Redirect_id", "eightPointThree");
    }
  };

  const HandleShowDate = ({ endDate }) => {
    const [timeDiff, setTimeDiff] = useState(
      new Date(endDate).getTime() - new Date().getTime()
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeDiff((prevTimeDiff) => {
          let timeVar = prevTimeDiff;
          const days = Math.floor(timeVar / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeVar % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeVar % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeVar % (1000 * 60)) / 1000);

          if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
            dispatch(GetcHomeIsFeaturedAction());
          }
          return prevTimeDiff - 1000;
        });
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return (
      <div className="competitonTockent flex gap-2 gap-lg-5 mb-3 marg4444">
        <div>
          <h1>{days}</h1>
          <p>Day{days > 1 ? "s" : ""}</p>
        </div>
        <div>
          <h1>{hours}</h1>
          <p>Hr{hours > 1 ? "s" : ""}</p>
        </div>
        <div>
          <h1>{minutes}</h1>
          <p>Min{minutes > 1 ? "s" : ""}</p>
        </div>
        <div>
          <h1>{seconds}</h1>
          <p>Sec{seconds > 1 ? "s" : ""}</p>
        </div>
      </div>
    );
  };

  const handleEnterNow = (idValue) => {
    localStorage.setItem("slidervalue", 25);
    const array2 = { sliderValue: 25 };
    localStorage.setItem("competitondetailid", JSON.stringify(array2));
    navigate(`/competition-deatils/${idValue}`);
  };

  const customStyles = {
    galleryAction: {
      display: "none", // This will hide the open and play buttons
    },
  };

  const handleClickRedirect = () => {
    localStorage.setItem("Redirect_id", "eightPointThree");
    setTimeout(() => {
      navigate("/terms-to-play");
    }, 500);
  };

  const [isMobileVideoPlaying, setIsMobileVideoPlaying] = useState(true);
  const [isDesktopVideoPlaying, setIsDesktopVideoPlaying] = useState(true);

  const handleMobileVideoPlay = () => {
    setIsMobileVideoPlaying(true);
  };

  const handleMobileVideoPause = () => {
    setIsMobileVideoPlaying(false);
  };

  const handleDesktopVideoPlay = () => {
    setIsDesktopVideoPlaying(true);
  };

  const handleDesktopVideoPause = () => {
    setIsDesktopVideoPlaying(false);
  };

  const isVideo = (url) => {
    const videoExtensions = ['mp4', 'webm', 'ogg'];
    const extension = url.split('.').pop().split('?')[0]; // To handle query parameters
    return videoExtensions.includes(extension);
  };



  return (
    <>
      <div className="homesection">
        <div className="banner_video">
          {homeContent?.mobile_video && (
            <LazyLoad height={200} offset={100} once>
              {isVideo(homeContent.mobile_video) ? (
                <ReactPlayer
                  url={homeContent.mobile_video}
                  type="video/mp4"
                  controls={false}
                  playing={isMobileVideoPlaying}
                  loop={true}
                  muted={true}
                  width="100%"
                  height="85vh"
                  playsinline={true}
                  onPlay={handleMobileVideoPlay}
                  onPause={handleMobileVideoPause}
                  className="mobileVideo"
                />
              ) : (
                <img
                  src={homeContent.mobile_video}
                  alt="Mobile content"
                  width="100%"
                  height="85vh"
                  className="mobileVideo"
                />
              )}
            </LazyLoad>
          )}
          {homeContent?.background_video && (
            <LazyLoad height={200} offset={100} once>
              {isVideo(homeContent.background_video) ? (
                <ReactPlayer
                  url={homeContent.background_video}
                  type="video/mp4"
                  controls={false}
                  playing={isDesktopVideoPlaying}
                  loop={true}
                  muted={true}
                  width="100%"
                  height="100vh"
                  playsinline={true}
                  onPlay={handleDesktopVideoPlay}
                  onPause={handleDesktopVideoPause}
                  className="desktopVideo"
                />
              ) : (

                <img
                  src={homeContent.background_video}
                  alt="Background content"
                  width="100%"
                  height="100vh"
                  className="desktopVideo"
                />
              )}
            </LazyLoad>
          )}

        </div>
        <div className="homeimageContent">
          <h1 className="tournament-contnet">
            <span className="banner_heading">HOME FOR NO.1</span>{" "}
            {homeContent?.header_title}
          </h1>
          {homeContent?.link && (
            <div className="main-enter-btn">
              <Link to={homeContent?.link}>
                <button className="enternow-btn">
                  Enter Now{" "}
                  <svg
                    className="h-4 w-4 imgcolorsec"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {" "}
                    <polyline points="11 17 6 12 11 7" />{" "}
                    <polyline points="18 17 13 12 18 7" />
                  </svg>
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="Featured">
        <div className="container">
          <h1 className="text-center Featuredtitle">
            OUR <span>Featured COMPETITION</span>
          </h1>
          <div className="Featuredslider">
            {getIsFeatured?.length == 0 ? (
              <h1 className="titlefount">No Current Competitions</h1>
            ) : (
              <>
                {getIsFeatured?.slice(0, 6)?.map((itemIsFeatured, index) => {
                  const sliderImgs = itemIsFeatured.competition_media?.map(
                    (innerItem) => ({ original: innerItem.image })
                  );
                  return (
                    <div
                      className="Competition_new_new"
                      key={itemIsFeatured?.id}
                    >
                      {new Date(itemIsFeatured?.end_date).getTime() -
                        new Date().getTime() >
                        0 ? (
                        <div>
                          <div
                            className="Competition_img"
                            onClick={(e) => handleEnterNow(itemIsFeatured.id)}
                          >
                            <ImageGallery
                              items={sliderImgs}
                              additionalClass="my-gallery" // You can add an additional className for more specific targeting
                              showPlayButton={false} // This will hide the play button
                              showFullscreenButton={false} // This will hide the fullscreen button
                              styles={customStyles} // Apply custom styles
                              autoPlay={true} // Enable autoplay
                              slideInterval={3000} // Set the interval between slides (in milliseconds)
                              lazyLoad={true} // Adding lazyLoad prop to enable lazy loading
                            />

                            <div className="bacgrounddiv"></div>
                          </div>
                          <div className="nametime">
                            <div className="name_title">
                              <h2>
                                {" "}
                                {itemIsFeatured?.title
                                  ? itemIsFeatured.title.length > 30
                                    ? itemIsFeatured.title.slice(0, 10) + "..."
                                    : itemIsFeatured.title
                                  : ""}
                              </h2>
                            </div>
                            <div className="timesec">
                              <div className="perentry">
                                <HandleShowDate
                                  endDate={itemIsFeatured?.end_date}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="buytrick">
                            <div
                              className="buttoncompetitionsection1 flex justify-center"
                              onClick={(e) => handleEnterNow(itemIsFeatured.id)}
                            >
                              <button className="competitonsButton1">
                                <img src="/images/buybtn.png" alt="buybtn" />
                              </button>
                            </div>
                            <button></button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="homeBanner-main" onClick={() => handleClickRedirect()}>
        <img
          src="/images/img_1.png"
          alt="Banner"
          className="banner-image-sections"
        />
      </div>

      <section className="who_we_are_Section1">
        <div className="container mx-auto sm:py-[12px] videcontentshwo">
          <div className="grid md:grid-cols-2 sm:grid-cols-1">
            <div className="mb-4 mb-md-0 videoparagraph">
              <h2 className="title4">
                Lux499 is the ultimate
                <br />
                destination for all your
                <br />
                <span className="surgery">cosmetic surgery</span> dreams!
              </h2>
              <div dangerouslySetInnerHTML={{ __html: parseContent() }} />
            </div>
            <div className="videosection">
              <LazyLoad height={200} offset={100} once>
                <ReactPlayer
                  url={homeContent?.homepage_content_video}
                  type="video/mp4"
                  controls={false} // Hide default controls
                  playing={true} // Autoplay
                  loop={true}
                  muted={true} // Muted for autoplay
                  width="100%"
                  height="auto"
                  playsinline={true}
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>

      <div className="slideHomeSection newbackgrundimg">
        <div className="slide-container">
          {competitionImage.length > 0 && (
            <div className="doctor_img_slider">
              <ImageGallery
                items={images}
                additionalClass="my-gallery" // You can add an additional className for more specific targeting
                showPlayButton={false} // This will hide the play button
                showFullscreenButton={false} // This will hide the fullscreen button
                styles={customStyles} // Apply custom styles
                autoPlay={true} // Enable autoplay
                slideInterval={5000} // Set the interval between slides (in milliseconds)
                onSlide={(index) => handleSlideChange(index)}
                lazyLoad={true} // Adding lazyLoad prop to enable lazy loading
              />
            </div>
          )}

          {images[currentSlide]?.link && (
            <>
              <div onClick={() => haldeClickShow()}>
                <Link to={images[currentSlide].link} target="_blank">
                  <div className="aestheticChild2">
                    <h1 className="aesthetic-contnet">
                      {images[currentSlide].title}
                    </h1>
                    <div className="main-enter-btn2"></div>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="legmain-diuv">
        <div
          className="legssection"
          style={{
            backgroundImage: `url(${homeContent?.footer_image})`,
            backgroundRepeat: "no-repeat",
            display: "flex",
            backgroundSize: "100% 100%",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            fontSize: "24px",
            height: "356px",
          }}
        >
          <div>
            <div
              className="main-leglistday2"
              dangerouslySetInnerHTML={{ __html: homeContent?.footer_content }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
