import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";


const HowToPlay = () => {
  let navigate = useNavigate();

  const handleRedirectFun = (e) => {
    e.preventDefault();
    localStorage.setItem("Redirect_id", "eightPointThree");
    setTimeout(() => {
      navigate("/terms-to-play");
    }, 500); 
  };

  return (
    <>
      <div className="top">
        <video
          src="/Video/HOW TO PLAY PAGE.mp4"
          controls={false}
          autoPlay
          loop
          muted
          playsInline
          width="100%"
          height="90vh"
        />
        <div className="how_to_play_bg">
          <div className="container mx-auto md:px-[50px] ">
            <div className="headingPolicy">
              <p className="text-white">
                We conduct a live drawing event on our Instagram channel
                <b> (@lux499competitions) </b> where you can join us to witness
                the drawing process and the random selection of the winner. To
                ensure fairness, we employ the Google Random Number Generator,
                demonstrating its use to ensure transparency in the selection
                process. Additionally, we actively engage with our audience
                during the live broadcast to confirm that each draw is indeed
                happening in real-time.
              </p>
              <p className="text-white">
                <b>For Great Britain Entrant's Only!</b>
              </p>
            </div>

            <section className="home-section6 margin-cls">
              <Accordion className="accordian-width">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="question">
                    <h1 className="policyHeading">HOW TO PLAY</h1>
                  </Accordion.Header>
                  <Accordion.Body className="answer">
                    <h2 className="title-5 mb-0 pt-2">
                      {" "}
                      Here's a simplified version of the steps to participate in
                      our online competitions
                    </h2>
                    <p className="paragraphnormal text-7">
                      <ul className="how_to_play">
                        <li>
                          <h2 className="title-5">Create an Account </h2> Sign
                          up for a free account by clicking the "Login/Register"
                          link and providing your details. Please note that you
                          must be a UK resident and at least 18 years old to
                          join. We verify your ID and address to ensure
                          eligibility.
                        </li>
                        <li>
                          <h2 className="title-5">Select Your Competitions</h2>{" "}
                          Explore our website to find ongoing competitions.
                          Click on each competition to learn about the prize,
                          available entries, end date, and entry restrictions.
                        </li>
                        <li>
                          <h2 className="title-5">Answer a Question</h2> To
                          enter the competition and have a chance to win the
                          prize, you must correctly answer a multiple-choice
                          question. Each competition has its own question, so be
                          sure to answer it accurately. Incorrect answers still
                          incur a charge but won't enter you into the draw.
                        </li>
                        <li>
                          <h2 className="title-5">Complete Your Purchase</h2>{" "}
                          After answering the question, choose the number of
                          entries you want and follow the checkout process.
                          You'll receive an email confirmation with your ticket
                          numbers, which can also be found in your account.
                          Check that the status shows as "Processing" or
                          "Completed" to ensure your order was successful.
                        </li>
                        <li>
                          <h2 className="title-5">Wait for the Live Draw</h2>{" "}
                          Once all available entries are sold out or the
                          competition ends, we'll announce the date and time of
                          the Live Draw. This draw takes place on our Facebook
                          channel, and the winner is randomly selected using the
                          Google Random Number Generator from all correct
                          entries.
                        </li>
                      </ul>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header className="question">
                    <h1 className="policyHeading">FREE POSTAL ENTRIES</h1>
                  </Accordion.Header>
                  <Accordion.Body className="answer">
                    <p
                      className="paragraphnormal text-7 show-link-data-how"
                      onClick={(e) => handleRedirectFun(e)}
                    >
                      For a free postal entry, see <u>here</u>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header className="question">
                    <h2 className="title-5 M-title">DRAW DATES</h2>
                  </Accordion.Header>
                  <Accordion.Body className="answer">
                    <p className="paragraphnormal text-7">
                      We always let you know the scheduled date for each
                      competition's draw. You can find this information on the
                      competition entry page, just below the total number of
                      available tickets. If a competition sells out all its
                      tickets before the initially planned draw date, we'll move
                      the live draw to an earlier date and announce this new
                      date.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header className="question">
                    <h2 className="title-5 M-title">
                      {/* Is UK only play allowed? */}
                      WHO CAN ENTER?
                    </h2>
                  </Accordion.Header>
                  <Accordion.Body className="answer">
                    <p className="paragraphnormal text-7">
                      {/* YES! */}
                      Great british residents only
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header className="question">
                    <h2 className="title-5 M-title">MINIMUM AGE TO PLAY</h2>
                  </Accordion.Header>
                  <Accordion.Body className="answer">
                    <p className="paragraphnormal text-7">
                      If you're under 18 or not living in the UK when you enter,
                      please refrain from participating because your entry won't
                      be considered, and we won't be able to offer a refund. For
                      all the details on who can participate, check our terms
                      and conditions.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header className="question">
                    <h2 className="title-5 M-title">THE INSTAGRAM LIVE DRAW</h2>
                  </Accordion.Header>
                  <Accordion.Body className="answer">
                    <p className="paragraphnormal text-7">
                      Let's take an example: A competition has 6,000 available
                      entries. When you enter this competition, your ticket
                      number is assigned entirely by chance. This means your
                      number could be anywhere between 1 and 6,000, regardless
                      of when you enter. For instance, you might be the first to
                      enter but end up with a high number like 6,000, or you
                      could be among the last to enter and get a low number like
                      8 if it's still available. Whether the competition sells
                      out completely or some ticket numbers remain unsold, all
                      numbers from 1 to 6,000 are entered into a random number
                      generator. Then, the system generates a random number, and
                      we check it against all the assigned ticket numbers in
                      real-time. If the generated number matches an
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header className="question">
                    <h2 className="title-5 M-title">HOW TO ENTER</h2>
                  </Accordion.Header>
                  <Accordion.Body className="answer">
                    <p>
                      You can enter this promotion in any of the following ways:
                    </p>
                    <ol className="marginu77">
                      <li>
                        <table className="table_term_of_play">
                          <tbody>
                            <tr>
                              <th style={{ borderRight: "2px solid #000" }}>
                                Online
                              </th>
                              <td>
                                <p>
                                  Complete the entry steps online on our website
                                  at LUX499 The cost of entry shall be displayed
                                  on our Website.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <th>Free Postal Entry Available</th>
                              <td>
                                <p>
                                  You can enter by post, but you will first have
                                  to register an account with us (see clause 4.1
                                  below).
                                </p>
                                <p id="eightPointThree">
                                  Please send a postcard your with your name,
                                  LUX499 Competitions Ltd account number, postal
                                  address, date of birth, email address and
                                  telephone number and the name of the
                                  competition you are entering to 124 City Road,
                                  London, United Kingdom, EC1V 2NX.
                                </p>
                                <p>
                                  Valid free entries will be found within the
                                  history of your account.
                                </p>
                                <p>One entry per postcard to each Draw</p>
                                <p>
                                  Postal entries must be received by the closing
                                  date and time shown on each Draw in order for
                                  it to be processed before the Draw. Postal
                                  entries received after the closing date and
                                  time will not be entered into the Draw.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="paragraphnormal text-7">
                          4.1. In order to enter a Draw, you will need to create
                          an account with us via our Website. Please follow the
                          on-screen instructions. You must provide us with your
                          name and contact details which must include your email
                          and postal address. It is really important that these
                          details are correct, accurate and up to date so that
                          we can contact you about the Draw if we need to. We
                          cannot be responsible or liable to you this regard if
                          you have provided us with inaccurate information.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.2. You will create a user name and password for your
                          account. It is your responsibility to keep these
                          details safe and secure and to not choose a password
                          which can be easily guessed, and we are neither
                          responsible nor liable to you in this regard. If you
                          believe that somebody else is using your account
                          please contact us.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.3. Once you have paid for each entry you will then
                          receive an email to confirm your entry into the Draw
                          together with your Draw numbers.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.4. If you are entering by Post, we will allocate a
                          randomly selected available Draw number to you.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.5. Please note that when entering the Draw either
                          Online and/or by Post you will not have been deemed to
                          have entered the Draw until we have confirmed your
                          entry into the Draw by emailing you and by
                          confirmation to your account by logging into your
                          account and checking under the “My Account” section.
                          You will then be asked to enter your date of birth to
                          confirm that you are over 18 years of age and that you
                          have read and accepted these Terms and our Privacy
                          Policy
                        </p>
                        <p className="paragraphnormal text-7">
                          4.6. We reserve the right to refuse or disqualify your
                          entry if we have reasonable grounds to believe that
                          you have acted in breach of these Terms, and you shall
                          be liable for the return and/or reimbursement of all
                          and any prizes (as defined below) to us.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.7. We reserve the right to reject entries that are
                          unlawful, indecent, racist, inflammatory, defamatory
                          or which we consider to be otherwise harmful. We also
                          have the right to suspend or cancel your account.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.8. We accept no responsibility for any late, lost or
                          misdirected entries including but not limited to
                          entries not received due to technical disruptions,
                          network congestion, loss in service of online entry
                          mechanisms, computer error in transit, delay in postal
                          services or any other reason
                        </p>
                        <p className="paragraphnormal text-7">
                          .4.9. There will only be one Draw operating at any one
                          time for each competition.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.10. The Draw will close when the closing date with
                          the count down clock has ended.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.11. The Prize for each Draw (“Prize”) will be
                          displayed on our Website.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.12. There will only be one winner chosen for each
                          Draw.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.13. You must create your online account and only
                          enter the Draw on your own behalf. You are not allowed
                          to enter a Draw on behalf of anyone else.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.14. You can enter a maximum number of times as
                          displayed in the description section under the
                          relevant competition.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.15. Where the Prize amounts to circumstances where
                          we agree to pay for a Winner’s cosmetic procedure the
                          additional rules of eligibility will apply:
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.1. The Winner shall be responsible for arranging
                          and booking the cosmetic procedure at their chosen
                          location within the United Kingdom and it shall be
                          their responsibility to make the appropriate enquiries
                          as to the qualification and suitability of their
                          chosen provider of the cosmetic procedure and we will
                          not carry out these checks on your behalf.
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.2. We make no representations, nor do we provide
                          any warranties about the Prize, its value, or in
                          relation to any other information provided on our
                          Website regarding the Prize.
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.3. We will pay for the cost of the Winner’s
                          cosmetic procedure only up to the value as expressly
                          described in the description section under the
                          relevant competition that you are entering in relation
                          to that Prize. This payment will be transferred to the
                          Winner’s bank account within 7 working days of you
                          booking the cosmetic procedure upon us receiving
                          written confirmation from your chosen provider
                          confirming that the cosmetic procedure has been
                          booked. If a Winner decides to cancel their cosmetic
                          procedure after we have transferred this payment to
                          their bank account the Winner may keep this cash
                          payment as a cash prize alternative instead of using
                          this cash payment to pay for the cost of the Winner’s
                          cosmetic procedure. The Winner is responsible for any
                          cancellation fees payable to their chosen location
                          within the United Kingdom for the cancellation of the
                          procedure.
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.4. The Winner is responsible for arranging (and
                          bearing the costs of) any element of the cosmetic
                          procedure that is not expressly described in the
                          description section under the relevant competition
                          that you are entering including (but not limited to):
                        </p>
                        <p className="paragraphnormal text-7 ml-6">
                          4.15.4.1. transfers and travel;
                        </p>
                        <p className="paragraphnormal text-7 ml-6">
                          4.15.4.2. accommodation;
                        </p>
                        <p className="paragraphnormal text-7 ml-6">
                          4.15.4.3. insurance and other travel documentation;
                        </p>

                        <p className="paragraphnormal text-7 ml-6">
                          4.15.4.4. car hire; and
                        </p>
                        <p className="paragraphnormal text-7 ml-6">
                          4.15.4.5. food and drink.
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.5. The Winner must ensure that they are not
                          subject to any restriction which may impact their
                          ability to travel.
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.6. If you have any accessibility requirements,
                          please inform us as soon as possible.
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.7. The Prize will be subject to additional terms
                          and conditions imposed by your chosen provider. The
                          Winner must review and comply with these terms and
                          conditions, and we shall have no responsibility or
                          liability to you in this regard.
                        </p>
                        <p className="paragraphnormal text-7 ml-4">
                          4.15.8. If necessary due to circumstances beyond its
                          control, we may (at our option) substitute the prize
                          for:
                        </p>
                        <p className="paragraphnormal text-7 ml-6">
                          4.15.8.1. a reasonable equivalent of equal or higher
                          value; or
                        </p>
                        <p className="paragraphnormal text-7 ml-6">
                          4.15.8.2. a cash alternative of the value of the
                          Prize.
                        </p>

                        <p className="paragraphnormal text-7">
                          4.15.9. The prize is for the named Winner only and
                          cannot be given or transferred to any other person.
                        </p>
                        <p className="paragraphnormal text-7">
                          4.15.10. Winners must attend initial consultations
                          with their chosen provider of the cosmetic procedure
                          within 6 months of the Winner being drawn, and
                          treatments must be redeemed within 12 months of the
                          Winner being drawn. Failure to adhere to these
                          timeframes will result in the disqualification of the
                          potential Winner for breaching these Terms and the
                          Winner will forfeit the prize and the Prize will be
                          re-drawn from the remaining entries in accordance with
                          clause 5.2 and 5.3.
                        </p>
                      </li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </section>

            <div className="image_text">
              <div className="man_image">
                <LazyLoad height={200} offset={100} once>
                  <img src="/images/AdobeStock_477892159.jpeg" alt="Lazy loaded image" />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToPlay;
