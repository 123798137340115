export const SUPPORT_SEND_SUCCESS = "SUPPORT_SEND_SUCCESS";
export const SUPPORT_SEND_REQUEST = "SUPPORT_SEND_REQUEST";
export const SUPPORT_SEND_FAILURE = "SUPPORT_SEND_FAILURE";

export const GET_HOME_cOMPETION_ISFEATURED_SUCCESS =
  "GET_HOME_cOMPETION_ISFEATURED_SUCCESS";
export const GET_HOME_cOMPETION_ISFEATURED_FAILURE =
  "GET_HOME_cOMPETION_ISFEATURED_FAILURE";
export const GET_HOME_cOMPETION_ISFEATURED_REQUEST =
  "GET_HOME_cOMPETION_ISFEATURED_REQUEST";

export const GET_COMPETITION_DETAILS_SUCCESS =
  "GET_COMPETITION_DETAILS_SUCCESS";
  export const GET_COMPETITION_DETAILS_REQUEST =
  "GET_COMPETITION_DETAILS_REQUEST";
  export const GET_COMPETITION_DETAILS_FAILURE =
  "GET_COMPETITION_DETAILS_FAILURE";

  export const UPDATE_PROFILE_USER_SUCCESS = "UPDATE_PROFILE_USER_SUCCESS";
  export const UPDATE_PROFILE_USER_REQUEST = "UPDATE_PROFILE_USER_REQUEST";
  export const UPDATE_PROFILE_USER_FAILURE = "UPDATE_PROFILE_USER_FAILURE";