import React, { useState, useEffect } from "react";
import { Validations } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { SupportSendAction } from "../../Redux/Actions/PublicActions";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";

const Support = () => {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState();
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    SupportSend,
    error: SupportSendError,
  } = useSelector((state) => state.SupportSendReducer);

  const [error, setError] = useState({
    fullNameError: null,
    emailError: null,
    phoneError: null,
    messageError: null,
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (SupportSend && render) {
      swal({
        title: "Success",
        text: "Email successfully send",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 3000,
      });

      setEmail("");
      setFullName("");
      setPhoneNumber("");
      setMessage("");
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      setRender(false);
    }
    if (SupportSendError && render) {
      swal({
        title: "Error",
        text: SupportSendError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRender(false);
    }
  }, [dispatch, SupportSend, SupportSendError]);

  const validationSubmit = (e) => {
    e.preventDefault();
    const tempError = {
      fullNameError: Validations.fullname(fullName),
      emailError: Validations.email(email),
      phoneError: Validations.phoneNumber(phoneNumber),
      messageError: !message && "Message is required",
    };
    setError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    HandleSubmit(e);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const fromData = new FormData();
    fromData.append("fullname", fullName);
    fromData.append("email", email);
    fromData.append("phonenumber", phoneNumber);
    fromData.append("message", message);
    setRender(true);
    setIsLoading(true);
    dispatch(SupportSendAction(fromData));
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="contactud">
            <div className="support_text">
              <h1 className="cookies_Heading">
                Support
              </h1>
            </div>
            <div className="container">
              <div className="contactform-1">
                <div className="contactform-left">
                  <form onSubmit={validationSubmit}>
                    <div className="inputsec">
                      <div className="iconsec">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="textinput">
                        <input
                          type="text"
                          id="fname"
                          name="firstname"
                          placeholder="Your name.."
                          value={fullName}
                          onChange={(e) => {
                            setFullName(e.target.value);
                            setError({ ...error, fullNameError: null });
                          }}
                        />
                      </div>
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: " flex",
                        justifyContent: "end",
                        opacity: error.fullNameError ? 1 : 0,
                      }}
                    >
                      {error?.fullNameError ?? "valid"}
                    </span>

                    <div className="inputsec">
                      <div className="iconsec">
                        <i className="far fa-envelope"></i>
                      </div>
                      <div className="textinput">
                        <input
                          type="text"
                          id="fname"
                          name="firstname"
                          placeholder="Email..."
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setError({ ...error, emailError: null });
                          }}
                        />
                      </div>
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: " flex",
                        justifyContent: "end",
                        opacity: error.emailError ? 1 : 0,
                      }}
                    >
                      {error?.emailError ?? "valid"}
                    </span>
                    <div className="inputsec">
                      <div className="iconsec">
                        <i className="fa fa-phone"></i>
                      </div>
                      <div className="textinput">
                        <input
                          type="tel"
                          id="fname"
                          name="firstname"
                          placeholder="Phone number"
                          value={phoneNumber}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                            setError({ ...error, phoneError: null });
                          }}
                        />
                      </div>
                    </div>
                    <span
                      style={{
                        color: "#D14F4F",
                        display: " flex",
                        justifyContent: "end",
                        opacity: error.phoneError ? 1 : 0,
                      }}
                    >
                      {error?.phoneError ?? "valid"}
                    </span>
                    <div className="textaressupport">
                      <textarea
                        id="subject"
                        name="subject"
                        placeholder="Write something.."
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setError({ ...error, messageError: null });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          display: " flex",
                          justifyContent: "end",
                          opacity: error.messageError ? 1 : 0,
                        }}
                      >
                        {error?.messageError ?? "valid"}
                      </span>
                    </div>
                    <input
                      className="btn-2 btnsupportbtn"
                      type="submit"
                      value="Submit"
                    />
                  </form>
                </div>
                <div className="contactform-right">
                  <img src="/images/AdobeStock_430052231.jpeg" alt="image"/>
                  <div className="address">
                    <h2 className="title-5 M-title address">
                      <span className="map pe-2 ">
                        <i className="fa-solid fa-location-dot"></i>
                      </span>
                      Lux Address - 124 City Road London EC1V 2NX
                    </h2>
                    <p>Please send postal entries to the above address.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Support;
