import React, { useEffect } from "react";
import { useSelector } from "react-redux";


import { useLocation, Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (pathname !== "/payment") {
      import("../../custom-tailwind.css");
    }
  }, [pathname]);

  const { userData } = useSelector((state) => state.authReducer);

  return userData?.user.role == 0 || userData?.user.role == 1 ? (
    children
  ) : (
    <Navigate to="/" />
  );
}
