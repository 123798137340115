import React, {  useEffect } from "react";
import { FRONTEND_URL } from "../../Environment";


const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="cookie_policy_main">
      <div className="container mx-auto md:px-[50px] sm:py-[12px] ">
        <h1 className="cookies_Heading">COOKIE POLICY</h1>
        <h1 className="cookies_scope">Scope of This Policy</h1>
        <p>
          1. Lux499 (we or us or our) uses cookies when you visit our website,
          {FRONTEND_URL}competitons, (the Website) to help customise
          the Website and improve your experience using the Website.
        </p>
        <p>
          2. This Policy applies between you, the user of this Website, and us,
          Lux499, the owner and provider of this Website.
        </p>
        <p>
          3. When you visit the Website, and before your Website places cookies
          on your computer, you will be presented with a message bar requesting
          your consent to set those cookies. By giving your consent to the
          placing of cookies, you are enabling us to provide a better experience
          and service. You may, if you wish, deny consent to the placing of
          these cookies; however, certain features of the Website may not
          function fully or as intended.
        </p>
        <p>
          4. This Cookie Policy should be read alongside, and in addition to,
          our Privacy Policy, which can be found at:
          {FRONTEND_URL}privacy-policy.
        </p>
        <h1 className="cookies_scope">What Are Cookies?</h1>
        <p>
          5. A cookie is a small text file placed on your computer by this
          Website when you visit certain parts of the Website and /or when you
          use certain features of the Website.
        </p>
        <p>
          6. This Website may place and access certain cookies on your computer.
          We use these cookies to improve your experience of using the Website
          and to improve our range of products and services.
        </p>
        <p>
          7. Cookies do not usually contain any information that personally
          identifies you, as the Website user. However, personal information
          that we store about you may be linked to the information obtained from
          and stored in cookies. For more information on how such personal
          information is handled and stored, refer to our Privacy Policy which
          is available online at:{FRONTEND_URL}privacy-policy.
        </p>
        <h1 className="cookies_scope">Types of Cookies</h1>
        <p>8. This Website uses the following cookies:</p>
        <div className="privacytexttable_Cookies">
          <div className="table-responsive_cookies">
            <table className="custom-table_cookies">
              <thead>
                <tr>
                  <th>Type of cookie</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Strictly necessary cookies</td>
                  <td>
                    These are cookies that are required for the operation of the
                    Website. They include, for example, cookies that enable you
                    to log into secure areas of the Website, use a shopping cart
                    or make use of e-billing services.
                  </td>
                </tr>
                <tr>
                  <td>Analytical/performance cookies</td>
                  <td>
                    These cookies allow us to recognise and count the number of
                    visitors and to see how visitors move around our Website
                    when they are using it. This helps us to improve the way our
                    Website works, for example, by ensuring that users are
                    finding what they are looking for easily.
                  </td>
                </tr>
                <tr>
                  <td>Functionality cookies</td>
                  <td>
                    These are used to recognise you when you return to our
                    Website. This enables us to personalise our content for you,
                    greet you by name and remember your preferences (for
                    example, your choice of language or region). By using the
                    Website, you agree to our placement of functionality
                    cookies.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p className="mt-8">
          9. You can find a list of the cookies that we use in the attached
          Cookie Schedule.
        </p>
        <p>
          10. We have carefully chosen these cookies and have taken steps to
          ensure that your privacy is protected and respected at all times.
        </p>
        <h1 className="cookies_scope">How To Control Your Cookies</h1>
        <p>
          11. You can choose to enable or disable cookies in your internet
          browser. By default, most internet browsers accept cookies but this
          can be changed. For further details, please see the help menu in your
          internet browser.
        </p>
        <p>
          12. You can switch off cookies at any time, however, you may lose
          information that enables you to access the Website more quickly and
          efficiently.
        </p>
        <p>
          13. It is recommended that you ensure that your internet browser is
          up-to-date and that you consult the help and guidance provided by the
          developer of your internet browser if you are unsure about adjusting
          your privacy settings.
        </p>
        <p>
          14. For more information generally on cookies, including how to
          disable them, please refer to aboutcookies.org. You will also find
          details on how to delete cookies from your computer.
        </p>
        <h1 className="cookies_scope">Changes To This Policy</h1>
        <p>
          15. Lux499 reserves the right to change this Cookie Policy as we may
          deem necessary from time to time or as may be required by law. Any
          changes will be immediately posted on the Website and you are deemed
          to have accepted the terms of the Cookie Policy on your first use of
          the Website following the alterations.
        </p>
        <h1 className="cookies_scope">Contact Details</h1>
        <p>
          16. The Website is owned by Lux499 incorporated in England and Wales
          with registered number 15157904 whose registered office is at 124 City
          Road, London, EC1V 2NX, England.
        </p>
        <p className="mb-2">17 .You may contact us:</p>
        <p className="ml-3"> a. using the contact form on the Website.</p>
        <h1 className="cookies_scope">Attribution</h1>
        <p>
          18. This Cookie Policy was created using a document from Rocket Lawyer
          (https://www.rocketlawyer.com/gb/en).
        </p>
        <p className="ml-3">This Policy was created on 26 October 2023.</p>
        <h1 className="cookies_schedule">COOKIE SCHEDULE</h1>
        <p>
          Below is a list of the cookies that we use. We have tried to ensure
          this is complete and up to date, but if you think that we have missed
          a cookie or there is any discrepancy, please let us know.
        </p>
        <p>Cookies that we use on the Website:</p>
        <h1 className="cookies_scope_table">Strictly Necessary Cookies</h1>
        <p className="mb-2">We use the following strictly necessary cookies:</p>
        <div className="privacytexttable_Cookies">
          <div className="table-responsive_cookies">
            <table className="custom-table_cookies">
              <thead>
                <tr>
                  <th className="tableheadingshowCookies">Name of cookie</th>
                  <th>Purpose of cookie</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>First-party Cookies</td>
                  <td>
                    We use these cookies to recognise users and the information
                    provided when they navigate through the Lux499 website. We
                    only retain information about a user's activities for as
                    long as they are on the website. Once the web browser is
                    closed, the cookies are deleted.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h1 className="cookies_scope_table">Functionality Cookies</h1>
        <p className="mb-2">We use the following functionality cookies:</p>
        <div className="privacytexttable_Cookies">
          <div className="table-responsive_cookies">
            <table className="custom-table_cookies">
              <thead>
                <tr>
                  <th className="tableheadingshowCookies">Name of cookie</th>
                  <th>Purpose of cookie</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Permanent Cookies</td>
                  <td>
                    We use this cookie so your session login details and
                    passwords can be remembered. This means, you don't need to
                    re-enter them every time they use the site. These details
                    are forgotten after 12 months.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h1 className="cookies_scope_table">Analytical/Performance Cookies</h1>
        <p className="mb-2">
          We use the following analytical/performance cookies:
        </p>
        <div className="privacytexttable_Cookies">
          <div className="table-responsive_cookies">
            <table className="custom-table_cookies">
              <thead>
                <tr>
                  <th className="tableheadingshowCookies">Name of cookie</th>
                  <th>Purpose of cookie</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>First-party Cookies</td>
                  <td>
                    First-party cookies are installed directly by Lux499. These
                    cookies enable Lux499 to collect analytics data, remember
                    language settings, and perform other useful functions that
                    provide a good user experience.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
