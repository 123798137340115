import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetuserAction } from "../../Redux/Actions/AdminAction";
import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import LoadingSpinner from "../LoadingSpinner";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { saveAs } from "file-saver";
import { BACKEND_API_URL } from "../../Environment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert';
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"


const Promolist = () => {
    const dispatch = useDispatch();
    const [usersForRender, setUsersForRender] = useState([]);
    const [validationError, setValidationError] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogOpen1, setIsDialogOpen1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState('');
    const [promoname, setpromoname] = useState('');
    const [discount, setdiscount] = useState();
    const [isActive, setIsActive] = useState(false);
    const [iseveryone, setiseveryone] = useState(false);
    const [checkedUsers, setCheckedUsers] = useState({});
    const [allpromo, setallpromo] = useState({});
    const [editid, seteditid] = useState("");
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (id) => {
        setCheckedUsers(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        const newCheckedUsers = {};
        userlist.forEach(item => {
            newCheckedUsers[item.id] = newSelectAll;
        });
        setCheckedUsers(newCheckedUsers);
    };


    const handleSubmituser = async () => {
        setIsLoading(true)
        setIsDialogOpen1(false);
        const selectedUserIds = Object.keys(checkedUsers).filter(id => checkedUsers[id]);

        // Remove userDataToken?.user.user_id from the selectedUserIds array
        const filteredUserIds = selectedUserIds.filter(id => id !== userDataToken?.user.user_id);

        // Convert the filtered array to a comma-separated string
        const userIdsString = filteredUserIds.join(',');

        console.log(userIdsString); // Output the comma-separated string

        const formData = new FormData();
        formData.append('promo_code', editid);
        formData.append('user', userIdsString); // Append the comma-separated string
        try {
            const response = await fetch(
                `${BACKEND_API_URL}assign-promo/`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userDataToken?.token}`,
                    },
                    body: formData,
                }
            );

            if (response.ok) {
                setIsLoading(false)
                Swal("Assigned!", "Promo has been Assigned to user!", "success");
                fetchData();
                setIsDialogOpen1(false);
            } else {
                setIsLoading(false)
                throw new Error("Failed to assign promo code");
                setIsDialogOpen1(false);
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error assigning promo code:', error);
            setIsDialogOpen1(false);
            Swal("Error", "Failed to assign promo code", "error");
        }
    };


    const handleDelete = async (userId) => {
        const confirmed = await Swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete this promo?",
            icon: "warning",
            dangerMode: true,
            buttons: true,
        });

        if (confirmed) {
            try {
                const response = await fetch(
                    `${BACKEND_API_URL}promo-codes/${userId}/`,
                    {
                        method: 'DELETE', // Assuming you're using DELETE method for deletion
                        headers: {
                            Authorization: `Bearer ${userDataToken?.token}`,
                        },
                    }
                );

                if (response.ok) {
                    Swal("Deleted!", "Promo has been deleted!", "success");
                    fetchData()
                } else {
                    throw new Error("Failed to delete user");
                }
            } catch (error) {
                Swal("Error", "Failed to delete promo", "error");
            }
        }
    };


    const handleEdit = async (userId) => {
        seteditid(userId)
        try {
            const response = await fetch(
                `${BACKEND_API_URL}promo-codes/${userId}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${userDataToken?.token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch promo codes');
            }

            // Log the response body
            const responseBody = await response.text();

            // Parse the response body as JSON
            const data = JSON.parse(responseBody);
            console.log(data)
            const startDate = new Date(data?.valid_from).toISOString().split('T')[0];
            const endDate = new Date(data?.valid_to).toISOString().split('T')[0];
            setpromoname(data?.code)
            setdiscount(data?.discount)
            setStartDate(startDate)
            setEndDate(endDate)
            setIsActive(data?.is_active)
            setiseveryone(data?.is_everyone)
            setIsDialogOpen(true)
        } catch (error) {
            console.error('Error fetching promo codes:', error);
            // Handle error, e.g., display an error message to the user
        }
    };

    const handleSubmit = async () => {
        // Check if all fields are filled
        if (!promoname || !discount || !startDate || !endDate) {
            setValidationError('All fields are required');
            return;
        }
        // Check if start date is before end date
        if (new Date(startDate) >= new Date(endDate)) {
            setValidationError('Start date must be before end date');
            return;
        }
        // Clear any previous validation error
        setValidationError('');
        if (!editid) {
            try {
                const response = await fetch(
                    `${BACKEND_API_URL}promo-codes/`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${userDataToken?.token}`,
                        },
                        body: JSON.stringify({
                            code: promoname,
                            discount,
                            valid_from: startDate,
                            valid_to: endDate,
                            is_active: isActive,
                            is_everyone: iseveryone
                        }),
                    }
                );
                if (response.ok) {
                    // Handle success response
                    Swal("Added!", "Promo has been added!", "success");
                    fetchData();
                    setIsDialogOpen(false);
                } else {
                    // Handle error response
                    const responseData = await response.json();
                    if (responseData.code && responseData.code.length > 0 && responseData.code[0] === "promo code with this code already exists.") {
                        // Handle specific error: promo code already exists
                        Swal("Error", "Promo code already exists.", "error");
                    } else {
                        // Handle other errors
                        throw new Error("Failed to create promo code");
                    }
                }
            } catch (error) {
                // Handle fetch or other errors
                console.error('Error creating promo code:', error);
                Swal("Error", "Failed to create promo code", "error");
            }
        }
        else {
            try {
                const response = await fetch(
                    `${BACKEND_API_URL}promo-codes/${editid}/`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${userDataToken?.token}`,
                        },
                        body: JSON.stringify({
                            code: promoname,
                            discount,
                            valid_from: startDate,
                            valid_to: endDate,
                            is_active: isActive,
                            is_everyone: iseveryone
                        }),
                    }
                );
                if (response.ok) {
                    // Handle success response
                    Swal("Added!", "Promo has been Updated!", "success");
                    fetchData()
                    setIsDialogOpen(false);
                } else {
                    // Handle error response
                    const responseData = await response.json();
                    if (responseData.code && responseData.code.length > 0 && responseData.code[0] === "promo code with this code already exists.") {
                        // Handle specific error: promo code already exists
                        Swal("Error", "Promo code already exists.", "error");
                    } else {
                        // Handle other errors
                        throw new Error("Failed to create promo code");
                    }
                }
            } catch (error) {
                // Handle fetch or other errors
                console.error('Error creating promo code:', error);
                Swal("Error", "Failed to create promo code", "error");
            }
        }
    };

    const {
        userData: userDataToken,
    } = useSelector((state) => state.authReducer);

    const { userlist, success: userlistsucess } = useSelector(
        (state) => state.userlistreducer
    );

    useEffect(() => {
        dispatch(GetuserAction());
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${BACKEND_API_URL}promo-codes/`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${userDataToken?.token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch promo codes');
            }

            // Log the response body
            const responseBody = await response.text();

            // Parse the response body as JSON
            const data = JSON.parse(responseBody);
            setallpromo(data);
        } catch (error) {
            console.error('Error fetching promo codes:', error);
            // Handle error, e.g., display an error message to the user
        }
    };


    useEffect(() => {
        fetchData();
    }, [BACKEND_API_URL, userDataToken]);

    useEffect(() => {
        if (allpromo && allpromo.length > 0) {
            const userData = allpromo.map(item => {
                // Parse ISO 8601 formatted date strings into Date objects
                const startDate = new Date(item.valid_from).toLocaleDateString('en-US');
                const endDate = new Date(item.valid_to).toLocaleDateString('en-US');

                // Create a new object with transformed properties
                return {
                    ...item,
                    title: item.code,
                    Lastname: item.discount,
                    start_Date: startDate,
                    end_date: endDate,
                    Action: (
                        <div className="btncentre">
                            {!item.is_everyone ? (
                                <button onClick={() => Handleadduser(item.id)}>Assign user</button>
                            ) : (
                                "For Everyone"
                            )}
                            <i onClick={() => handleDelete(item.id)} className="fa fa-trash common-icon" aria-hidden="true"></i>
                            <i className="fas fa-edit common-icon" onClick={() => handleEdit(item.id)}></i>
                        </div>
                    ),
                };
            });
            setUsersForRender(userData);
        } else {
            setUsersForRender([]);
        }
    }, [allpromo]);




    const data1 = {
        columns: [
            {
                label: "Promo Name",
                field: "title",
                sort: "asc",
                width: 500,
            },
            {
                label: "Discount In %",
                field: "Lastname",
                sort: "asc",
                width: 500,
            },
            {
                label: "Start-Date",
                field: "start_Date",
                sort: "asc",
                width: 500,
            },
            {
                label: "End-Date",
                field: "end_date",
                sort: "asc",
                width: 500,
            },
            {
                label: "Action",
                field: "Action",
                sort: "asc",
                width: 500,
            },

        ],
        rows: usersForRender,
    };

    const Handleaddpromo = () => {
        setIsDialogOpen(true)
        setpromoname("")
        setdiscount("")
        setStartDate("")
        setEndDate("")
        setIsActive(false)
        setiseveryone(false)
        setIsDialogOpen(true)
        seteditid("")
    };

    const Handleadduser = async (id) => {
        seteditid(id);
        setIsDialogOpen1(true);
        try {
            const response = await fetch(
                `${BACKEND_API_URL}assign-promo/?promo_code_id=${id}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${userDataToken?.token}`,
                    },
                }
            );

            const data = await response.json();


            const initialCheckedUsers = {};
            data.users.forEach(user => {
                initialCheckedUsers[user.id] = true;
            });
            setCheckedUsers(initialCheckedUsers);

        } catch (error) {
            console.error('Error fetching promo codes:', error);
            // Handle error, e.g., display an error message to the user
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;

        if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
            setdiscount(value);
        }
    };



    return (
        <>
            {isLoading ? <LoadingSpinner />
                :
                <div className="main-center">
                    <h1 className="componentheading">Promo List</h1>
                    <div className="center-section">
                        <div>
                            <div className="exportUserList">
                                <button className="AddCompbutton" onClick={() => Handleaddpromo()}>Add</button>
                            </div>
                            <MDBDataTable
                                style={{}}
                                responsive
                                striped
                                bordered
                                small
                                data={data1}
                            />
                        </div>
                    </div>
                    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                        <DialogTitle>Add the promocode</DialogTitle>
                        <DialogContent>
                            {error && <Alert severity="error">{error}</Alert>}
                            {validationError && <Alert severity="error">{validationError}</Alert>}
                            <TextField
                                autoFocus
                                margin="dense"
                                id="promo-name"
                                label="Promo Name"
                                type="text"
                                fullWidth
                                value={promoname}
                                onChange={(e) => setpromoname(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                margin="dense"
                                id="discount"
                                label="Discount"
                                type="number"
                                fullWidth
                                value={discount}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                margin="dense"
                                id="start-date"
                                label="Start Date"
                                type="date"
                                fullWidth
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                margin="dense"
                                id="end-date"
                                label="End Date"
                                type="date"
                                fullWidth
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isActive}
                                        onChange={(e) => setIsActive(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is Active"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={iseveryone}
                                        onChange={(e) => setiseveryone(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is Everyone"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSubmit} color="primary">
                                Submit
                            </Button>
                            <Button
                                onClick={() => {
                                    setIsDialogOpen(false);
                                    setpromoname('');
                                    setdiscount('');
                                    setStartDate('');
                                    setEndDate('');
                                    setIsActive(false);
                                }}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <div className="maindialogbox" >
                        <Dialog open={isDialogOpen1} onClose={() => setIsDialogOpen1(false)}>
                            <DialogTitle>Select User</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Add User
                                </DialogContentText>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                                color="primary"
                                            />
                                        }
                                        label="Select All"
                                    />
                                    {userlist?.map((item, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={checkedUsers[item.id] || false}
                                                    onChange={() => handleCheckboxChange(item.id)}
                                                    color="primary"
                                                />
                                            }
                                            label={`${item.first_name} (${item.email})`}
                                        />
                                    ))}
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSubmituser} color="primary">
                                    Submit
                                </Button>
                                <Button
                                    onClick={() => setIsDialogOpen1(false)}
                                    color="secondary"
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            }
        </>
    );
};

export default Promolist;
