import React, { useEffect } from "react";
import { GetWinnersAction } from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";

const WinnersPage = () => {
  const dispatch = useDispatch();

  const {
    GetWinner,
  } = useSelector((state) => state.GetWinnersReducer);


  useEffect(() => {
    dispatch(GetWinnersAction());
  }, [dispatch]);
  return (
    <>
      <div className="winner_page_bg">
        <div className="container">
          <div className="compitition_future text-white text-center winner_title">
            <h2>Winners</h2>
          </div>

          <div className="grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 gap-6 m-6">
            {GetWinner?.map((item, index) => (
              <div
              className="bg-white home_page_card winner_page winner_card-"
                key={item?.id}
              >
                <div className="winner_img gold_trophy">
                  <img
                    className="rounded-t-lg w-100"
                    src={item?.winning_image}
                    alt=""
                  />
                </div>

                <div className="competitionContent winner_acrd_content">
                  <div className="winner_text">
                    <h4 className="text-3xl font-medium uppercase  winner_comphtag ">
                      {item?.title
                        ? item.title
                          .split(" ")
                          .slice(0, 5)
                          .join(" ")
                        : ""}
                    </h4>
                    <h5 className="address_location">
                      {item?.user_description
                        ? item.user_description
                          .split(" ")
                          .slice(0, 10)
                          .join(" ")
                        : ""}
                    </h5>
                    <h5 className="tiket_number">
                      Ticket Number: {item?.ticket_number}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {GetWinner?.length < 1 && (
              <>
              <div className="coming_soon_main_section">
                <h1 className="coming_soon_main_section_child">
                  Coming Soon...
                </h1>
                </div>
              </>
           )}
        </div>
      </div>
    </>
  );
};

export default WinnersPage;
