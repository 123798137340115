import React, { useState, useEffect } from "react";
import { Validations } from "../../utils";
import { BACKEND_API_URL } from "../../Environment";
import axios from "axios";
import swal from "sweetalert";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";

const ResetPassword = () => {
  const location = useLocation();
  const pathname = location.pathname;
  let navigate = useNavigate();

  const { ResetpasswordId, userId } = useParams();
  const [Password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  const [error, setError] = useState({
    PasswordError: null,
    cpasswordError: null,
  });

  useEffect(() => {
    if (pathname.includes("reset-password")) {
      import("../../custom-tailwind.css");
    }
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const validationSubmit = (e) => {
    e.preventDefault();
    const tempError = {
      PasswordError: Validations.password(Password),
      cpasswordError: Validations.confirmPassword(Password, cpassword),
    };
    setError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      console.log(
        "..values",
        Object.values(tempError).filter((value) => value)
      );
      return;
    }
    submitHandler(e);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    axios
      .put(`${BACKEND_API_URL}reset-password/${ResetpasswordId}/${userId}/`, {
        password: Password,
        confirm_password: cpassword,
      })
      .then((res) => {
        swal({
          title: "Successfully Complete",
          text: "Your password successfully updated",
          className: "successAlert",
          icon: "success",
          buttons: false,
          timer: 5000,
        });
        navigate("/login");
      })
      .catch((err) => { });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="flex h-screen w-full items-center justify-center reset_password">
            <div className="reset_password_form bg-white px-16 py-10 shadow-2xl  backdrop-blur-md max-sm:px-8">
              <div className="text-white">
                <div className="mb-8 flex flex-col items-center">
                  <img
                    className="login_main_logo"
                    src="/images/logonew.png"
                    width="150"
                    alt=""
                    srcSet=""
                  />
                  <span className="text-black">Enter New Password</span>
                </div>
                <form onSubmit={validationSubmit}>
                  <div className="grid mb-2 text-lg text-black enter_password">
                    <input
                      className="rounded-3xl m-2 border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                      type="Password"
                      name="name"
                      placeholder="Password"
                      value={Password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError({ ...error, PasswordError: null });
                      }}
                    />
                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.PasswordError ? 1 : 0,
                      }}
                    >
                      {error.PasswordError ?? "valid"}
                    </span>
                  </div>
                  <div className="grid mb-2 text-lg conform_password text-black">
                    <input
                      className="rounded-3xl m-2 border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                      type="Password"
                      name="name"
                      placeholder="Confirm Password"
                      value={cpassword}
                      onChange={(e) => {
                        setCPassword(e.target.value);
                        setError({ ...error, cpasswordError: null });
                      }}
                    />

                    <span
                      style={{
                        color: "#D14F4F",
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "14px",
                        marginRight: "27px",
                        opacity: error.cpasswordError ? 1 : 0,
                      }}
                    >
                      {error.cpasswordError ?? "valid"}
                    </span>
                  </div>
                  <div className="mt-2 mb-6 flex justify-center text-lg text-black">
                    <button
                      type="submit"
                      className="rounded-3xl w-80 bg-yellow-400 bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600"
                    >
                      Send
                    </button>
                  </div>
                  <div className="mt-2 mb-8 flex justify-center text-lg text-black">
                    <Link to="/login">
                      <button className="rounded-3xl w-80 bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ResetPassword;
