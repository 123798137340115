let PAYMENT_URL = "";

if (process.env.REACT_APP_MODE === "PROD") {
  PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL_PROD;
} else {
  PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL_TEST;
}


export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export { PAYMENT_URL };
