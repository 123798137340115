import React, { useState, useEffect } from "react";
import {
  GetCartListAction,
  WithoutLoginGetCartListAction,
  ProcessToPayAction,
  WLoginDeleteCartItemAction,
  DeleteCartItemAction
} from "../../Redux/Actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import LoadingSpinner from "../LoadingSpinner";
import { Validations } from "../../utils";
import { BACKEND_API_URL, FRONTEND_URL } from "../../Environment";
import axios from "axios";
import { login } from "../../Redux/Actions/AuthAction";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const PlaceOrder = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [products, setProducts] = useState();
  const [totalItems, setTotalItems] = useState();
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);
  const [navigatestate, setnavigatestate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deviceIdData, setDeviceIdData] = useState();
  const [promoid, setpromoid] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [address_line1, setAddress_line1] = useState("");
  const [open, setOpen] = useState(false);

  const [LoginUsername, setLoginUsername] = useState("");
  const [LoginPassword, setLoginPassword] = useState("");
  const [termsCon, setTermsCon] = useState(false);
  const [doorNumber, setDoorNumber] = useState("");
  const [isPromoCodeVisible, setIsPromoCodeVisible] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [promovaluerror, setpromovaluerror] = useState(false);

  const {
    userData: userDataToken,
  } = useSelector((state) => state.authReducer);

  const handleButtonClick = () => {
    if (isPromoCodeApplied) {
      setPromoCode('');
      setIsPromoCodeApplied(false);
      setIsPromoCodeVisible(false);
      setIsPromoCodeVisible(false);
      const formData = new FormData();
      formData.append('promo_code', promoCode);
      try {
        const response = fetch(
          `${BACKEND_API_URL}apply-promo-code/`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${userDataToken?.token}`,
            },
            body: formData,
          }
        );

        if (response.ok) {

        } else {
        }
      } catch (error) {
        console.error('Error assigning promo code:', error);
      }
      if (userData) {
        dispatch(GetCartListAction());
      } else {
        if (deviceIdData) {
          dispatch(WithoutLoginGetCartListAction(deviceIdData));
        }
      }
    }
    if (totalItems?.total_package_amount_in_cart > 0) {
      setIsPromoCodeVisible((prevVisible) => !prevVisible);
    }
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
    setpromovaluerror(false)
  };

  const handleApplyPromoCode = async () => {
    if (promoCode.trim() === '') {
      setpromovaluerror(true);
      swal("Error", "Please enter a valid promo code", "error");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();

    if (deviceIdData && !userDataToken) {
      formData.append('device_id', deviceIdData);
    }

    if (userDataToken) {
      formData.append('user_id', userDataToken?.user.user_id);
    }

    formData.append('promo_code', promoCode);

    const headers = {};
    if (userDataToken?.token) {
      headers['Authorization'] = `Bearer ${userDataToken.token}`;
    }

    try {
      const response = await fetch(`${BACKEND_API_URL}apply-promo-code/`, {
        method: 'POST',
        headers: headers,
        body: formData,
      });

      const data = await response.json();
      setIsLoading(false);

      if (response.ok) {
        setpromoid(data.user_promo);
        if (data.message === "Promo code applied successfully") {
          setIsPromoCodeApplied(true);
          setIsPromoCodeVisible(false);
          setpromovaluerror(false);

          if (userData) {
            dispatch(GetCartListAction(promoCode));
          } else if (deviceIdData) {
            dispatch(WithoutLoginGetCartListAction(deviceIdData, promoCode));
          }

          swal("Success", "Promo code applied successfully", "success");
        } else {
          swal("Error", data.message || "Failed to apply promo code", "error");
        }
      } else {
        swal("Error", data.message || "Failed to apply promo code", "error");
      }
    } catch (error) {
      setIsLoading(false);
      swal("Error", "An unexpected error occurred", "error");
    }
  };






  const ukPostcodeRegex =
    /^(?!.*(ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA|KY\d|MSR|VG|AI|GE\s?CX|GIR\s?0A{2}|SAN\s?TA1))(([A-Za-z]{1,2}[0-9][A-Za-z0-9]?|[A-Za-z]{2}\d|\d[A-Za-z]{2}|[A-Za-z]\d|\d[A-Za-z])\s?\d[A-Za-z]{2})$/i;

  const [error, setError] = useState({
    userError: null,
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    PasswordError: null,
    cpasswordError: null,
    countryError: null,
    phoneError: null,
    isCheckedError: null,
    postCodeError: null,
    addressError: null,
    termsConError: null,
    doorError: null,
  });

  const [loginError, setLoginError] = useState({
    userNameLoginError: null,
    passwordLoginError: null,
  });

  useEffect(() => {
    const data = localStorage.getItem("DeviceIdUser");
    if (data) {
      setDeviceIdData(data);
    }
  }, []);

  const { error: userDataError, userData } = useSelector(
    (state) => state.authReducer
  );

  const { ProcessToPay, error: ProcessToPayError } = useSelector(
    (state) => state.ProcessToPayReducer
  );

  const { GetCartList } = useSelector((state) => state.GetCartListReducer);

  const { WithoutLoginGetCartList } = useSelector(
    (state) => state.WithoutLoginGetCartListReducer
  );
  const {
    WLoignUpdateCart,
    error: WLoignUpdateCartError,
    success: WLoignUpdateCartSuccess,
  } = useSelector((state) => state.WLoginUpdateCartListReducer);

  useEffect(() => {
    localStorage.removeItem("hasReloadedd");
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (userData) {
      if (GetCartList) {
        setProducts(GetCartList?.cart_items);
        setTotalItems(GetCartList?.totals);
      }
    } else {
      if (WithoutLoginGetCartList) {
        setProducts(WithoutLoginGetCartList?.cart_items);
        setTotalItems(WithoutLoginGetCartList?.totals);
      }
    }
  }, [GetCartList, WithoutLoginGetCartList]);



  useEffect(() => {
    if (ProcessToPay && render) {
      localStorage.setItem("order_Id", ProcessToPay?.id);

      setTimeout( async () => {
        if (promoid) {
          const formData = new FormData();
          formData.append('promo_id', promoid);
          try {
            const response = await fetch(
              `${BACKEND_API_URL}promo-check/`,
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${userDataToken?.token}`,
                },
                body: formData,
              }
            );
          } catch (error) {
            swal("Error", "An unexpected error occurred", "error");
          }
        }
        setIsLoading(true);
        if (totalItems?.total_package_amount_in_cart > 0) {
          navigate("/payment");
        } else {
          navigate("/order-successfully")
        }
        //window.location = FRONTEND_URL + "payment";
      }, 2000);
    }

    if (ProcessToPayError && render) {
      swal({
        title: "Error",
        text: ProcessToPayError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 2500,
      });
      setIsLoading(false);
      setRender(false);
    }
  }, [ProcessToPay, ProcessToPayError]);

  // const HandleProcess = () => {
  //   setRender(true);
  //   setIsLoading(true);
  //   dispatch(ProcessToPayAction());
  // };

  const HandleProcess = async () => {
      setRender(true);
      setIsLoading(true);
      const order_Id = localStorage.getItem("order_Id");
      const hasReloaded = localStorage.getItem("hasReloaded");
      const DeviceIdUser = localStorage.getItem("DeviceIdUser");
      const competitondetailid = localStorage.getItem("competitondetailid");
      const slidervalue = localStorage.getItem("slidervalue");
      const userData = localStorage.getItem("userData");
      const loglevel = localStorage.getItem("loglevel");
      const myAwesomeCookieName2 = localStorage.getItem("myAwesomeCookieName2");
      let authUserData = null;
      if (userData) {
        const parsedUserData = JSON.parse(userData);
        authUserData = parsedUserData.token;
      }
      if (authUserData) {
        const data = {
          order_Id: order_Id,
          hasReloaded: hasReloaded,
          DeviceIdUser: DeviceIdUser,
          competitondetailid: competitondetailid,
          slidervalue: slidervalue,
          loglevel: loglevel,
          userData: userData,
          myAwesomeCookieName2: myAwesomeCookieName2,
        };

        // Setting token in header
        const config = {
          headers: {
            "Content-type": "application/json", // Set content type to JSON
            Authorization: `Bearer ${authUserData}`,
          },
        };

        try {
          const response = await axios.post(
            `${BACKEND_API_URL}user-data/`,
            JSON.stringify(data),
            config
          );
          console.log("Response:", response.data);
          dispatch(ProcessToPayAction());
          // Handle response as needed
        } catch (error) {
          console.error("Error:", error);
          // Handle error as needed
        }

      }
  };

  const validationSubmit = (e) => {
    e.preventDefault();
    const tempError = {
      firstNameError: Validations.firstName(firstName),
      lastNameError: Validations.lastName(lastName),
      emailError: Validations.email(email),
      PasswordError: Validations.password(Password),
      phoneError: Validations.phoneNumber(phoneNumber),
      postCodeError: Validations.isValidZipCode(postcode),
      addressError: Validations.AddressRequired(address_line1),
      doorError: Validations.doorNumberIs(doorNumber),
    };
    setError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    validationSubmitSecond(e);
  };

  const validationSubmitSecond = (e) => {
    e.preventDefault();
    const tempError = {
      isCheckedError: !isChecked && "This field is required",
      termsConError: !termsCon && "This field is required",
    };
    setError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    submitHandler(e);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${BACKEND_API_URL}orders/`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        password: Password,
        postcode: postcode,
        address_line1: address_line1,
        device_id: deviceIdData,
        door_no: doorNumber,
      })
      .then((res) => {
        localStorage.setItem("order_Id", res?.data?.user?.order_id);
        dispatch(
          login({
            email: email,
            password: Password,
          })
        );

        // debugger;

        swal({
          title: "Successfully Complete",
          text: res.data.message,
          icon: "success",
          buttons: false,
          timer: 3000,
        });
        setTimeout(() => {
          if (totalItems?.total_package_amount_in_cart > 0) {
            navigate("/payment");
          } else {
            navigate("/order-successfully")
          }
          setIsLoading(false);
        }, 7000);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1);

        if (
          err.response.data?.message?.non_field_errors ==
          "User Name already taken"
        ) {
          setIsLoading(false);
          const tempErrors = {
            username: "Username already taken",
          };
          setError(tempErrors);
          return;
        } else if (
          err.response.data?.message?.non_field_errors?.[0] ==
          "Email must be unique"
        ) {
          setIsLoading(false);
          const tempErrors = {
            emailError: "Email already taken",
          };
          setError(tempErrors);
          return;
        } else if (err?.response?.status == 500) {
          setIsLoading(false);
          swal({
            title: "Something went wrong",
            text: err?.message,
            icon: "error",
            buttons: false,
            timer: 5000,
          });
          return;
        } else {
          setIsLoading(false);
          const tempErrors = {
            email: "Email already taken",
            username: "Username already taken",
          };
          setError(tempErrors);
          return;
        }
      });
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoginError({
      userNameLoginError: null,
      passwordLoginError: null,
    });
  };

  useEffect(() => {
    if (userData && render2) {
      swal({
        title: "Successfully Complete",
        text: "Login Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setOpen(false);
      setTimeout(() => {
        setIsLoading(false);
        //window.location.reload();
      }, 2000);
      setRender2(false);
    }
    if (userDataError && render2) {
      swal({
        title: "Error",
        text: userDataError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 1500,
      });
      setIsLoading(false);
      setRender2(false);
    }
  }, [dispatch, userData, userDataError]);

  const validateSubmitLogin = (e) => {
    e.preventDefault();
    const tempError = {
      userNameLoginError: Validations.emailRequired(LoginUsername),
      passwordLoginError: Validations.password(LoginPassword),
    };
    setLoginError(tempError);
    if (Object.values(tempError).filter((value) => value).length) {
      return;
    }
    submitHandlerLogin(e);
  };

  const submitHandlerLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setRender2(true);
    await dispatch(
      login({
        email: LoginUsername,
        password: LoginPassword,
        device_id: deviceIdData,
      })
    );
  };

  useEffect(() => {
    const handleSubmitPostCode = async () => {
      try {
        if (!postcode.match(ukPostcodeRegex)) {
          // If postcode doesn't match UK postcode regex, do nothing
          return;
        }

        const response = await axios.get(
          `https://api.postcodes.io/postcodes/${postcode}`
        );
        const { latitude, longitude } = response.data.result;

        const streetResponse = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
        );
        const street = streetResponse.data.address.road;
        setAddress_line1(street);
        setError({ ...error, addressError: null });
      } catch (error) {
        // console.log("error------------",error)
        setAddress_line1("");
      }
    };

    handleSubmitPostCode();
  }, [postcode]);

  const [render3, setRender3] = useState(false);

  const {
    DelCartItem,
    error: DelCartItemError,
    success: DelCartItemSuccess,
  } = useSelector((state) => state.DeleteCartItemReducer);

  const {
    UpdateCart,
    error: UpdateCartError,
    success: UpdateCartSuccess,
  } = useSelector((state) => state.UpdateCartListReducer);

  const {
    WLoginDelCartItem,
    error: WLoginDelCartItemError,
  } = useSelector((state) => state.WLoginDeleteCartItemReducer);

  useEffect(() => {
    if (promoCode) {
      if (userData) {
        dispatch(GetCartListAction(promoCode));
      } else if (deviceIdData) {
        dispatch(WithoutLoginGetCartListAction(deviceIdData, promoCode));
      }
    }else{
      if (userData) {
        dispatch(GetCartListAction());
      } else {
        if (deviceIdData) {
          dispatch(WithoutLoginGetCartListAction(deviceIdData));
        }
      }
    }
  }, [userData, deviceIdData, dispatch,
    UpdateCartSuccess,
    WLoginDelCartItem,
    DelCartItemSuccess,
    DelCartItemError,
    deviceIdData]);

  useEffect(() => {
    if (DelCartItem && render) {
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setRender(false);
     
    }
    if (DelCartItemError && render) {
      swal({
        title: "Error",
        text: "Cart item not found because competition has expired.",
        className: "errorAlert-login",
        buttons: false,
        timer: 5000,
      });
      setRender(false);
    }
  
  }, [dispatch, DelCartItem, DelCartItemError,navigatestate]);

  useEffect(() => {
    if (WLoginDelCartItem && render3 ) {
      setPromoCode("")
      setpromoid("")
      setIsPromoCodeApplied(false);
      setIsPromoCodeVisible(false);
      setIsPromoCodeVisible(false);
      swal({
        title: "Successfully Complete",
        text: "Delete Success",
        className: "successAlert-login",
        icon: "success",
        buttons: false,
        timer: 1500,
      });
      setRender3(false);
    }
    if (WLoginDelCartItemError && render3 ) {
      swal({
        title: "Error",
        text: WLoginDelCartItemError,
        className: "errorAlert-login",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setRender3(false);
      if (
        UpdateCartError ==
        "Cart item not found because some Competition has expired, please order again"
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  }, [dispatch, WLoginDelCartItem, WLoginDelCartItemError,navigatestate]);



  const removeFromCart = (i, id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      setPromoCode('')
      setpromoid('')
      setIsPromoCodeApplied(false);
      setIsPromoCodeVisible(false);
      setIsPromoCodeVisible(false);
      if (willDelete) { 
        if (userData) {
          setRender(true);
          dispatch(DeleteCartItemAction(id));
          setProducts((prevCart) =>
          prevCart.filter((item, o) => {
            return i !== o;
          })
        );
        } else {
          setRender3(true);
          dispatch(WLoginDeleteCartItemAction(id, deviceIdData));
          setProducts((prevCart) =>
          prevCart.filter((item, o) => {
            return i !== o;
          })
        );
        }
      }
    });
  };


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="container">
            <div className="compitition_future place_order">
              <h2 className="competitonHeading text-3xl font-semibold font-montserrat text-center">
                Checkout
              </h2>
            </div>
            {!userData && (
              <div
                className="button_login_checkout placebtn showtheIconAnd"
                onClick={() => handleOpenDialog()}
              >
                <div className="flex">
                  <i
                    className="fas fa-user-check returning_customer_section"
                    aria-hidden="true"
                  ></i>
                  <p className="returning_customer_section">
                    Returning customer?
                  </p>
                </div>
                <button className="btn-3">Login</button>
              </div>
            )}

            <div className="pageplaceorder">
              {!userData && (
                <div className="register_with_checkout_page placeorder1">
                  <div className="text-white">
                    <div className="Billingtitle">
                      <span className="text-black">Billing details</span>
                      <p>
                        Please make sure we have your correct information for
                        when you win
                      </p>
                    </div>
                    <div className="text-lg text-black  gap-2 register_formmm_checkout oderinputsec">
                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="Text"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setError({ ...error, firstNameError: null });
                          }}
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          First Name
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.firstNameError ? 1 : 0,
                          }}
                        >
                          {error.firstNameError ?? "valid"}
                        </span>
                      </div>

                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="Text"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            setError({ ...error, lastNameError: null });
                          }}
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Last Name
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.lastNameError ? 1 : 0,
                          }}
                        >
                          {error.lastNameError ?? "valid"}
                        </span>
                      </div>
                    </div>

                    <div className=" text-lg text-black  gap-2 register_formmm_checkout">
                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="Text"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setError({ ...error, emailError: null });
                          }}
                          autoComplete="off"
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Email
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.emailError ? 1 : 0,
                          }}
                        >
                          {error.emailError ?? "valid"}
                        </span>
                      </div>

                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="email"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={phoneNumber}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /[^\d+]/g,
                              ""
                            );
                            setPhoneNumber(numericValue);
                            setError({ ...error, phoneError: null });
                          }}
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Phone Number
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.phoneError ? 1 : 0,
                          }}
                        >
                          {error.phoneError ?? "valid"}
                        </span>
                      </div>
                    </div>

                    <div className=" text-lg text-black  gap-2 register_formmm_checkout">
                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="Text"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={postcode}
                          onChange={(e) => {
                            setPostcode(e.target.value);
                            setError({ ...error, postCodeError: null });
                          }}
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Post code
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.postCodeError ? 1 : 0,
                          }}
                        >
                          {error.postCodeError ?? "valid"}
                        </span>
                      </div>

                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="Text"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={doorNumber}
                          onChange={(e) => {
                            setDoorNumber(e.target.value);
                            setError({ ...error, doorError: null });
                          }}
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Door Number
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.doorError ? 1 : 0,
                          }}
                        >
                          {error.doorError ?? "valid"}
                        </span>
                      </div>
                    </div>

                    <div className=" text-lg text-black  gap-2 register_formmm_checkout">
                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="Text"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={address_line1}
                          onChange={(e) => {
                            setAddress_line1(e.target.value);
                            setError({ ...error, addressError: null });
                          }}
                          autoComplete="off"
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Address
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.addressError ? 1 : 0,
                          }}
                        >
                          {error.addressError ?? "valid"}
                        </span>
                      </div>
                    </div>

                    <div className=" text-lg text-black  gap-2 register_formmm_checkout">
                      <div className="relative compitition_name z-0 w-full mb-3 group">
                        <input
                          type="Password"
                          name="floating_email"
                          id="floating_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer showboarderininput"
                          placeholder=" "
                          value={Password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setError({ ...error, PasswordError: null });
                          }}
                          autoComplete="new-password"
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Password
                        </label>

                        <span
                          style={{
                            color: "#D14F4F",
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "12px",
                            marginRight: "27px",
                            opacity: error.PasswordError ? 1 : 0,
                          }}
                        >
                          {error.PasswordError ?? "valid"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <section
                className={`support_page ${userData ? "placeorder2_" : "placeorder2"
                  }`}
              >
                {products && products.length > 0 ? (
                  <>
                    {products && products.length == 0 ? (
                      <div className="whenPrcessDatanot">
                        <h1>Not Found</h1>
                      </div>
                    ) : (
                      <div className=" align-items-center">
                        <div className="odersec">
                          <div className="productcard-show bg-white place_order_border rounded-lg overflow-hidden">
                            <div className="p-4">
                              <div className="border-b order_color">
                                <h1 className="text-gray-900 font-bold text-2xl">
                                  Your Order
                                </h1>
                              </div>
                              <div className="product-heading">
                                <h2 className="text-lg font-semibold mt-2">
                                  Product
                                </h2>
                                <h2 className="text-lg font-semibold mt-2">
                                  Subtotal
                                </h2>
                              </div>
                              {products &&
                                products.map((itemsData, index) => (
                                  <div className="py-3 bg-gray-200 px-3 productitemsCheckOut">
                                    <div className="product-heading">
                                      <p className="m-0">
                                        {" "}
                                        {itemsData.competition_name}{" "}
                                        <i className="fa fa-close"  onClick={() => removeFromCart(index, itemsData.id)}></i>{" "}
                                        {itemsData.quantity}
                                      </p>
                                      <p className="m-0">
                                        £{itemsData.package_price}
                                      </p>
                                    </div>
                                    <div className="answerdiv1">
                                      <h2 className="text-lg font-semibold mb-0">
                                        Answer:
                                      </h2>
                                      <p className="m-0">
                                        {itemsData?.user_answer}
                                      </p>
                                    </div>
                                  </div>
                                ))}

                              <div className="product-heading border-b">
                                <h2 className="text-lg font-semibold mt-2">
                                  Subtotal
                                </h2>
                                <h2 className="text-lg mt-2">
                                  {totalItems?.total_package_amount_in_cart >
                                    1.0
                                    ? `£${totalItems?.total_package_amount_in_cart.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`
                                    : `${totalItems?.total_package_amount_in_cart
                                      .toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })
                                      .replace(/^0?\./, "")}p`}
                                </h2>
                              </div>
                              <div className="product-heading">
                                <h2 className="text-lg font-semibold mt-2">
                                  Total
                                </h2>
                                <h2 className="text-lg font-semibold mt-2">
                                  £{totalItems?.total_package_amount_in_cart}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!userData && (
                          <div>
                            <div className="relative z-0 w-full group checkboxdiv">
                              <div className="flex items-center">
                                <input
                                  checked={isChecked}
                                  onChange={(e) => {
                                    setIsChecked(e.target.checked);
                                    setError({
                                      ...error,
                                      isCheckedError: null,
                                    });
                                  }}
                                  id="checked-checkbox"
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 inputordersec2"
                                />
                                <label
                                  for="checked-checkbox"
                                  className="ml-2 text-sm font-medium mb-0 text-gray-900 dark:text-gray-300 AreYouSection"
                                >
                                  I am 18 years +
                                </label>
                              </div>
                              <span
                                style={{
                                  color: "#D14F4F",
                                  fontSize: "12px",
                                  marginLeft: "47px",
                                  opacity: error.isCheckedError ? 1 : 0,
                                }}
                              >
                                {error.isCheckedError ?? "valid"}
                              </span>
                            </div>
                            <div>
                              <div className="text-center registar_form flex">
                                <input
                                  checked={termsCon}
                                  onChange={(e) => {
                                    setTermsCon(e.target.checked);
                                    setError({ ...error, termsConError: null });
                                  }}
                                  id="checked-checkbox2"
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 marginTop22"
                                />
                                <label
                                  for="checked-checkbox2"
                                  className="ml-2 text-sm font-medium mb-0 text-gray-900 dark:text-gray-300"
                                >
                                  <Link
                                    className="linkColor2"
                                    to="/terms-to-play"
                                  >
                                    I agree to Terms of Play
                                  </Link>
                                </label>
                              </div>
                              <span
                                style={{
                                  color: "#D14F4F",
                                  fontSize: "12px",
                                  marginLeft: "47px",
                                  opacity: error.termsConError ? 1 : 0,
                                }}
                              >
                                {error.termsConError ?? "valid"}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="pay_now_btn Proceedbtns">
                          <div className="checkout-container">
                            {totalItems?.total_package_amount_in_cart > 0 && <>
                              {deviceIdData && !userDataToken && <button
                                className="proceed_pay logincheckbtn checkout-btn"
                                onClick={handleButtonClick}
                              >
                             {totalItems?.total_package_amount_in_cart > 0 
    ? (isPromoCodeApplied 
        ? "Remove Promocode" 
        : "Apply Promocode")
    : (totalItems?.total_package_amount_in_cart === 0 
        ? "Remove Promocode" 
        : "")}


                              </button>}
                              {userDataToken && <button
                                className="proceed_pay logincheckbtn"
                                onClick={handleButtonClick}
                              >
                             {totalItems?.total_package_amount_in_cart > 0 
    ? (isPromoCodeApplied 
        ? "Remove Promocode" 
        : "Apply Promocode")
    : (totalItems?.total_package_amount_in_cart === 0 
        ? "Remove Promocode" 
        : "")}


                              </button>}

                            </>}
                            {isPromoCodeVisible && !isPromoCodeApplied && (
                              <>
                                <div className="promo-code-container">
                                  <div>
                                    <input
                                      type="text"
                                      value={promoCode}
                                      onChange={handlePromoCodeChange}
                                      placeholder="Enter Promocode"
                                      className="promo-code-input"
                                    />

                                  </div>


                                  <button
                                    className="apply-promo-code-btn"
                                    onClick={handleApplyPromoCode}
                                  >
                                    Add
                                  </button>
                                </div>
                                <div className="promocode-err">
                                  {promovaluerror && <span className="promocode-add">Please Add Promocode </span>}
                                </div>
                              </>
                            )}
                            {isPromoCodeApplied && (
                              <div className="promo-code-applied">
                                <p>Promocode "{promoCode}" applied.</p>
                              </div>
                            )}
                          </div>

                          {userData ? (
                            <button
                              className="proceed_pay logincheckbtn "
                              onClick={(e) => HandleProcess(e)}
                            >
                              {" "}
                              {totalItems?.total_package_amount_in_cart > 0 ? "Proceed to Pay" : "Place Order"}
                            </button>
                          ) : (
                            <button
                              className="proceed_pay checkout-btn"
                              onClick={(e) => validationSubmit(e)}
                            >
                              {" "}
                              {totalItems?.total_package_amount_in_cart > 0 ? "Proceed to Pay" : "Place Order"}
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="whenPrcessDatanotdata">
                    <h1 className="not-found">Not Found</h1>
                  </div>
                )}
              </section>
            </div>
          </div>
          <div>
            <Dialog
              className="dialogsctionmain_checkout"
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div className="close-icons-placeorder">
                  <i
                    class="fa fa-close placeorder-icons-design"
                    onClick={handleClose}
                  ></i>
                </div>
                <div className="place-order-image-logo">
                  <img
                    className="login_main_logo"
                    src="/images/logonew.png"
                    width="150"
                    alt=""
                    srcSet=""
                  />
                </div>
                <h4 className="enter-login-placeorder-heading">
                  Enter Login Details
                </h4>
              </DialogTitle>
              <DialogContent className="dailog-placeorder-login-details">
                <DialogContentText id="alert-dialog-description">
                  <div className="enter-lgoin-placeorder-sections-last">
                    <div className="flex w-full items-center justify-center">
                      <div className="bg-white">
                        <div className="text-white">
                          <div className="grid login_inputp text-lg text-black">
                            <input
                              className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                              type="text"
                              name="name"
                              placeholder="E-mail"
                              value={LoginUsername}
                              onChange={(e) => {
                                setLoginUsername(e.target.value);
                                setError({
                                  ...loginError,
                                  userNameLoginError: null,
                                });
                              }}
                            />
                            <span
                              style={{
                                color: "#D14F4F",
                                textAlign: "end",
                                fontSize: "12px",
                                opacity: loginError.userNameLoginError ? 1 : 0,
                              }}
                            >
                              {loginError.userNameLoginError ?? "valid"}
                            </span>
                          </div>

                          <div className="grid login_inputp text-lg text-black">
                            <input
                              className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-600 shadow-lg outline-none backdrop-blur-md"
                              type="Password"
                              name="name"
                              placeholder="*********"
                              value={LoginPassword}
                              onChange={(e) => {
                                setLoginPassword(e.target.value);
                                setError({
                                  ...loginError,
                                  passwordLoginError: null,
                                });
                              }}
                            />
                            <span
                              style={{
                                color: "#D14F4F",
                                textAlign: "end",
                                fontSize: "12px",
                                opacity: loginError.passwordLoginError ? 1 : 0,
                              }}
                            >
                              {loginError.passwordLoginError ?? "valid"}
                            </span>
                          </div>

                          <p className="popup-forgot-link">
                            <Link className="linkColor2" to="/forgot-password">
                              Forgot Password?
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
                <div className="CancelSubmitbtn">
                  {/* <button
                    className="AddCompbutton AddCompbutton1"
                    onClick={handleClose}
                  >
                    Cancel
                  </button> */}
                  <button
                    className="AddCompbutton AddCompbutton2"
                    onClick={(e) => validateSubmitLogin(e)}
                    autoFocus
                  >
                    Submit
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
};
export default PlaceOrder;
