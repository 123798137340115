import React, {  useEffect } from "react";
import "../Css/PrivacyPolicy.css";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { FRONTEND_URL } from "../../Environment";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="top pravicy_policy">
        <div className="container mx-auto md:px-[50px] sm:py-[12px] ">
          <div className="headingPolicy">
            <h1 className="cookies_Heading">PRIVACY POLICY</h1>
          </div>

          <p className="mb-3 paragraph text1">
            Last updated September 17, 2023
          </p>

          <p className="mb-3 paragraphnormal text2">
            This privacy notice for Lux499 ({" "}
            <strong>'we', 'us', or 'our'</strong> ), describes how and why we
            might collect, store, use, and/or share ({" "}
            <strong>'process' </strong>) your information when you use our
            services (<strong> 'Services'</strong> ), such as when you:
          </p>

          <ul className="unorderlist">
            <li className="listshow">
              Visit our website at
              <Link
                className="linkShow"
                to={FRONTEND_URL}
                target="_blank"
              >
                {FRONTEND_URL}
              </Link>{" "}
              , or any website of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>

          <p className="mb-8 mt-3">
            <strong>Questions or concerns?</strong> Reading this privacy notice
            will help you understand your privacy rights and choices. If you do
            not agree with our policies and practices, please do not use our
            Services. If you still have any questions or concerns, please
            contact us at info@lux499.com .
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading">SUMMARY OF KEY POINTS</h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              {" "}
              <em>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our
              </em>
            </strong>{" "}
            <strong>
              <ScrollLink to="tableofcontent" smooth={true} duration={500}>
                <em className="tablelink"> table of contents </em>
              </ScrollLink>

              <em> below to find the section you are looking for.</em>
            </strong>
          </p>

          <p className="mb-3 mt-3">
            What personal information do we process? When you visit, use, or
            navigate our Services, we may process personal information depending
            on how you interact with us and the Services, the choices you make,
            and the products and features you use. Learn more{" "}
            <ScrollLink to="personalinformation" smooth={true} duration={500}>
              <span className="colorblue">
                about personal information you disclose to us.
              </span>
            </ScrollLink>
          </p>

          <p className="mb-3 mt-3">
            <strong>Do we process any sensitive personal information?</strong>{" "}
            We do not process sensitive personal information.
          </p>

          <p className="mb-3 mt-3">
            <strong> Do we receive any information from third parties?</strong>{" "}
            We do not receive any information from third parties.
          </p>
          <p className="mb-3 mt-3">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about{" "}
            <ScrollLink to="processinfromation" smooth={true} duration={500}>
              <span className="colorblue">
                how we process your information.
              </span>
            </ScrollLink>
          </p>

          <p className="mb-3 mt-3">
            <strong>
              In what situations and with which parties do we share personal
              information?
            </strong>{" "}
            We may share information in specific situations and with specific
            third parties. Learn more about{" "}
            <ScrollLink to="whenandwith" smooth={true} duration={500}>
              <span className="colorblue">
                {" "}
                when and with whom we share your personal information .
              </span>
            </ScrollLink>
          </p>

          <p className="mb-3 mt-3">
            <strong> How do we keep your information safe?</strong> We have
            organisational and technical processes and procedures in place to
            protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorised third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Learn more about{" "}
            <ScrollLink to="informationsafe" smooth={true} duration={500}>
              <span className="colorblue">
                {" "}
                how we keep your information safe.
              </span>
            </ScrollLink>
          </p>

          <p className="mb-3 mt-3">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more about{" "}
            <ScrollLink to="privacyrights" smooth={true} duration={500}>
              <span className="colorblue"> your privacy rights.</span>
            </ScrollLink>
          </p>

          <p className="mb-3 mt-3">
            <strong> How do you exercise your rights? </strong>The easiest way
            to exercise your rights is by submitting a{" "}
            <Link
              to="https://app.termly.io/notify/0edd1686-2d03-44be-b436-bb713c56791c"
              target="_blank"
            >
              <span className="colorblue"> data subject access request </span>
            </Link>
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>

          <p className="mb-3 mt-3">
            Want to learn more about what we do with any information we collect?
            <ScrollLink to="tableofcontent" smooth={true} duration={500}>
              <span className="colorblue">
                {" "}
                Review the privacy notice in full.{" "}
              </span>
            </ScrollLink>
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="tableofcontent">
              TABLE OF CONTENTS
            </h1>
          </div>

          <ul className="tablecontentdata">
            <ScrollLink to="informationcollect" smooth={true} duration={500}>
              <li className="colorblue">WHAT INFORMATION DO WE COLLECT?</li>
            </ScrollLink>
            <ScrollLink to="processinfromation" smooth={true} duration={500}>
              <li className="colorblue">HOW DO WE PROCESS YOUR INFORMATION?</li>
            </ScrollLink>
            <ScrollLink
              to="processyourinfromation"
              smooth={true}
              duration={500}
            >
              <li className="colorblue">
                WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </li>
            </ScrollLink>

            <ScrollLink to="whenandwith" smooth={true} duration={500}>
              <li className="colorblue">
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </li>
            </ScrollLink>
            <ScrollLink to="thirdpartywebsites" smooth={true} duration={500}>
              <li className="colorblue">
                WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
              </li>
            </ScrollLink>

            <ScrollLink to="trackingtechnologies" smooth={true} duration={500}>
              <li className="colorblue">
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </li>
            </ScrollLink>

            <ScrollLink to="sociallogins" smooth={true} duration={500}>
              <li className="colorblue">
                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </li>
            </ScrollLink>

            <ScrollLink to="yourinformation8" smooth={true} duration={500}>
              <li className="colorblue">
                HOW LONG DO WE KEEP YOUR INFORMATION?
              </li>
            </ScrollLink>
            <ScrollLink to="informationsafe" smooth={true} duration={500}>
              <li className="colorblue">
                HOW DO WE KEEP YOUR INFORMATION SAFE?
              </li>
            </ScrollLink>

            <ScrollLink to="fromminors10" smooth={true} duration={500}>
              <li className="colorblue">
                DO WE COLLECT INFORMATION FROM MINORS?
              </li>
            </ScrollLink>

            <ScrollLink to="privacyrights" smooth={true} duration={500}>
              <li className="colorblue">WHAT ARE YOUR PRIVACY RIGHTS?</li>
            </ScrollLink>
            <ScrollLink to="donottrack12" smooth={true} duration={500}>
              <li className="colorblue">CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            </ScrollLink>
            <ScrollLink to="specificprivacy13" smooth={true} duration={500}>
              <li className="colorblue">
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </li>
            </ScrollLink>

            <ScrollLink to="haveSpecific14" smooth={true} duration={500}>
              <li className="colorblue">
                DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </li>
            </ScrollLink>
            <ScrollLink to="thisnotic15" smooth={true} duration={500}>
              <li className="colorblue">DO WE MAKE UPDATES TO THIS NOTICE?</li>
            </ScrollLink>

            <ScrollLink to="baoutnotice16" smooth={true} duration={500}>
              <li className="colorblue">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </li>
            </ScrollLink>

            <ScrollLink to="deletethedata17" smooth={true} duration={500}>
              <li className="colorblue">
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </li>
            </ScrollLink>
          </ul>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="informationcollect">
              1. WHAT INFORMATION DO WE COLLECT?
            </h1>
          </div>

          <p className="mb-3 mt-3 strongsetionbig" id="personalinformation">
            <strong>Personal information you disclose to us</strong>
          </p>

          <p className="mb-3 mt-3">
            <strong>
              <em> In Short:</em>
            </strong>{" "}
            <em>We collect personal information that you provide to us.</em>
          </p>

          <p className="mb-3 mt-3">
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>

          <p className="mb-3 mt-3">
            <strong>Personal Information Provided by You. </strong>The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>

          <ul className="text-N">
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>mailing addresses</li>
          </ul>

          <p className="mb-3 mt-3">
            <strong> Sensitive Information.</strong> We do not process sensitive
            information.
          </p>
          <p className="mb-3 mt-3">
            <strong> Payment Data.</strong> We may collect data necessary to
            process your payment if you make purchases, such as your payment
            instrument number, and the security code associated with your
            payment instrument. All payment data is stored by Paytriot . You may
            find their privacy notice link(s) here:
            <Link
              to="https://www.paytriot.co.uk/privacy-policy"
              target="_blank"
            >
              <span className="colorblue px-2">
                https://www.paytriot.co.uk/privacy-policy .
              </span>
            </Link>{" "}
          </p>

          <p className="mb-3 mt-3">
            <strong>Social Media Login Data.</strong> We may provide you with
            the option to register with us using your existing social media
            account details, like your Facebook, Twitter, or other social media
            account. If you choose to register in this way, we will collect the
            information described in the section called{" "}
            <ScrollLink to="sociallogins" smooth={true} duration={500}>
              <span className="colorblue">
                'HOW DO WE HANDLE YOUR SOCIAL LOGINS?'
              </span>
            </ScrollLink>
            below.
          </p>
          <p className="mb-3 mt-3">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="processinfromation">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>
            <em>
              {" "}
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </em>
          </p>

          <p className="mb-3 mt-3">
            <strong>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </strong>
          </p>

          <ul className="text-t1">
            <li>
              <strong>
                {" "}
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </strong>{" "}
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
            <li>
              <strong>
                {" "}
                To save or protect an individual's vital interest.
              </strong>{" "}
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="processyourinfromation">
              {" "}
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h1>
          </div>
          <p className="mb-3 mt-3">
            <strong>
              <em> In Short:</em>
            </strong>{" "}
            <em>
              We only process your personal information when we believe it is
              necessary and we have a valid legal reason (i.e. legal basis) to
              do so under applicable law, like with your consent, to comply with
              laws, to provide you with services to enter into or fulfil our
              contractual obligations, to protect your rights, or to fulfil our
              legitimate business interests.
            </em>
          </p>

          <p className="mb-3 mt-3">
            <strong>
              <em>
                If you are located in the EU or UK, this section applies to you.
              </em>
            </strong>
          </p>

          <p className="mb-3 mt-3">
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>

          <ul className="text-t">
            <li>
              <strong>Consent.</strong> We may process your information if you
              have given us permission (i.e. consent) to use your personal
              information for a specific purpose. You can withdraw your consent
              at any time. Learn more about{" "}
              <ScrollLink to="withdrawingcontent" smooth={true} duration={500}>
                <span className="colorblue"> withdrawing your consent.</span>
              </ScrollLink>
            </li>

            <li>
              <strong> Legal Obligations.</strong> We may process your
              information where we believe it is necessary for compliance with
              our legal obligations, such as to cooperate with a law enforcement
              body or regulatory agency, exercise or defend our legal rights, or
              disclose your information as evidence in litigation in which we
              are involved.
            </li>
            <li>
              <strong>Vital Interests.</strong> We may process your information
              where we believe it is necessary to protect your vital interests
              or the vital interests of a third party, such as situations
              involving potential threats to the safety of any person.
            </li>
          </ul>
          <div className="headingPolicy">
            <h1 className="policyHeading" id="whenandwith">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h1>
          </div>
          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>{" "}
            <em>
              We may share information in specific situations described in this
              section and/or with the following third parties.
            </em>
          </p>

          <p className="mb-3 mt-3">
            We may need to share your personal information in the following
            situations:
          </p>

          <ul>
            <li>
              <strong> Business Transfers.</strong> We may share or transfer
              your information in connection with, or during negotiations of,
              any merger, sale of company assets, financing, or acquisition of
              all or a portion of our business to another company.
            </li>
          </ul>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="thirdpartywebsites">
              5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>{" "}
            <em>
              We are not responsible for the safety of any information that you
              share with third parties that we may link to or who advertise on
              our Services, but are not affiliated with, our Services.
            </em>
          </p>

          <p className="mb-3 mt-3">
            The Services may link to third-party websites, online services, or
            mobile applications and/or contain advertisements from third parties
            that are not affiliated with us and which may link to other
            websites, services, or applications. Accordingly, we do not make any
            guarantee regarding any such third parties, and we will not be
            liable for any loss or damage caused by the use of such third-party
            websites, services, or applications. The inclusion of a link towards
            a third-party website, service, or application does not imply an
            endorsement by us. We cannot guarantee the safety and privacy of
            data you provide to any third parties. Any data collected by third
            parties is not covered by this privacy notice. We are not
            responsible for the content or privacy and security practices and
            policies of any third parties, including other websites, services,
            or applications that may be linked to or from the Services. You
            should review the policies of such third parties and contact them
            directly to respond to your questions.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="trackingtechnologies">
              6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>{" "}
            <em>
              {" "}
              We may use cookies and other tracking technologies to collect and
              store your information.
            </em>
          </p>
          <p className="mb-3 mt-3">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="sociallogins">
              7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>{" "}
            <em>
              {" "}
              If you choose to register or log in to our Services using a social
              media account, we may have access to certain information about
              you.
            </em>
          </p>

          <p className="mb-3 mt-3">
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>

          <p className="mb-3 mt-3">
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="yourinformation8">
              8. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short: </em>
            </strong>{" "}
            <em>
              We keep your information for as long as necessary to fulfil the
              purposes outlined in this privacy notice unless otherwise required
              by law.
            </em>
          </p>

          <p className="mb-3 mt-3">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>

          <p className="mb-3 mt-3">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymise such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="informationsafe">
              9. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h1>
          </div>
          <p className="mb-3 mt-3">
            <strong>
              <em>In Short: </em>
            </strong>{" "}
            <em>
              We aim to protect your personal information through a system of
              organisational and technical security measures.
            </em>
          </p>

          <p className="mb-3 mt-3">
            We have implemented appropriate and reasonable technical and
            organisational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorised third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="fromminors10">
              10. DO WE COLLECT INFORMATION FROM MINORS?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>
            <em>
              {" "}
              We do not knowingly collect data from or market to children under
              18 years of age.
            </em>
          </p>
          <p className="mb-3 mt-3">
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at info@lux499.com.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="privacyrights">
              11. WHAT ARE YOUR PRIVACY RIGHTS?
            </h1>
          </div>
          <p className="mb-3 mt-3">
            <strong>
              <em>In Short: </em>
            </strong>{" "}
            <em>
              In some regions, such as the European Economic Area (EEA) and
              United Kingdom (UK), you have rights that allow you greater access
              to and control over your personal information. You may review,
              change, or terminate your account at any time.
            </em>
          </p>
          <p className="mb-3 mt-3">
            In some regions (like the EEA and UK), you have certain rights under
            applicable data protection laws. These may include the right (i) to
            request access and obtain a copy of your personal information, (ii)
            to request rectification or erasure; (iii) to restrict the
            processing of your personal information; and (iv) if applicable, to
            data portability. In certain circumstances, you may also have the
            right to object to the processing of your personal information. You
            can make such a request by contacting us by using the contact
            details provided in the section{" "}
            <ScrollLink to="baoutnotice16" smooth={true} duration={500}>
              <span className="colorblue">
                'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
              </span>{" "}
            </ScrollLink>
            below.
          </p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your{" "}
            <Link
              to="https://ec.europa.eu/newsroom/article29/items/612080"
              target="_blank"
            >
              <span className="colorblue">
                Member State data protection authority{" "}
              </span>
            </Link>
            or{" "}
            <Link
              to="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
              target="_blank"
            >
              <span className="colorblue"> UK data protection authority.</span>
            </Link>
          </p>

          <p className="mb-3 mt-3">
            If you are located in Switzerland, you may contact the
            <Link
              to="https://www.edoeb.admin.ch/edoeb/en/home.html"
              target="_blank"
            >
              <span className="colorblue">
                {" "}
                Federal Data Protection and Information Commissioner.
              </span>
            </Link>
          </p>

          <p className="mb-3 mt-3">
            <strong id="withdrawingcontent"> Withdrawing your consent:</strong>{" "}
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section
            <ScrollLink to="baoutnotice16" smooth={true} duration={500}>
              <span className="colorblue">
                'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
              </span>
            </ScrollLink>
          </p>

          <p className="mb-3 mt-3">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>

          <p className="mb-3 mt-3">
            <strong>
              Opting out of marketing and promotional communications:
            </strong>{" "}
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, replying 'STOP' or 'UNSUBSCRIBE' to the SMS
            messages that we send, or by contacting us using the details
            provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
            below. You will then be removed from the marketing lists. However,
            we may still communicate with you — for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </p>

          <h1 className="strongsetionbig ">Account Information</h1>

          <p className="mb-3 mt-3">
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>

          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
          </ul>

          <p className="mb-3 mt-3">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p className="mb-3 mt-3">
            <strong>Cookies and similar technologies:</strong> Most Web browsers
            are set to accept cookies by default. If you prefer, you can usually
            choose to set your browser to remove cookies and to reject cookies.
            If you choose to remove cookies or reject cookies, this could affect
            certain features or services of our Services. You may also
            <Link
              to="https://optout.aboutads.info/?c=2&lang=EN"
              target="_blank"
            >
              <span className="colorblue">
                {" "}
                opt out of interest-based advertising by advertisers
              </span>
            </Link>
            on our Services.
          </p>

          <p className="mb-3 mt-3">
            If you have questions or comments about your privacy rights, you may
            email us at info@lux499.com.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="donottrack12">
              12. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h1>
          </div>
          <p className="mb-3 mt-3">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ('DNT') feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognising and
            implementing DNT signals has been finalised. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <div className="headingPolicy">
            <h1 className="policyHeading" id="specificprivacy13">
              13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h1>
          </div>
          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>
            <em>
              {" "}
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </em>
          </p>
          <p className="mb-3 mt-3">
            California Civil Code Section 1798.83, also known as the 'Shine The
            Light' law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p className="mb-3 mt-3">
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>
          <h1 className="strongsetionbig">CCPA Privacy Notice</h1>

          <p className="mb-3 mt-3 ">
            The California Code of Regulations defines a 'resident' as:
          </p>
          <p className="ml-5">
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </p>
          <p className="ml-5">
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </p>
          <p className="mb-3 mt-3">
            All other individuals are defined as 'non-residents'.
          </p>

          <p className="mb-3 mt-3">
            If this definition of 'resident' applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </p>

          <p className="mb-3 mt-3">
            <strong>
              What categories of personal information do we collect?
            </strong>
          </p>

          <p className="mb-3 mt-3">
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <div className="privacytexttable">
            <div className="table-responsive">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Examples</th>
                    <th>Collected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A. Identifiers</td>
                    <td>
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, and account name
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>
                      B. Personal information categories listed in the
                      California Customer Records statute
                    </td>
                    <td>
                      Name, contact information, education, employment,
                      employment history, and financial information
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>
                      C. Protected classification characteristics under
                      California or federal law
                    </td>
                    <td>Gender and date of birth</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>D. Commercial information</td>
                    <td>
                      Transaction information, purchase history, financial
                      details, and payment information
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>E. Biometric information</td>
                    <td>Fingerprints and voiceprints</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>F. Internet or other similar network activity</td>
                    <td>
                      Browsing history, search history, online behaviour,
                      interest data, and interactions with our and other
                      websites, applications, systems, and advertisements
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>G. Geolocation data</td>
                    <td>Device location</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>
                      H. Audio, electronic, visual, thermal, olfactory, or
                      similar information
                    </td>
                    <td>
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>I. Professional or employment-related information</td>
                    <td>
                      Business contact details in order to provide you our
                      Services at a business level or job title, work history,
                      and professional qualifications if you apply for a job
                      with us
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>J. Education Information</td>
                    <td>Student records and directory information</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>K. Inferences drawn from other personal information</td>
                    <td>
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual’s preferences and
                      characteristics
                    </td>
                    <td>NO</td>
                  </tr>

                  <tr>
                    <td>L. Sensitive Personal Information</td>
                    <td></td>
                    <td>NO</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="mb-3 mt-3">
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>

          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>

          <p className="mb-3 mt-3">
            <strong>How do we use and share your personal information?</strong>
          </p>

          <p className="mb-3 mt-3">
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>

          <p className="mb-3 mt-3">
            You may contact us by email at info@lux499.com, or by referring to
            the contact details at the bottom of this document.
          </p>

          <p className="mb-3 mt-3">
            If you are using an authorised agent to exercise your right to opt
            out we may deny a request if the authorised agent does not submit
            proof that they have been validly authorised to act on your behalf.
          </p>

          <p className="mb-3 mt-3">
            <strong>Will your information be shared with anyone else?</strong>
          </p>
          <p className="mb-3 mt-3">
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf, following the same strict privacy
            protection obligations mandated by the CCPA.
          </p>
          <p className="mb-3 mt-3">
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            'selling' of your personal information.
          </p>
          <p className="mb-3 mt-3">
            We have not disclosed, sold, or shared any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. We will not sell or share personal information
            in the future belonging to website visitors, users, and other
            consumers.
          </p>

          <p className="mb-3 mt-3">
            <strong>Your rights with respect to your personal data</strong>
          </p>
          <p className="mb-3 mt-3">
            <u> Right to request deletion of the data — Request to delete</u>
          </p>

          <p className="mb-3 mt-3">
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </p>

          <p className="mb-3 mt-3">
            <u>Right to be informed — Request to know</u>
          </p>
          <p className="mb-3 mt-3">
            Depending on the circumstances, you have a right to know:
          </p>

          <ul className="list_menu1">
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
              the purposes for which the collected personal information is used;
            </li>
            <li>
              whether we sell or share personal information to third parties;
            </li>
            <li>
              the categories of personal information that we sold, shared, or
              disclosed for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose;
            </li>
            <li>
              the business or commercial purpose for collecting, selling, or
              sharing personal information; and
            </li>
            <li>
              the specific pieces of personal information we collected about
              you.
            </li>
          </ul>

          <p className="mb-3 mt-3">
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>
          <p className="mb-3 mt-3">
            <u>
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </u>
          </p>
          <p className="mb-3 mt-3">
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p className="mb-3 mt-3">
            <u>
              {" "}
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </u>
          </p>
          <p className="mb-3 mt-3">
            We do not process consumer's sensitive personal information.
          </p>
          <p className="mb-3 mt-3">
            <u>Verification process</u>
          </p>

          <p className="mb-3 mt-3">
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p className="mb-3 mt-3">
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p className="mb-3 mt-3 text7568">
            <u>Other privacy rights</u>
          </p>

          <ul className="text7568">
            <li>
              You may object to the processing of your personal information.
            </li>
            <li>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </li>
            <li>
              You can designate an authorised agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorised
              agent that does not submit proof that they have been validly
              authorised to act on your behalf in accordance with the CCPA.
            </li>
            <li>
              You may request to opt out from future selling or sharing of your
              personal information to third parties. Upon receiving an opt-out
              request, we will act upon the request as soon as feasibly
              possible, but no later than fifteen (15) days from the date of the
              request submission.
            </li>
          </ul>

          <p className="mb-3 mt-3">
            To exercise these rights, you can contact us by email at
            info@lux499.com, or by referring to the contact details at the
            bottom of this document. If you have a complaint about how we handle
            your data, we would like to hear from you.
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="haveSpecific14">
              14. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short: </em>
            </strong>{" "}
            <em>
              Yes, if you are a resident of Virginia, you may be granted
              specific rights regarding access to and use of your personal
              information.
            </em>
          </p>
          <h1 className="strongsetionbig">Virginia CDPA Privacy Notice</h1>

          <p className="mb-3 mt-3">
            Under the Virginia Consumer Data Protection Act (CDPA):
          </p>

          <p className="mb-3 mt-3">
            'Consumer' means a natural person who is a resident of the
            Commonwealth acting only in an individual or household context. It
            does not include a natural person acting in a commercial or
            employment context.
          </p>
          <p className="mb-3 mt-3">
            'Personal data' means any information that is linked or reasonably
            linkable to an identified or identifiable natural person. 'Personal
            data' does not include de-identified data or publicly available
            information.
          </p>
          <p className="mb-3 mt-3">
            'Sale of personal data' means the exchange of personal data for
            monetary consideration.
          </p>
          <p className="mb-3 mt-3">
            If this definition 'consumer' applies to you, we must adhere to
            certain rights and obligations regarding your personal data.
          </p>
          <p className="mb-3 mt-3">
            The information we collect, use, and disclose about you will vary
            depending on how you interact with us and our Services. To find out
            more, please visit the following links:
          </p>

          <ul>
            <ScrollLink to="informationcollect" smooth={true} duration={500}>
              <li className="colorblue">Personal data we collect</li>
            </ScrollLink>

            <ScrollLink to="processinfromation" smooth={true} duration={500}>
              <li className="colorblue">How we use your personal data</li>
            </ScrollLink>

            <ScrollLink
              to="processyourinfromation"
              smooth={true}
              duration={500}
            >
              <li className="colorblue">
                When and with whom we share your personal data
              </li>
            </ScrollLink>
          </ul>

          <p className="mb-2 mt-2">
            <u> Your rights with respect to your personal data</u>
          </p>

          <ul className="text2">
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects ('profiling')
            </li>
          </ul>

          <p className="mb-3 mt-3">
            We have not sold any personal data to third parties for business or
            commercial purposes. We will not sell personal data in the future
            belonging to website visitors, users, and other consumers.
          </p>
          <p className="mb-3 mt-3">
            <u>Exercise your rights provided under the Virginia CDPA</u>
          </p>
          <p className="mb-3 mt-3">
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>
          <p className="mb-3 mt-3">
            You may contact us by email at info@lux499.com, by submitting a
            <span className="colorblue"> data subject access request,</span> or
            by referring to the contact details at the bottom of this document.
          </p>
          <p>
            If you are using an authorised agent to exercise your rights, we may
            deny a request if the authorised agent does not submit proof that
            they have been validly authorised to act on your behalf.
          </p>

          <p className="mb-3 mt-3">
            <u>Verification process</u>
          </p>
          <p className="mb-3 mt-3">
            We may request that you provide additional information reasonably
            necessary to verify you and your consumer's request. If you submit
            the request through an authorised agent, we may need to collect
            additional information to verify your identity before processing
            your request.
          </p>
          <p className="mb-3 mt-3">
            Upon receiving your request, we will respond without undue delay,
            but in all cases, within forty-five (45) days of receipt. The
            response period may be extended once by forty-five (45) additional
            days when reasonably necessary. We will inform you of any such
            extension within the initial 45-day response period, together with
            the reason for the extension.
          </p>

          <p className="mb-3 mt-3">
            <u>Right to appeal</u>
          </p>

          <p className="mb-3 mt-3">
            If we decline to take action regarding your request, we will inform
            you of our decision and reasoning behind it. If you wish to appeal
            our decision, please email us at info@lux499.com. Within sixty (60)
            days of receipt of an appeal, we will inform you in writing of any
            action taken or not taken in response to the appeal, including a
            written explanation of the reasons for the decisions. If your appeal
            if denied, you may contact the{" "}
            <span className="colorblue">
              {" "}
              Attorney General to submit a complaint.
            </span>
          </p>

          <div className="headingPolicy">
            <h1 className="policyHeading" id="thisnotic15">
              15. DO WE MAKE UPDATES TO THIS NOTICE?
            </h1>
          </div>

          <p className="mb-3 mt-3">
            <strong>
              <em>In Short:</em>
            </strong>{" "}
            <em>
              {" "}
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </em>
          </p>
          <p className="mb-3 mt-3">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated 'Revised' date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <div className="headingPolicy">
            <h1 className="policyHeading" id="baoutnotice16">
              16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h1>
          </div>
          <p className="mb-3 mt-3">
            If you have questions or comments about this notice, you may email
            us at info@lux499.com or contact us by post at:
          </p>
          <ul className="luxtext">
            <li>Lux499 124 City Road</li>
            <li>London EC1V 2NX</li>
            <li>United Kingdom</li>
          </ul>
          <div className="headingPolicy">
            <h1 className="policyHeading" id="deletethedata17">
              17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h1>
          </div>
          <p className="mb-3 mt-3">
            You have the right to request access to the personal information we
            collect from you, change that information, or delete it. To request
            to review, update, or delete your personal information, please fill
            out and submit a{" "}
            <Link
              to="https://app.termly.io/notify/0edd1686-2d03-44be-b436-bb713c56791c"
              target="_blank"
            >
              <span className="colorblue"> data subject access request. </span>
            </Link>
          </p>
          <p>
            This privacy policy was created using Termly's{" "}
            <span className="colorblue">Privacy Policy Generator.</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
