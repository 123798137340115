import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import swal from "sweetalert";
import { BACKEND_API_URL } from "../../Environment";
import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom";

const VerifyEmail = () => {
  const location = useLocation();
  const shouldLog = useRef(true);
  const { token, uid } = useParams();
  const pathname = location.pathname;
  const [successChk, setSuccessChk] = useState(false);

  useEffect(() => {
    if (pathname !== "/payment") {
      import("../../custom-tailwind.css");
    }
  }, []);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      axios
        .get(`${BACKEND_API_URL}verify-email/${token}/${uid}/`)
        .then((res) => {
          if (res.data.message) {
            setSuccessChk(true);
            swal({
              title: "Email successfully verified",
              text: res.data.message,
              className: "successAlert",
              buttons: false,
              timer: 2000,
            });
          }
        })
        .catch((err) => {
          if (err.response.data.message) {
            setSuccessChk(false);
            swal({
              title: "Error",
              text: err.response.data.message,
              className: "errorAlert",
              buttons: false,
              timer: 2000,
            });
          }
        });
    }
  }, []);

  return (
    <>
      <div className="flex h-screen w-full items-center justify-center login">
        <div className="w-1/2 bg-white px-16 py-10 shadow-2xl  backdrop-blur-md max-sm:px-8 login_form">
          <div className="text-black text-center">
            <div className="flex flex-col items-center">
              <img src="/images/logonew.png" width="150" alt="" srcSet="" />
            </div>
            <h1>Welcome to LUX499</h1>

            {successChk ? (
              <>
                <h2 className="thanku_page">
                  Thank you, your email address has been verified and your
                  account is all set up.
                </h2>
              </>
            ) : (
              <>
                <h2 className="thanku_page">
                  {" "}
                  Opps! Something went wrong, Please try again.{" "}
                </h2>
              </>
            )}
            <div className="mt-8 mb-4 flex justify-center text-lg text-black">
              <Link to="/login">
                <button
                  type="submit"
                  className="verifybtnsec rounded-3xl w-80 bg-yellow-400 bg-opacity-50 px-10 py-2 text-black shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600"
                >
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
