import {
  ADMIN_CREATE_COMPETITION_SUCCESS,
  ADMIN_CREATE_COMPETITION_REQUEST,
  ADMIN_CREATE_COMPETITION_FAIL,
  ADMIN_COMPETITION_LIST_SUCCESS,
  ADMIN_COMPETITION_LIST_REQUEST,
  ADMIN_COMPETITION_LIST_FAIL,
  COMPETITION_DELETE_SUCCESS,
  COMPETITION_DELETE_REQUEST,
  COMPETITION_DELETE_FAILURE,
  EDIT_COMPETITION_SUCCESS,
  EDIT_COMPETITION_REQUEST,
  EDIT_COMPETITION_FAILURE,
  GET_COMPETITION_BY_ID_SUCCESS,
  GET_COMPETITION_BY_ID_REQUEST,
  GET_COMPETITION_BY_ID_FAILURE,
  ADD_BEFORE_AFTER_IMAGES_SUCCESS,
  ADD_BEFORE_AFTER_IMAGES_REQUEST,
  ADD_BEFORE_AFTER_IMAGES_FAILURE,
  EDIT_BEFORE_AFTER_IMAGES_SUCCESS,
  EDIT_BEFORE_AFTER_IMAGES_REQUEST,
  EDIT_BEFORE_AFTER_IMAGES_FAILURE,
  GET_EDIT_BEFORE_AFTER_IMAGES_SUCCESS,
  GET_EDIT_BEFORE_AFTER_IMAGES_REQUEST,
  GET_EDIT_BEFORE_AFTER_IMAGES_FAILURE,
  UPDATE_BEFORE_AFTER_IMAGES_SUCCESS,
  UPDATE_BEFORE_AFTER_IMAGES_REQUEST,
  UPDATE_BEFORE_AFTER_IMAGES_FAILURE,
  DELETE_BEFOR_AFTER_IMAGES_SUCCESS,
  DELETE_BEFOR_AFTER_IMAGES_REQUEST,
  DELETE_BEFOR_AFTER_IMAGES_FAILURE,
  ADD_SLIDER_IMAGES_SUCCESS,
  ADD_SLIDER_IMAGES_REQUEST,
  ADD_SLIDER_IMAGES_FAILURE,
  GET_SLIDER_IMAGES_LIST_SUCCESS,
  GET_SLIDER_IMAGES_LIST_REQUEST,
  GET_SLIDER_IMAGES_LIST_FAILURE,
  DELETE_SLIDER_IMAGES_SUCCESS,
  DELETE_SLIDER_IMAGES_REQUEST,
  DELETE_SLIDER_IMAGES_FAILURE,
  EDIT_SLIDER_IMAGES_SUCCESS,
  EDIT_SLIDER_IMAGES_REQUEST,
  EDIT_SLIDER_IMAGES_FAILURE,
  UPDATE_SLIDER_IMAGES_SUCCESS,
  UPDATE_SLIDER_IMAGES_REQUEST,
  UPDATE_SLIDER_IMAGES_FAILURE,
  SHOW_SLIDER_IMAGES_SUCCESS,
  SHOW_SLIDER_IMAGES_REQUEST,
  SHOW_SLIDER_IMAGES_FAILURE,
  SECOND_SHOW_SLIDER_IMAGES_SUCCESS,
  SECOND_SHOW_SLIDER_IMAGES_REQUEST,
  SECOND_SHOW_SLIDER_IMAGES_FAILURE,
  HOME_PAGE_CONTENT_SEND_SUCCESS,
  HOME_PAGE_CONTENT_SEND_FAILURE,
  HOME_PAGE_CONTENT_SEND_REQUEST,
  HOME_PAGE_CONTENT_GET_SUCCESS,
  HOME_PAGE_CONTENT_GET_REQUEST,
  HOME_PAGE_CONTENT_GET_FAILURE,
  UPDATE_HOME_PAGE_CONTENT_SUCCESS,
  UPDATE_HOME_PAGE_CONTENT_REQUEST,
  UPDATE_HOME_PAGE_CONTENT_FAILURE,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_FAILURE,
  ADD_WINNER_SUCCESS,
  ADD_WINNER_REQUEST,
  ADD_WINNER_FAILURE,
  GET_WINNER_SUCCESS,
  GET_WINNER_REQUEST,
  GET_WINNER_FAILURE,
  DELETE_WINNER_SUCCESS,
  DELETE_WINNER_REQUEST,
  DELETE_WINNER_FAILURE,
  EDIT_WINNER_SUCCESS,
  EDIT_WINNER_REQUEST,
  EDIT_WINNER_FAILURE,
  GET_BY_WINNER_SUCCESS,
  GET_BY_WINNER_REQUEST,
  GET_BY_WINNER_FAILURE,
  GET_PRICE_SUCCESS,
  GET_PRICE_REQUEST,
  GET_PRICE_FAILURE,
  GET_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_FAILURE,
  GET_CART_DATA_SUCCESS,
  GET_CART_DATA_REQUEST,
  GET_CART_DATA_FAILURE,
  UPDATE_CART_DATA_SUCCESS,
  UPDATE_CART_DATA_REQUEST,
  UPDATE_CART_DATA_FAILURE,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_REQUEST,
  DELETE_CART_ITEM_FAILURE,
  PAST_COMPETITION_LIST_SUCCESS,
  PAST_COMPETITION_LIST_REQUEST,
  PAST_COMPETITION_LIST_FAILURE,
  PROCESS_TO_PAY_SUCCESS,
  PROCESS_TO_PAY_REQUEST,
  PROCESS_TO_PAY_FAILURE,
  GET_PROCESS_TO_PAY_SUCCESS,
  GET_PROCESS_TO_PAY_REQUEST,
  GET_PROCESS_TO_PAY_FAILURE,
  USER_COMPETITION_LIST_SUCCESS,
  USER_COMPETITION_LIST_REQUEST,
  USER_COMPETITION_LIST_FAILURE,
  WITHOUT_LOGIN_ADD_TO_CART_SUCCESS,
  WITHOUT_LOGIN_ADD_TO_CART_REQUEST,
  WITHOUT_LOGIN_ADD_TO_CART_FAILURE,
  WITHOUT_LOGIN_GET_CART_DATA_SUCCESS,
  WITHOUT_LOGIN_GET_CART_DATA_REQUEST,
  WITHOUT_LOGIN_GET_CART_DATA_FAILURE,
  WITHOUT_LOGIN_DELETE_CART_ITEM_SUCCESS,
  WITHOUT_LOGIN_DELETE_CART_ITEM_REQUEST,
  WITHOUT_LOGIN_DELETE_CART_ITEM_FAILURE,
  W_LOGIN_UPDATE_CART_DATA_SUCCESS,
  W_LOGIN_UPDATE_CART_DATA_REQUEST,
  W_LOGIN_UPDATE_CART_DATA_FAILURE,
  COMPETITON_ANS_DELETE_SUCCESS,
  COMPETITON_ANS_DELETE_REQUEST,
  COMPETITON_ANS_DELETE_FAILURE,
} from "../Constants/AdminConstants";
import axios from "axios";
import { BACKEND_API_URL } from "../../Environment";

export const GetuserAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_SUCCESS,
    });
    let config;
    const {
      authReducer: { userData },
    } = getState();

    if (userData) {
      config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };
    }

    let data = [];
    data = await axios.get(`${BACKEND_API_URL}users-info`, config);

    dispatch({
      type: GET_USER_REQUEST,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const GetpriceAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRICE_SUCCESS,
    });

    let config;
    const {
      authReducer: { userData },
    } = getState();

    if (userData) {
      config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };
    }

    let url = `${BACKEND_API_URL}competition-packages/`;

    if (id) {
      url += `?competition_id=${id}`;
    }

    let data = [];
    data = await axios.get(url, config);

    dispatch({
      type: GET_PRICE_REQUEST,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PRICE_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};


export const CreateCompetitionAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_CREATE_COMPETITION_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_API_URL}competitions/`,
        params,
        config
      );

      dispatch({
        type: ADMIN_CREATE_COMPETITION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error---actions--------------", error)
      dispatch({
        type: ADMIN_CREATE_COMPETITION_FAIL,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data.subtitle
            ? error.response.data.subtitle?.[0]
            : error.response.data.title
              ? error.response.data.title?.[0]
              : error.response.data.max_tickets
                ? error.response.data.max_tickets?.[0]
                : error.response.data.price
                  ? error.response.data.price?.[0]
                  : error.response.data.question
                    ? error.response.data.question?.[0]
                    : error.response.data.error
                      ? error.response.data.error
                      : error.response.data,
      });
    }
  };

export const CompetitionListAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_COMPETITION_LIST_REQUEST,
    });
    let config;
    const {
      authReducer: { userData },
    } = getState();

    if (userData) {
      config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };
    }

    const { data } = await axios.get(
      `${BACKEND_API_URL}competitions/?competition_admin_future=True`,
      config
    );

    dispatch({
      type: ADMIN_COMPETITION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_COMPETITION_LIST_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const CompetitionListForUserAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_COMPETITION_LIST_REQUEST,
      });

      const { data } = await axios.get(`${BACKEND_API_URL}competitions/`);
      dispatch({
        type: USER_COMPETITION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_COMPETITION_LIST_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };

export const CompetitionDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPETITION_DELETE_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.delete(
      `${BACKEND_API_URL}competitions/${id}/`,
      config
    );

    dispatch({
      type: COMPETITION_DELETE_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: COMPETITION_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.response.data.message
            ? error.response.data.message
            : error.message,
    });
  }
};

export const GetCompetitionByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_COMPETITION_BY_ID_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.get(
      `${BACKEND_API_URL}competitions/${id}`,
      config
    );

    dispatch({
      type: GET_COMPETITION_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPETITION_BY_ID_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const EditCompetitionAction =
  (id, params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_COMPETITION_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.patch(
        `${BACKEND_API_URL}competitions/${id}/`,
        params,
        config
      );

      dispatch({
        type: EDIT_COMPETITION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_COMPETITION_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data.subtitle
            ? error.response.data.subtitle?.[0]
            : error.response.data.title
              ? error.response.data.title?.[0]
              : error.response.data.max_tickets
                ? error.response.data.max_tickets?.[0]
                : error.response.data.price
                  ? error.response.data.price?.[0]
                  : error.response.data.question
                    ? error.response.data.question?.[0]
                    : error.response.data,
      });
    }
  };

export const AddBeforeAfterImagesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_BEFORE_AFTER_IMAGES_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_API_URL}competitions/afterbeforeimages/`,
        params,
        config
      );

      dispatch({
        type: ADD_BEFORE_AFTER_IMAGES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_BEFORE_AFTER_IMAGES_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data.title
            ? error.response.data.title?.[0]
            : error.response.data,
      });
    }
  };

export const EditBeforeAfterImagesAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_BEFORE_AFTER_IMAGES_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_API_URL}competitions/afterbeforeimages/?competition=${id}`,
        config
      );

      dispatch({
        type: EDIT_BEFORE_AFTER_IMAGES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_BEFORE_AFTER_IMAGES_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };

export const GetEditBeforeAfterImagesAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_EDIT_BEFORE_AFTER_IMAGES_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_API_URL}competitions/afterbeforeimages/${id}/`,
        config
      );

      dispatch({
        type: GET_EDIT_BEFORE_AFTER_IMAGES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EDIT_BEFORE_AFTER_IMAGES_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };

export const UpdateBeforeAfterImagesAction =
  (id, params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_BEFORE_AFTER_IMAGES_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.patch(
        `${BACKEND_API_URL}competitions/afterbeforeimages/${id}/`,
        params,
        config
      );

      dispatch({
        type: UPDATE_BEFORE_AFTER_IMAGES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BEFORE_AFTER_IMAGES_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data.before_image
            ? error.response.data.before_image?.[0]
            : error.response.data,
      });
    }
  };

export const DeleteBeforeAfterImagesAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_BEFOR_AFTER_IMAGES_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.delete(
        `${BACKEND_API_URL}competitions/afterbeforeimages/${id}/`,
        config
      );

      dispatch({
        type: DELETE_BEFOR_AFTER_IMAGES_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: DELETE_BEFOR_AFTER_IMAGES_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const AddSliderImagesAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_SLIDER_IMAGES_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_API_URL}sliders/`,
      params,
      config
    );

    dispatch({
      type: ADD_SLIDER_IMAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("error Action -----------", error);
    dispatch({
      type: ADD_SLIDER_IMAGES_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data.slider_image
          ? error.response.data.slider_image?.[0]
          : error.response.data.link
            ? error.response.data.link?.[0]
            : error.response.data.title
              ? error.response.data.title?.[0]
              : error.response.data,
    });
  }
};

export const GetSliderImageListAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SLIDER_IMAGES_LIST_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.get(`${BACKEND_API_URL}sliders/`, config);

    dispatch({
      type: GET_SLIDER_IMAGES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SLIDER_IMAGES_LIST_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const DeleteSliderAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_SLIDER_IMAGES_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.delete(
      `${BACKEND_API_URL}sliders/${id}/`,
      config
    );

    dispatch({
      type: DELETE_SLIDER_IMAGES_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: DELETE_SLIDER_IMAGES_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getEditSliderImagesAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EDIT_SLIDER_IMAGES_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.get(
      `${BACKEND_API_URL}sliders/${id}/`,
      config
    );
    dispatch({
      type: EDIT_SLIDER_IMAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EDIT_SLIDER_IMAGES_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const UpdateSliderImagesAction =
  (id, params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_SLIDER_IMAGES_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.patch(
        `${BACKEND_API_URL}sliders/${id}/`,
        params,
        config
      );

      dispatch({
        type: UPDATE_SLIDER_IMAGES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SLIDER_IMAGES_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data.slider_image
            ? error.response.data.slider_image?.[0]
            : error.response.data.link
              ? error.response.data.link?.[0]
              : error.response.data.title
                ? error.response.data.title?.[0]
                : error.response.data,
      });
    }
  };

export const ShowSliderImageHomeAction =
  (options) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SHOW_SLIDER_IMAGES_REQUEST,
      });
      const { data } = await axios.get(`${BACKEND_API_URL}sliders/?type=home`);

      dispatch({
        type: SHOW_SLIDER_IMAGES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SLIDER_IMAGES_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };

export const ShowSliderImageCompetitionAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: SECOND_SHOW_SLIDER_IMAGES_REQUEST,
      });
      const { data } = await axios.get(
        `${BACKEND_API_URL}sliders/?type=competition`
      );

      dispatch({
        type: SECOND_SHOW_SLIDER_IMAGES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SECOND_SHOW_SLIDER_IMAGES_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };

export const HomePageContentSendAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HOME_PAGE_CONTENT_SEND_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_API_URL}home_about_content/`,
        params,
        config
      );

      dispatch({
        type: HOME_PAGE_CONTENT_SEND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: HOME_PAGE_CONTENT_SEND_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };

export const GetHomePageContentAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HOME_PAGE_CONTENT_GET_REQUEST,
    });

    const { data } = await axios.get(`${BACKEND_API_URL}home_about_content/`);

    dispatch({
      type: HOME_PAGE_CONTENT_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HOME_PAGE_CONTENT_GET_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const UpdateHomePageContentAction =
  (id, params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_HOME_PAGE_CONTENT_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.patch(
        `${BACKEND_API_URL}home_about_content/${id}/`,
        params,
        config
      );

      dispatch({
        type: UPDATE_HOME_PAGE_CONTENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_HOME_PAGE_CONTENT_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };

export const AddToCartAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_TO_CART_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_API_URL}add-to-cart/`,
      params,
      config
    );

    dispatch({
      type: ADD_TO_CART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_TO_CART_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
          ? error.response.data.detail
          : error.response.data,
    });
  }
};

export const WithoutUserAddToCartAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WITHOUT_LOGIN_ADD_TO_CART_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_API_URL}add-to-cart/`,
        params,
        config
      );

      dispatch({
        type: WITHOUT_LOGIN_ADD_TO_CART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WITHOUT_LOGIN_ADD_TO_CART_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data.detail
            ? error.response.data.detail
            : error.response.data,
      });
    }
  };

export const AddWinnersAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_WINNER_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_API_URL}winners/`,
      params,
      config
    );

    dispatch({
      type: ADD_WINNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_WINNER_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data.winning_image
          ? error.response.data.winning_image?.[0]
          : error.response.data.title
            ? error.response.data.title?.[0]
            : error.response.data.ticket_number
              ? error.response.data.ticket_number?.[0]
              : error.response.data,
    });
  }
};

export const GetWinnersAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_WINNER_REQUEST,
    });

    const { data } = await axios.get(`${BACKEND_API_URL}winners/`);

    dispatch({
      type: GET_WINNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_WINNER_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const DeleteWinnersAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_WINNER_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.delete(
      `${BACKEND_API_URL}winners/${id}/`,
      config
    );

    dispatch({
      type: DELETE_WINNER_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: DELETE_WINNER_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const EditWinnersAction = (id, params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EDIT_WINNER_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.patch(
      `${BACKEND_API_URL}winners/${id}/`,
      params,
      config
    );

    dispatch({
      type: EDIT_WINNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EDIT_WINNER_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data.winning_image
          ? error.response.data.winning_image?.[0]
          : error.response.data.title
            ? error.response.data.title?.[0]
            : error.response.data.ticket_number
              ? error.response.data.ticket_number?.[0]
              : error.response.data,
    });
  }
};

export const GetByIdWinnersAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BY_WINNER_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.get(`${BACKEND_API_URL}winners/${id}`, config);

    dispatch({
      type: GET_BY_WINNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BY_WINNER_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const GetCartListAction = (promocode) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CART_DATA_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    let url = `${BACKEND_API_URL}cart/`;
    if (promocode) {
      url += `?promo_code=${promocode}`;
    }

    const { data } = await axios.get(url, config);

    dispatch({
      type: GET_CART_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CART_DATA_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const WithoutLoginGetCartListAction =
  (id, promocode) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WITHOUT_LOGIN_GET_CART_DATA_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      let url = `${BACKEND_API_URL}cart/?device_id=${id}`;
      if (promocode) {
        url += `&promo_code=${promocode}`;
      }

      const { data } = await axios.get(url, config);

      dispatch({
        type: WITHOUT_LOGIN_GET_CART_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WITHOUT_LOGIN_GET_CART_DATA_FAILURE,
        payload: error.response.data.message
          ? error.response.data.message
          : error.response.data,
      });
    }
  };


export const UpdateCartListAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_CART_DATA_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_API_URL}update-cart-items/`,
      params,
      config
    );

    dispatch({
      type: UPDATE_CART_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CART_DATA_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
          ? error.response.data.detail
          : error.response.data,
    });
  }
};

export const WLoginUpdateCartListAction = (params, id) => async (dispatch) => {
  try {
    dispatch({
      type: W_LOGIN_UPDATE_CART_DATA_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      `${BACKEND_API_URL}update-cart-items/?device_id=${id}`,
      params,
      config
    );

    dispatch({
      type: W_LOGIN_UPDATE_CART_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: W_LOGIN_UPDATE_CART_DATA_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
          ? error.response.data.detail
          : error.response.data,
    });
  }
};

export const DeleteCartItemAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CART_ITEM_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.delete(
      `${BACKEND_API_URL}remove-cart-item/${id}/`,
      config
    );

    dispatch({
      type: DELETE_CART_ITEM_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: DELETE_CART_ITEM_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const WLoginDeleteCartItemAction =
  (id, Divid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WITHOUT_LOGIN_DELETE_CART_ITEM_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.delete(
        `${BACKEND_API_URL}remove-cart-item/${id}/?device_id=${Divid}`,
        config
      );

      dispatch({
        type: WITHOUT_LOGIN_DELETE_CART_ITEM_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: WITHOUT_LOGIN_DELETE_CART_ITEM_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const GetPastCompetitionAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PAST_COMPETITION_LIST_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.get(
      `${BACKEND_API_URL}competitions/?competition_admin=True`,
      config
    );

    dispatch({
      type: PAST_COMPETITION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAST_COMPETITION_LIST_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const ProcessToPayAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROCESS_TO_PAY_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_API_URL}orders/`,
      params,
      config
    );

    dispatch({
      type: PROCESS_TO_PAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROCESS_TO_PAY_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data.error
          ? error.response.data.error
          : error.response.data,
    });
  }
};

export const GetProcessToPayAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PROCESS_TO_PAY_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.get(`${BACKEND_API_URL}orders/`, config);

    dispatch({
      type: GET_PROCESS_TO_PAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROCESS_TO_PAY_FAILURE,
      payload: error.response.data.message
        ? error.response.data.message
        : error.response.data,
    });
  }
};

export const CompetitionAnsDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPETITON_ANS_DELETE_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.delete(
        `${BACKEND_API_URL}competition-answers/${id}/`,
        config
      );

      dispatch({
        type: COMPETITON_ANS_DELETE_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      console.log("action error -----", error)
      dispatch({
        type: COMPETITON_ANS_DELETE_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data.message
              ? error.response.data.message
              : error.message,
      });
    }
  };
