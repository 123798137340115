import React from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { logoutAction } from "../../Redux/Actions/AuthAction";

const AdminHeader = (props) => {
  const {showDisplayHandle}=props
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logoutAction());
    swal({
      title: "Successfully Complete",
      text: "Logout Success",
      className: "successAlert-login",
      icon: "success",
      buttons: false,
      timer: 1500,
    });

  };
  return (
    <>
      <header className="flex items-center h-20 px-6 sm:px-10 bg-white">
        <button className="block sm:hidden relative flex-shrink-0 p-2 mr-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 focus:bg-gray-100 focus:text-gray-800 rounded-full" onClick={()=>showDisplayHandle()}>
          <span className="sr-only">Menu</span>
          <svg
            aria-hidden="true"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
        </button>
        <div className="relative w-full max-w-md sm:-ml-2">


        </div>
        <div className="flex flex-shrink-0 items-center ml-auto">

          <div className="border-l pl-3 ml-3 space-x-1">

            <button className="relative p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100 focus:text-gray-600 rounded-full" onClick={() => handleLogout()}>
              <span className="sr-only">Log out</span>
              <svg
                aria-hidden="true"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default AdminHeader