import React, {  useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function AdminRoute({ children }) {
  useEffect(() => {
    import("../../custom-tailwind.css");
  }, []);

  const { userData } = useSelector((state) => state.authReducer);
  return userData?.user?.role == 0 ? children : <Navigate to="/" />;
}
